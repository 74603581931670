<template>
<div class="wrapper">
  <div v-for="(mItem, index) in Object.entries(testVal)"
        :key="index+'A'">
        <p v-text="mItem[0] + '일'" />
        <div  v-for="(vItem, index) in mItem[1]"
        :key="index+'B'">
        <p v-text=" vItem.quit+ ' 상담일 ' + getDayInfo(vItem.quit) + '요일'" />
        <p v-text="vItem.createAt + '기록일'" />
        </div>
      </div>
      </div>
</template>

<script>
export default {
  props: {

  },
  data() {
    return {
      testVal: {},
    };
  },
  created() {
    this.checkupList();
  },
  methods: {
    checkupList() {
      const test = {};
      const sampleObj = {
        list: [
          {
            quit: '2021-05-30',
            createAt: '2021-04-20',
          },
          {
            quit: '2021-05-30',
            createAt: '2021-04-20',
          },
          {
            quit: '2021-05-31',
            createAt: '2021-04-21',
          },
        ],
      };
      for (let i = 0; i < sampleObj.list.length; i++) {
        if (i === 0) {
          console.log('처음');
          const sampleArr = [];
          test[sampleObj.list[i].createAt] = sampleArr;
          test[sampleObj.list[i].createAt].push(sampleObj.list[i]);
        } else if (sampleObj.list[i].createAt !== sampleObj.list[i - 1].createAt) {
          console.log('동류없음', i);
          const sampleArr = [];
          test[sampleObj.list[i].createAt] = sampleArr;
          test[sampleObj.list[i].createAt].push(sampleObj.list[i]);
        } else {
          console.log('동류있음', i);
          test[sampleObj.list[i - 1].createAt].push(sampleObj.list[i]);
        }
      }
      this.testVal = test;
      console.log('test :', Object.entries(test));
      console.log('test :', Object.entries(test)[0]);
      console.log('test :', Object.entries(test)[1]);
      console.log('test :', Object.entries(test)[0][1]);
    },
  },
  computed: {
    getDayInfo() {
      return (date) => {
        const week = [];
        week.push('일', '월', '화', '수', '목', '금', '토');
        const day = new Date(date);
        const dayName = day.getDay();
        return week[dayName];
      };
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
