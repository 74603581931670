<template>
  <div class="app-context flex deep share">
    <div class="wrap-context">
      <notification class="low-margin" :notificationInfo="notificationInfo"></notification>
      <information-diet-card
        :information="information"
        :readOnly="true"
        v-if="view.isCardPrepared"
      ></information-diet-card>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import InformationDietCard from '@/components/InformationDietCard';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      view: {
        isCardPrepared: false,
      },
      ogTag: {
        title: null,
        description: '식이법 안내 페이지입니다.',
        image: null,
        url: null,
      },
      information: {},
      notificationInfo: {
        title: '식이법',
        btnSet: [],
      },
      popupSet: {},
    };
  },
  head: {
    meta() {
      return [
        {
          property: 'og:title',
          content: this.transMeta(
            Number(this.$route.query.id),
            Number(this.$route.query.questionOrder),
            'title',
          ),
        },
        { property: 'og:description', content: this.ogTag.description },
        {
          property: 'og:image',
          content: this.transMeta(
            Number(this.$route.query.id),
            Number(this.$route.query.questionOrder),
            'image',
          ),
        },
        {
          property: 'og:url',
          content: this.transMeta(
            Number(this.$route.query.id),
            Number(this.$route.query.questionOrder),
            'url',
          ),
        },
        { name: 'description', content: this.ogTag.description },
      ];
    },
  },
  created() {
    console.log('준비물 점검 ID :', this.$route.query.id);
    console.log('준비물 점검 QuestionOrder :', this.$route.query.questionOrder);
    this.prepareInfo(
      Number(this.$route.query.id),
      Number(this.$route.query.questionOrder),
      Number(this.$route.query.userId),
    );
  },
  mounted() {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('feeding', ['FEEDING_DETAIL_SHARE']),
    async prepareInfo(id, questionOrder, userId) {
      console.log('prepare 준비물 :', id, questionOrder, userId);
      let cardInfo;
      if (questionOrder === 1) {
        /** 음식을 입안에서 조작하기가 어렵다면 */
        cardInfo = {
          id,
          question: '음식을 입안에서 조작하기가 어렵다면?',
          answer: '부드러운 음식을 드세요',
          content:
            "입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다. 삼키기 쉽도록 농도가 조절된 <b style='font-weight: normal;color: #fd8b11;'>대체식*</b>을 드시는 것도 좋습니다. ",
          referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
          galleryImgList: [],
          btnTxt: '대체식이란?',
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 2) {
        /** 음식을 입안에서 조작하기가 어렵다면 */
        cardInfo = {
          id,
          question: '음식을 입안에서 조작하기가 어렵다면?',
          answer: '믹서기로 간 음식을 드세요',
          content:
            '입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식(예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
          referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 3) {
        cardInfo = {
          id,
          question: '음식을 입안에 자주 들러붙는다면?',
          answer: '촉촉한 과일을 함께 드세요',
          content:
            '침이 부족하면 식사 시 음식이 입안에 들러붙어 삼키기 어려울 수 있습니다. 이때는 촉촉한 과일을 함께 먹으면 도움이 됩니다.',
          referenceImgList: ['referenceImgC01.png', 'referenceImgC02.png', 'referenceImgC03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 구강건조용 스프레이 및 연고를 사용하는 것도 좋습니다. 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 4) {
        cardInfo = {
          id,
          question: '구강 감각을 높이려면?',
          answer: '차가운 음식을 드세요',
          content:
            '입안의 감각이 무뎌지면 음식을 조작하고 삼키는 것이 어려울 수 있습니다. 이 때 차가운 음식으로 입안을 자극하면 구강 감각을 높이는 데 도움이 됩니다.',
          referenceImgList: ['referenceImgD01.png', 'referenceImgD02.png', 'referenceImgD03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 5) {
        cardInfo = {
          id,
          question: '구강 감각을 높이려면?',
          answer: '신맛의 음식을 드세요',
          content:
            '입안의 감각이 무뎌지면 음식을 조작하고 삼키는 것이 어려울 수 있습니다. 이 때 신맛(예: 식초, 레몬즙 등)을 가미해서 입안을 자극하면 구강 감각을 높이는 데 도움이 됩니다.',
          referenceImgList: ['referenceImgE01.png', 'referenceImgE02.png', 'referenceImgE03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 6) {
        cardInfo = {
          id,
          question: '음식을 삼키기 전에 사레에 걸린다면?',
          answer: '음식을 걸쭉하게 만드세요',
          content:
            "꿀꺽 삼키기 전에 음식이 저절로 목구멍으로 넘어가면 사레에 걸릴 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
          referenceImgList: [
            'referenceImgF01.png',
            'referenceImgF02.png',
            'referenceImgF03.png',
            'referenceImgF04.png',
          ],
          galleryImgList: [],
          btnTxt: '점도증진제란?',
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 7) {
        cardInfo = {
          id,
          question: '음식을 삼키는 중에 사레에 걸린다면?',
          answer: '음식을 걸쭉하게 만드세요',
          content:
            "삼킬 때 기도가 제대로 닫히지 않으면 사레에 걸릴 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
          referenceImgList: [
            'referenceImgF01.png',
            'referenceImgF02.png',
            'referenceImgF03.png',
            'referenceImgF04.png',
          ],
          galleryImgList: [],
          btnTxt: '점도증진제란?',
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 8) {
        cardInfo = {
          id,
          question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
          answer: '부드러운 음식을 드세요',
          content:
            '삼킨 후 목구멍에 음식물이 남으면 식사 후에 사레에 걸리거나 이물감을 느낄 수 있습니다. 이때는 잔여물을 방지하기 위해 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다.',
          referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 9) {
        cardInfo = {
          id,
          question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
          answer: '믹서기로 간 음식을 드세요',
          content:
            '입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식(예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
          referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 10) {
        cardInfo = {
          id,
          question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
          answer: '고체를 삼킨 후 액체를 삼키세요',
          content:
            '삼킨 후 목구멍에 음식물이 남으면 식사 후에 사레에 걸리거나 이물감을 느낄 수 있습니다. 이때는 고체 음식을 삼킨 후에 액체 음식을 삼키면 잔여물을 방지하는 데 도움이 됩니다.',
          referenceImgList: [
            'referenceImgG01.png',
            'referenceImgG02.png',
            'referenceImgG03.png',
            'referenceImgG04.png',
          ],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 11) {
        cardInfo = {
          id,
          question: '음식을 삼킨 후 목소리가 변한다면?',
          answer: '음식을 걸쭉하게 만드세요',
          content:
            "삼킨 후 목소리가 변한다면 기침 없이 사레에 걸린 것일 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
          referenceImgList: [
            'referenceImgF01.png',
            'referenceImgF02.png',
            'referenceImgF03.png',
            'referenceImgF04.png',
          ],
          galleryImgList: [],
          btnTxt: '점도증진제란?',
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 12) {
        cardInfo = {
          id,
          question: '국을 먹을 때 사레에 걸린다면?',
          answer: '음식의 농도를 통일해 주세요',
          content:
            '국에는 액체로 된 국물과 고체로 된 건더기가 함께 들어있습니다. 국에 들어간 건더기(예:콩나물, 팽이버섯 등)를 씹다가 국물이 의도치 않게 먼저 목으로 넘어가면 사레에 걸릴 수 있습니다. 건더기가 들어간 국을 먹거나 밥을 국에 말아먹는 것은 피하시고, 음식을 삼키기 쉬운 한 가지 농도로 통일해주세요.',
          referenceImgList: ['referenceImgH01.png', 'referenceImgH02.png', 'referenceImgH03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 13) {
        cardInfo = {
          id,
          question: '액체를 마실 때 사레에 걸린다면?',
          answer: '액체를 걸쭉하게 만드세요',
          content:
            "물이나 음료(예: 주스, 커피 등)처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 액체를 걸쭉하게 만드는 것이 도움이 됩니다.",
          referenceImgList: [
            'referenceImgF01.png',
            'referenceImgF02.png',
            'referenceImgF03.png',
            'referenceImgF04.png',
          ],
          galleryImgList: [],
          btnTxt: '점도증진제란?',
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 14) {
        cardInfo = {
          id,
          question: '액체를 마실 때 사레에 걸린다면?',
          answer: '액체를 얼려서 조금씩 녹여 드세요',
          content:
            '물이나 음료(예:주스, 커피 등)처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 액체를 살짝 얼린 후 잘게 부순 다음, 소량씩 입안에 넣어 녹여가며 삼키면 도움이 됩니다.',
          referenceImgList: ['referenceImgI01.png', 'referenceImgI02.png', 'referenceImgI03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 15) {
        cardInfo = {
          id,
          question: '물을 마실 때 사레에 걸린다면?',
          answer: '걸쭉한 음료를 드세요',
          content:
            '물처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 물보다 농도가 높은 걸쭉한 음료(예: 바나나 주스, 토마토 주스 등)로 수분을 섭취하시길 권장합니다.',
          referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 16) {
        cardInfo = {
          id,
          question: '알약을 삼키는 게 어렵다면?',
          answer: '가루약을 물에 타 드세요',
          content:
            '알약을 잘못 삼켜서 목구멍에 걸리면 사레에 걸릴 위험이 있습닏. 이떄는 알약을 가루로 만든 다음, 수저에 얹어서 물에 섞어 삼키면 도움이 됩니다.',
          referenceImgList: ['referenceImgJ01.png', 'referenceImgJ02.png', 'referenceImgJ03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 일부 알약을 가루로 만들면 약효가 감소가 될 수 있으므로, 주치의와 상의하시기 바랍니다.',
        };
      } else if (questionOrder === 17) {
        cardInfo = {
          id,
          question: '알약을 삼키는 게 어렵다면?',
          answer: '걸쭉한 음식과 함께 드세요',
          content:
            '평소에 물을 마실 때 사레에 쉽게 걸린다면, 물 대신에 걸쭉한 음식(예: 그릭 요거트)과 함께 알약을 삼키면 도움이 됩니다.',
          referenceImgList: ['referenceImgK01.png', 'referenceImgK02.png', 'referenceImgK03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 그릭 요거트는 일반 플레인 요거트와 구별됩니다. 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 18) {
        cardInfo = {
          id,
          question: '꿀꺽 삼키기가 어렵다면?',
          answer: '차가운 음식을 드세요',
          content:
            "삼킬 때 목구멍에서 '꿀꺽'하는 반응이 잘 일어나지 않으면 사레에 걸릴 위험이 있습니다. 이때 차가운 음식으로 입안을 자극하면 삼킴 반사가 일어나는 데 도움이 됩니다.",
          referenceImgList: ['referenceImgD01.png', 'referenceImgD02.png', 'referenceImgD03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 19) {
        cardInfo = {
          id,
          question: '꿀꺽 삼키기가 어렵다면?',
          answer: '신맛의 음식을 드세요',
          content:
            "삼킴 때 목구멍에서 '꿀꺽'하는 반응이 잘 일어나지 않으면 사레에 걸릴 위험이 있습니다. 이때 음식에 신맛(예:식초, 레몬즙 등)을 가미해서 입안을 자극하면 삼킴 반사가 일어나는 데 도움이 됩니다.",
          referenceImgList: ['referenceImgE01.png', 'referenceImgE02.png', 'referenceImgE03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 20) {
        cardInfo = {
          id,
          question: '물을 마실 때 양 조절이 어렵다면?',
          answer: '보조도구를 사용하세요',
          content:
            '일반 컵으로 물을 마시면 물의 양을 조절하기가 어려울수 있습니다. 한번에 많은 양의 물을 마시면 사레의 위험이 높아집니다. 이때는 보조도구(예: 계랸컵, 빨대컵, 일자빨대, 티스푼 등)를 사용해서 사용해서 한 모금에 소량만 마실 수 있도록 하면 도움이 됩니다.',
          referenceImgList: ['referenceImgL01.png', 'referenceImgL03.png', 'referenceImgL03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 21) {
        cardInfo = {
          id,
          question: '많은 양을 삼킬 때 사레에 걸린다면?',
          answer: '보조도구를 사용하세요',
          content:
            '한번에 많은 양의 음식을 삼키면 사레의 위험이 높아집니다. 이때는 보조도구(예: 계량컵, 빨대컵, 일자빨대, 티스푼 등)를 사용해서 한 입에 소량만 먹을 수 있게 조절하면 도움이 됩니다.',
          referenceImgList: ['referenceImgL01.png', 'referenceImgL03.png', 'referenceImgL01.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 22) {
        cardInfo = {
          id,
          question: '음식물이 목으로 역류한다면?',
          answer: '고체를 삼킨 후 액체를 삼키세요',
          content:
            '식도 장애가 있거나 배의 근육이 긴장하면 삼킨 음식이 목으로 역류할 수 있습니다. 이때는 고체 음식을 삼킨 후에 액체 음식을 삼키면 도움이 됩니다.',
          referenceImgList: [
            'referenceImgG01.png',
            'referenceImgG02.png',
            'referenceImgG03.png',
            'referenceImgG04.png',
          ],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification:
            '* 역류 증상과 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 23) {
        cardInfo = {
          id,
          question: '혀 또는 턱에 마비가 있다면?',
          answer: '부드러운 음식을 드세요',
          content:
            "혀 또는 턱에 마비가 있으면 음식을 씹고 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다. 삼키기 쉽도록 농도가 조절된 <b style='font-weight: normal;color: #fd8b11;'>대체식*</b>을 드시는 것도 좋습니다. ",
          referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
          galleryImgList: [],
          btnTxt: '대체식이란?',
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 24) {
        cardInfo = {
          id,
          question: '혀 또는 턱에 마비가 있다면?',
          answer: '믹서기로 간 음식을 드세요',
          content:
            '혀 또는 턱에 마비가 있으면 음식을 씹고 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식 (예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
          referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 25) {
        cardInfo = {
          id,
          question: '물을 마시면 안되는 경우에 주스나 커피도 마시면 안되나요?',
          answer: '네, 물 농도의 모든 액체는 피해주세요',
          content:
            '물을 마시지 말라는 권고는 물처럼 묽은 액체를 삼킬 때 사레의 위험이 높기 때문입니다. 물 농도의 모든 액체류 (예: 커피, 녹차, 홍차 등)를 마시지 않도록 주의하고, 걸쭉한 액체로 수분을 섭취하시길 권장합니다.',
          referenceImgList: ['referenceImgM01.png', 'referenceImgM02.png', 'referenceImgM03.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 26) {
        cardInfo = {
          id,
          question: '점도증진제를 넣은 물에 적응하기 어렵다면?',
          answer: '맛과 향을 활용하세요',
          content:
            '점도증진제로 걸쭉하게 만든 물에 거부감이 있다면 개인이 선호하는 맛과 향을 가진 음료(예: 주스, 차)에 점도증진제를 섞어 수분을 섭취하는 것이 도움이 됩니다.',
          referenceImgList: ['referenceImgN01.png', 'referenceImgN02.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      } else if (questionOrder === 27) {
        cardInfo = {
          id,
          question: '식사 시간이 오래 걸린다면?',
          answer: '한끼 식사량을 줄이고 간식을 드세요',
          content:
            '식사가 오래 걸리면 쉽게 피곤해지고 충분한 양을 먹지 못할 수 있습니다. 식사 시간을 30분 정도로 하고, 식사 사이에 간식을 먹는 것을 권장합니다.',
          referenceImgList: ['referenceImgO01.png', 'referenceImgO02.png'],
          galleryImgList: [],
          btnTxt: null,
          galleryTitle: '음식을 올려주세요',
          notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
        };
      }

      try {
        const payload = {
          id,
          userId,
        };
        await this.FEEDING_DETAIL_SHARE(payload).then((data) => {
          console.log('feeding-detail-data :', data);
          cardInfo.galleryImgList = data.response.imageList;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        /**
        this.SET_POPUP(true);
        this.popupSet.content = "로그인 권한이 필요합니다.";
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = null;
        this.popupSet.nextLink = "/login";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
         */
        console.log('error :', error);
      }
      console.log('cardInfo :', cardInfo);

      this.information = cardInfo;
      this.view.isCardPrepared = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    transMeta() {
      return (id, questionOrder, parts) => {
        let cardInfo;
        if (questionOrder === 1) {
          /** 음식을 입안에서 조작하기가 어렵다면 */
          cardInfo = {
            id,
            question: '음식을 입안에서 조작하기가 어렵다면?',
            answer: '부드러운 음식을 드세요',
            content:
              "입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다. 삼키기 쉽도록 농도가 조절된 <b style='font-weight: normal;color: #fd8b11;'>대체식*</b>을 드시는 것도 좋습니다. ",
            referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
            galleryImgList: [],
            btnTxt: '대체식이란?',
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 2) {
          /** 음식을 입안에서 조작하기가 어렵다면 */
          cardInfo = {
            id,
            question: '음식을 입안에서 조작하기가 어렵다면?',
            answer: '믹서기로 간 음식을 드세요',
            content:
              '입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식(예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
            referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 3) {
          cardInfo = {
            id,
            question: '음식을 입안에 자주 들러붙는다면?',
            answer: '촉촉한 과일을 함께 드세요',
            content:
              '침이 부족하면 식사 시 음식이 입안에 들러붙어 삼키기 어려울 수 있습니다. 이때는 촉촉한 과일을 함께 먹으면 도움이 됩니다.',
            referenceImgList: ['referenceImgC01.png', 'referenceImgC02.png', 'referenceImgC03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 구강건조용 스프레이 및 연고를 사용하는 것도 좋습니다. 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 4) {
          cardInfo = {
            id,
            question: '구강 감각을 높이려면?',
            answer: '차가운 음식을 드세요',
            content:
              '입안의 감각이 무뎌지면 음식을 조작하고 삼키는 것이 어려울 수 있습니다. 이 때 차가운 음식으로 입안을 자극하면 구강 감각을 높이는 데 도움이 됩니다.',
            referenceImgList: ['referenceImgD01.png', 'referenceImgD02.png', 'referenceImgD03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 5) {
          cardInfo = {
            id,
            question: '구강 감각을 높이려면?',
            answer: '신맛의 음식을 드세요',
            content:
              '입안의 감각이 무뎌지면 음식을 조작하고 삼키는 것이 어려울 수 있습니다. 이 때 신맛(예: 식초, 레몬즙 등)을 가미해서 입안을 자극하면 구강 감각을 높이는 데 도움이 됩니다.',
            referenceImgList: ['referenceImgE01.png', 'referenceImgE02.png', 'referenceImgE03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 6) {
          cardInfo = {
            id,
            question: '음식을 삼키기 전에 사레에 걸린다면?',
            answer: '음식을 걸쭉하게 만드세요',
            content:
              "꿀꺽 삼키기 전에 음식이 저절로 목구멍으로 넘어가면 사레에 걸릴 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
            referenceImgList: [
              'referenceImgF01.png',
              'referenceImgF02.png',
              'referenceImgF03.png',
              'referenceImgF04.png',
            ],
            galleryImgList: [],
            btnTxt: '점도증진제란?',
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 7) {
          cardInfo = {
            id,
            question: '음식을 삼키는 중에 사레에 걸린다면?',
            answer: '음식을 걸쭉하게 만드세요',
            content:
              "삼킬 때 기도가 제대로 닫히지 않으면 사레에 걸릴 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
            referenceImgList: [
              'referenceImgF01.png',
              'referenceImgF02.png',
              'referenceImgF03.png',
              'referenceImgF04.png',
            ],
            galleryImgList: [],
            btnTxt: '점도증진제란?',
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 8) {
          cardInfo = {
            id,
            question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
            answer: '부드러운 음식을 드세요',
            content:
              '삼킨 후 목구멍에 음식물이 남으면 식사 후에 사레에 걸리거나 이물감을 느낄 수 있습니다. 이때는 잔여물을 방지하기 위해 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다.',
            referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 9) {
          cardInfo = {
            id,
            question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
            answer: '믹서기로 간 음식을 드세요',
            content:
              '입안에서 음식을 적당한 크기와 형태로 조작하지 못하면 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식(예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
            referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 10) {
          cardInfo = {
            id,
            question: '음식을 삼킨 후 사레에 걸리거나 이물감이 느껴진다면?',
            answer: '고체를 삼킨 후 액체를 삼키세요',
            content:
              '삼킨 후 목구멍에 음식물이 남으면 식사 후에 사레에 걸리거나 이물감을 느낄 수 있습니다. 이때는 고체 음식을 삼킨 후에 액체 음식을 삼키면 잔여물을 방지하는 데 도움이 됩니다.',
            referenceImgList: [
              'referenceImgG01.png',
              'referenceImgG02.png',
              'referenceImgG03.png',
              'referenceImgG04.png',
            ],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 자세법과 함께 실시하세요. 이물감이 지속되면 내원 진료를 권장합니다. 식이조절은 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 11) {
          cardInfo = {
            id,
            question: '음식을 삼킨 후 목소리가 변한다면?',
            answer: '음식을 걸쭉하게 만드세요',
            content:
              "삼킨 후 목소리가 변한다면 기침 없이 사레에 걸린 것일 수 있습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 음식을 걸쭉하게 만드는 것이 도움이 됩니다.",
            referenceImgList: [
              'referenceImgF01.png',
              'referenceImgF02.png',
              'referenceImgF03.png',
              'referenceImgF04.png',
            ],
            galleryImgList: [],
            btnTxt: '점도증진제란?',
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 12) {
          cardInfo = {
            id,
            question: '국을 먹을 때 사레에 걸린다면?',
            answer: '음식의 농도를 통일해 주세요',
            content:
              '국에는 액체로 된 국물과 고체로 된 건더기가 함께 들어있습니다. 국에 들어간 건더기(예:콩나물, 팽이버섯 등)를 씹다가 국물이 의도치 않게 먼저 목으로 넘어가면 사레에 걸릴 수 있습니다. 건더기가 들어간 국을 먹거나 밥을 국에 말아먹는 것은 피하시고, 음식을 삼키기 쉬운 한 가지 농도로 통일해주세요.',
            referenceImgList: ['referenceImgH01.png', 'referenceImgH02.png', 'referenceImgH03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 13) {
          cardInfo = {
            id,
            question: '액체를 마실 때 사레에 걸린다면?',
            answer: '액체를 걸쭉하게 만드세요',
            content:
              "물이나 음료(예: 주스, 커피 등)처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 <b style='font-weight: normal;color: #fd8b11;'>점도증진제*</b>로 액체를 걸쭉하게 만드는 것이 도움이 됩니다.",
            referenceImgList: [
              'referenceImgF01.png',
              'referenceImgF02.png',
              'referenceImgF03.png',
              'referenceImgF04.png',
            ],
            galleryImgList: [],
            btnTxt: '점도증진제란?',
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 14) {
          cardInfo = {
            id,
            question: '액체를 마실 때 사레에 걸린다면?',
            answer: '액체를 얼려서 조금씩 녹여 드세요',
            content:
              '물이나 음료(예:주스, 커피 등)처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 액체를 살짝 얼린 후 잘게 부순 다음, 소량씩 입안에 넣어 녹여가며 삼키면 도움이 됩니다.',
            referenceImgList: ['referenceImgI01.png', 'referenceImgI02.png', 'referenceImgI03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 15) {
          cardInfo = {
            id,
            question: '물을 마실 때 사레에 걸린다면?',
            answer: '걸쭉한 음료를 드세요',
            content:
              '물처럼 묽은 액체는 사레에 걸릴 위험이 높습니다. 이때는 물보다 농도가 높은 걸쭉한 음료(예: 바나나 주스, 토마토 주스 등)로 수분을 섭취하시길 권장합니다.',
            referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 16) {
          cardInfo = {
            id,
            question: '알약을 삼키는 게 어렵다면?',
            answer: '가루약을 물에 타 드세요',
            content:
              '알약을 잘못 삼켜서 목구멍에 걸리면 사레에 걸릴 위험이 있습닏. 이떄는 알약을 가루로 만든 다음, 수저에 얹어서 물에 섞어 삼키면 도움이 됩니다.',
            referenceImgList: ['referenceImgJ01.png', 'referenceImgJ02.png', 'referenceImgJ03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 일부 알약을 가루로 만들면 약효가 감소가 될 수 있으므로, 주치의와 상의하시기 바랍니다.',
          };
        } else if (questionOrder === 17) {
          cardInfo = {
            id,
            question: '알약을 삼키는 게 어렵다면?',
            answer: '걸쭉한 음식과 함께 드세요',
            content:
              '평소에 물을 마실 때 사레에 쉽게 걸린다면, 물 대신에 걸쭉한 음식(예: 그릭 요거트)과 함께 알약을 삼키면 도움이 됩니다.',
            referenceImgList: ['referenceImgK01.png', 'referenceImgK02.png', 'referenceImgK03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 그릭 요거트는 일반 플레인 요거트와 구별됩니다. 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 18) {
          cardInfo = {
            id,
            question: '꿀꺽 삼키기가 어렵다면?',
            answer: '차가운 음식을 드세요',
            content:
              "삼킬 때 목구멍에서 '꿀꺽'하는 반응이 잘 일어나지 않으면 사레에 걸릴 위험이 있습니다. 이때 차가운 음식으로 입안을 자극하면 삼킴 반사가 일어나는 데 도움이 됩니다.",
            referenceImgList: ['referenceImgD01.png', 'referenceImgD02.png', 'referenceImgD03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 19) {
          cardInfo = {
            id,
            question: '꿀꺽 삼키기가 어렵다면?',
            answer: '신맛의 음식을 드세요',
            content:
              "삼킴 때 목구멍에서 '꿀꺽'하는 반응이 잘 일어나지 않으면 사레에 걸릴 위험이 있습니다. 이때 음식에 신맛(예:식초, 레몬즙 등)을 가미해서 입안을 자극하면 삼킴 반사가 일어나는 데 도움이 됩니다.",
            referenceImgList: ['referenceImgE01.png', 'referenceImgE02.png', 'referenceImgE03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 20) {
          cardInfo = {
            id,
            question: '물을 마실 때 양 조절이 어렵다면?',
            answer: '보조도구를 사용하세요',
            content:
              '일반 컵으로 물을 마시면 물의 양을 조절하기가 어려울수 있습니다. 한번에 많은 양의 물을 마시면 사레의 위험이 높아집니다. 이때는 보조도구(예: 계랸컵, 빨대컵, 일자빨대, 티스푼 등)를 사용해서 사용해서 한 모금에 소량만 마실 수 있도록 하면 도움이 됩니다.',
            referenceImgList: ['referenceImgL01.png', 'referenceImgL03.png', 'referenceImgL03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 21) {
          cardInfo = {
            id,
            question: '많은 양을 삼킬 때 사레에 걸린다면?',
            answer: '보조도구를 사용하세요',
            content:
              '한번에 많은 양의 음식을 삼키면 사레의 위험이 높아집니다. 이때는 보조도구(예: 계량컵, 빨대컵, 일자빨대, 티스푼 등)를 사용해서 한 입에 소량만 먹을 수 있게 조절하면 도움이 됩니다.',
            referenceImgList: ['referenceImgL01.png', 'referenceImgL03.png', 'referenceImgL01.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 22) {
          cardInfo = {
            id,
            question: '음식물이 목으로 역류한다면?',
            answer: '고체를 삼킨 후 액체를 삼키세요',
            content:
              '식도 장애가 있거나 배의 근육이 긴장하면 삼킨 음식이 목으로 역류할 수 있습니다. 이때는 고체 음식을 삼킨 후에 액체 음식을 삼키면 도움이 됩니다.',
            referenceImgList: [
              'referenceImgG01.png',
              'referenceImgG02.png',
              'referenceImgG03.png',
              'referenceImgG04.png',
            ],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification:
              '* 역류 증상과 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 23) {
          cardInfo = {
            id,
            question: '혀 또는 턱에 마비가 있다면?',
            answer: '부드러운 음식을 드세요',
            content:
              "혀 또는 턱에 마비가 있으면 음식을 씹고 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 부드러운 음식(예: 연두부, 계란찜, 으깬 감자 등)을 권장합니다. 삼키기 쉽도록 농도가 조절된 <b style='font-weight: normal;color: #fd8b11;'>대체식*</b>을 드시는 것도 좋습니다. ",
            referenceImgList: ['referenceImgA01.png', 'referenceImgA02.png', 'referenceImgA03.png'],
            galleryImgList: [],
            btnTxt: '대체식이란?',
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 24) {
          cardInfo = {
            id,
            question: '혀 또는 턱에 마비가 있다면?',
            answer: '믹서기로 간 음식을 드세요',
            content:
              '혀 또는 턱에 마비가 있으면 음식을 씹고 삼키기가 어려울 수 있습니다. 이때는 삼키기 편한 믹서기로 간 음식 (예: 바나나 주스, 토마토 주스 등)을 권장합니다.',
            referenceImgList: ['referenceImgB01.png', 'referenceImgB02.png', 'referenceImgB03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 25) {
          cardInfo = {
            id,
            question: '물을 마시면 안되는 경우에 주스나 커피도 마시면 안되나요?',
            answer: '네, 물 농도의 모든 액체는 피해주세요',
            content:
              '물을 마시지 말라는 권고는 물처럼 묽은 액체를 삼킬 때 사레의 위험이 높기 때문입니다. 물 농도의 모든 액체류 (예: 커피, 녹차, 홍차 등)를 마시지 않도록 주의하고, 걸쭉한 액체로 수분을 섭취하시길 권장합니다.',
            referenceImgList: ['referenceImgM01.png', 'referenceImgM02.png', 'referenceImgM03.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 26) {
          cardInfo = {
            id,
            question: '점도증진제를 넣은 물에 적응하기 어렵다면?',
            answer: '맛과 향을 활용하세요',
            content:
              '점도증진제로 걸쭉하게 만든 물에 거부감이 있다면 개인이 선호하는 맛과 향을 가진 음료(예: 주스, 차)에 점도증진제를 섞어 수분을 섭취하는 것이 도움이 됩니다.',
            referenceImgList: ['referenceImgN01.png', 'referenceImgN02.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        } else if (questionOrder === 27) {
          cardInfo = {
            id,
            question: '식사 시간이 오래 걸린다면?',
            answer: '한끼 식사량을 줄이고 간식을 드세요',
            content:
              '식사가 오래 걸리면 쉽게 피곤해지고 충분한 양을 먹지 못할 수 있습니다. 식사 시간을 30분 정도로 하고, 식사 사이에 간식을 먹는 것을 권장합니다.',
            referenceImgList: ['referenceImgO01.png', 'referenceImgO02.png'],
            galleryImgList: [],
            btnTxt: null,
            galleryTitle: '음식을 올려주세요',
            notification: '* 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.',
          };
        }
        console.log('cardInfo :', cardInfo);
        if (parts === 'title') {
          return cardInfo.question;
        }
        if (parts === 'image') {
          if (cardInfo.referenceImgList.length > 0) {
            return `http://dev.swallow.pro/information/diet/${cardInfo.referenceImgList[0]}`;
          }
          return null;
        }
        if (parts === 'url') {
          return `${'http://dev.swallow.pro/share/diet' + '?question='}${questionOrder}&id=${
            cardInfo.id
          }`;
        }
      };
    },
  },
  components: {
    Notification,
    DefaultPopup,
    InformationDietCard,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1023px) {
  #app .app-context.share.flex {
    .wrap-context {
      margin: 25px 55px;
      width: calc(100% - 110px);
    }
  }
}

@media screen and (max-width: 480px) {
  #app .app-context.share {
    .wrap-context {
      margin: 20px 30px;
      width: calc(100% - 60px);
    }
  }
}

.app-context {
  &.share {
    height: auto;
    .wrap-context {
      margin: 40px auto;
    }
  }
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 20px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
