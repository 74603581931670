<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
      :sideMenuDeep="true"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <swiper
        :swiperList="swiperList"
        @question-registered="askSubmit()"
        @inspect-pop="inspectPop"
      ></swiper>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="questionRegistered"
      @history-called="historyCall"
    />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      testList: [],
      swiperList: {
        structure: [
          {
            questionType: 1,
            question: 1,
            category: 0,
            title: "기도 삽관",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
          },
          {
            questionType: 1,
            question: 2,
            category: 0,
            title: "스피킹 밸브 유무",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
          },
          {
            questionType: 1,
            question: 3,
            category: 0,
            title: "호흡기 착용",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
          },
          {
            questionType: 4,
            question: 4,
            category: 0,
            title: "흡입(suction) 횟수",
            inputPlaceHolder: "회/일",
            selectedVal: "",
          },
          {
            questionType: 2,
            question: 5,
            category: 0,
            title: "섭식 양식",
            choiceVal: ["ORAL", "NG", "PEG", "기타"],
            selectedVal: "" /** 기타 */,
            etcVal: "" /** 영사마 */,
          },
          {
            questionType: 3,
            question: 6,
            category: 1,
            title: "식사 시 자세",
            subTitle: " 상체",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 7,
            category: 1,
            title: "식사 시 자세",
            subTitle: "목",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 8,
            category: 1,
            title: "식사 시 자세",
            subTitle: "손가락",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 9,
            category: 1,
            title: "혀 움직이기",
            subTitle: "긴장도",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },

          {
            questionType: 3,
            question: 10,
            category: 1,
            title: "혀 움직이기",
            subTitle: "범위",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 11,
            category: 1,
            title: "혀 움직이기",
            subTitle: "속도",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 12,
            category: 1,
            title: "볼 부풀리기",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 13,
            category: 1,
            title: "입술 다물기",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 14,
            category: 1,
            title: "연구개 움직임",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 15,
            category: 1,
            title: "기침소리 내게 하기",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
          },
          {
            questionType: 3,
            question: 16,
            category: 1,
            title: "마른 연속 삼킴",
            choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
            selectedVal: "",
            isLast: true,
          },
        ],
      },
      notificationInfo: {
        title: "삼킴구조와 기능",
        btnSet: [
          {
            img: "search.png",
            name: "평가조회",
            routerUrl: "evaluateRecord",
            isActionBtn: false,
          },
        ],
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
      view: {
        isPrepare: false,
        gettedId: null,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
    /**
    if (this.$route.query.id !== undefined) {
      this.fetchEvaluateList();
    }
     */
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", [
      "FETCH_EVALUATE_LIST",
      "CHECKING_EVALUATE",
      "SUBMIT_EVALUATE",
    ]),
    inspectPop() {
      this.SET_POPUP(true);
      this.popupSet.content = "입력해주세요";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    historyCall() {
      this.$router.push("/clinical");
    },
    handleGoback() {
      /** 
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/clinical");
      }
      */
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다. \n종료하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.confirmBtnText = "예";
      this.popupSet.nextLink = null;
      this.popupSet.historyCall = true;
    },
    askSubmit() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가를 제출할까요?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = "예";
    },
    transArray() {
      const array = [];
      let sampleObj;
      for (let i = 0; i < this.swiperList.structure.length; i++) {
        sampleObj = {
          question: this.swiperList.structure[i].question,
          answer: this.returnChoiceOrder(i),
          category: this.swiperList.structure[i].category,
          etcText:
            this.swiperList.structure[i].etcVal !== undefined &&
            this.swiperList.structure[i].etcVal !== "" &&
            this.swiperList.structure[i].selectedVal === "기타"
              ? this.swiperList.structure[i].etcVal
              : null,
        };
        array.push(sampleObj);
      }
      console.log("array :", array);
      return array;
    },
    async questionRegistered() {
      try {
        const payload = {
          type: "structure",
          list: this.transArray(),
        };

        await this.SUBMIT_EVALUATE(payload).then(() => {
          this.SET_POPUP(true);
          this.popupSet.popType = "warn";
          this.popupSet.popImg = "success.svg";
          this.popupSet.content = "평가가 완료되었습니다.";
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = "/clinical";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.isApiCall = false;
        });
      } catch (error) {
        this.SET_POPUP(true);
        this.popupSet.content = String(errorParser.parse(error));
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    async listContext() {
      await this.checkEvaluate();
      await this.fetchEvaluateList();
    },
    async fetchEvaluateList() {
      try {
        const payload = {
          type: "structure",
          id: this.$route.query.id,
        };
        await this.FETCH_EVALUATE_LIST(payload).then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            if (i === 4 && data.response.list[i].answer === "3") {
              console.log("진입하긴하나요?");
              (this.swiperList.structure[i].selectedVal = "기타"),
                (this.swiperList.structure[i].etcVal = data.response.list[i].answerText);
            } else {
              this.swiperList.structure[i].selectedVal = data.response.list[i].answerText;
            }
          }
          console.log("data :");
        });
      } catch (error) {
        console.log("error :", error);
      }
      this.view.isPrepare = true;
    },
    async checkEvaluate() {
      try {
        const payload = {
          type: "structure",
        };
        await this.CHECKING_EVALUATE(payload).then((data) => {
          if (data.response.isExist) {
            this.view.gettedId = data.response.id;
          }
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되었습니다.";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "완료";
      this.popupSet.destination = true;
    },

    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    returnChoiceOrder() {
      return (index) => {
        if (this.swiperList.structure[index].question === 4) {
          return Number(this.swiperList.structure[index].value);
        } else {
          return this.swiperList.structure[index].choiceVal.indexOf(
            this.swiperList.structure[index].selectedVal
          );
        }
      };
    },
  },
  beforeDestroy() {
    if (this.checkPopup) {
      this.SET_POPUP(false);
    }
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
