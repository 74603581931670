<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title complex">훈련기록</h5>
      <record-navigation
        :dateList="dateList"
        :recordType="1"
        @call-list-date="callListInfo"
        @call-list-fail="callListFail"
        @trans-date="transedDate"
        @open-calendar="calendarPop"
        ref="recordNav"
        v-if="view.isRecordNavigationVisible"
      >
      </record-navigation>
      <!-- 탭메뉴 시작 -->
      <tabs ref="tabs" @tab-selected="tabSelected" v-if="view.isTabs">
        <tab :tabData="tab[0]">
          <div class="training">
            <div class="item">
              <div class="title">
                <h2>힘껏 오래 삼키기</h2>
                <p class="point">인후두 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.swallow"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 음도 높이기</h2>
                <p class="point">성대 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.raising"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 혀 돌리기</h2>
                <p class="point">혀 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.turn"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
          </div>
        </tab>
        <tab :tabData="tab[1]" v-if="recordList.lunch.length > 0">
          <div class="training">
            <div class="item">
              <div class="title">
                <h2>힘껏 오래 삼키기</h2>
                <p class="point">인후두 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.swallow"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 음도 높이기</h2>
                <p class="point">성대 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.raising"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 혀 돌리기</h2>
                <p class="point">혀 운동</p>
              </div>
              <div class="content">
                <record-table
                  :record="record.turn"
                  :setOpt="view.setOpt"
                  v-if="view.isRecordTableVisible"
                ></record-table>
              </div>
            </div>
          </div>
        </tab>
        <tab :tabData="tab[2]" v-if="recordList.evening.length > 0">
          <div class="training">
            <div class="item">
              <div class="title">
                <h2>힘껏 오래 삼키기</h2>
                <p class="point">인후두 운동</p>
              </div>
              <div class="content">
                <record-table
                  :setOpt="view.setOpt"
                  :record="record.swallow"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 음도 높이기</h2>
                <p class="point">성대 운동</p>
              </div>
              <div class="content">
                <record-table
                  :setOpt="view.setOpt"
                  :record="record.raising"
                ></record-table>
              </div>
            </div>
            <div class="item">
              <div class="title">
                <h2>힘껏 혀 돌리기</h2>
                <p class="point">혀 운동</p>
              </div>
              <div class="content">
                <record-table :setOpt="view.setOpt" :record="record.turn"></record-table>
              </div>
            </div>
          </div>
        </tab>
      </tabs>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <calendar-popup
      @selected-date="selectedDate"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SideMenu from '@/components/SideMenu.vue';
import RecordNavigation from '@component/RecordNavigation.vue';
import RecordTable from '@component/RecordTable.vue';
import Tabs from '@/components/Tabs.vue';
import Tab from '@/components/Tab.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectOrder: 0,
      apiStatus: {
        currentYear: '',
        currentMonth: '',
        currentType: '',
        currentDate: '',
      },
      record: {
        swallow: [],
        raising: [],
        turn: [],
      },
      fetchOpt: {
        id: null,
        date: null,
        firstDay: null,
        lastDay: null,
      },
      tab: [
        {
          title: '아침',
        },
        {
          title: '점심',
        },
        {
          title: '저녁',
        },
      ],
      view: {
        isRecordNavigationVisible: false,
        passedFirstTab: false,
        isRecordTableVisible: false,
        isTabs: false,
        setOpt: {
          set: null,
          number: null,
        },
        limitLunch: false,
        limitEvening: false,
      },
      recordList: {},
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: false,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSetCalendar: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.getDateRange();
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    ...mapActions('training', ['FETCH_MONTHLIST_TRAINING', 'FETCH_TRAINING_DATE']),
    async transedDate(obj) {
      console.log('obj :', obj);
      this.fetchOpt.date = obj.date;
      this.fetchOpt.id = obj.id;
      this.getTrainingList();
    },
    initTrainingList() {
      const arraySample = [];
      this.record.swallow = arraySample;
      this.record.raising = arraySample;
      this.record.turn = arraySample;
      this.view.isTabs = false;
    },
    async getTrainingList() {
      this.initTrainingList();
      try {
        await this.FETCH_TRAINING_DATE(this.fetchOpt.id).then((data) => {
          console.log('data!! : ', data);
          this.view.limitLunch = !data.response.isLunch;
          this.view.limitEvening = !data.response.isEvening;
          const sampleOpt = {
            morning: data.response.morning,
            lunch: data.response.lunch,
            evening: data.response.evening,
          };
          this.recordList = sampleOpt;
          this.view.isTabs = true;
          this.view.setOpt.set = data.response.set;
          this.view.setOpt.number = data.response.number;
        });
        console.log('리스트 불러옴');
      } catch (error) {
        console.log('error-parser :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    callListFail() {
      console.log('진행한 훈련이 없음', this.transToType(this.selectOrder));
      this.$nextTick((_) => {
        this.view.passedFirstTab = true;
      });
    },
    callListInfo(info) {
      console.log('info 정보받음 :', info);
      this.fetchOpt.date = info.date;
      this.fetchOpt.id = info.id;
      /** 리스트 가져오기 */
      this.getTrainingList();
    },

    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/trainingDate');
      }
    },
    async getDateRange() {
      /** select-order를 타입으로 */
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      let monthVal;
      let yearVal;
      if (currentMonth < 3) {
        monthVal = currentMonth + 12;
        yearVal = currentYear - 1;
      } else if (currentMonth < 10) {
        monthVal = currentMonth - 12;
        yearVal = currentYear + 1;
      } else {
        monthVal = currentMonth;
        yearVal = currentYear;
      }
      const firstDay = this.$moment(new Date(yearVal, monthVal - 4, 1)).format(
        'YYYY-MM-DD',
      );
      const lastDay = this.$moment(new Date(yearVal, monthVal + 3, 0)).format(
        'YYYY-MM-DD',
      );
      this.fetchOpt.firstDay = firstDay;
      this.fetchOpt.lastDay = lastDay;
      try {
        const payload = {
          gte: firstDay,
          lte: lastDay,
        };
        await this.FETCH_MONTHLIST_TRAINING(payload).then((data) => {
          this.dateList = data.response.list;
          this.view.isRecordNavigationVisible = true;
        });
      } catch (error) {
        console.log('error :', String(errorParser.parse(error)));
      }
      console.log('firstDay :', firstDay);
      console.log('lastDay :', lastDay);
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    selectedDate(date) {
      console.log('date :', date);
      this.$refs.recordNav.getDayInfo(date);
      /**
      const dateSplit = date.split("-");
      const year = this.apiStatus.currentYear;
      if (dateSplit[0] !== year) {
        this.callRecordData(null, dateSplit[0], date);
      } else {
        this.$refs.record.calculateDateOrder(date);
      }
       */
    },
    tabSelected(order) {
      console.log('tab-select 진입 :', order);
      this.selectOrder = order;
      this.view.isRecordTableVisible = false;
      /** 모닝 구조분해 할당해서 키값 swallow 인 걸 부어야함 */
      const initArr = [];
      this.record.swallow = initArr;
      this.record.raising = initArr;
      this.record.turn = initArr;
      if (order === 0) {
        console.log('검증자 :', this.recordList.morning);
        console.log('검증자 렝스 :', this.recordList.morning.length);
        if (this.recordList.morning.length > 0) {
          for (let i = 0; i < this.recordList.morning.length; i++) {
            if (this.recordList.morning[i].type === 'swallow') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.morning[i].list.length; j++) {
                sampleArr.push(this.recordList.morning[i].list[j]);
              }
              console.log('tabSelected - morning- swallowList :', sampleArr);
              this.record.swallow = sampleArr;
            } else if (this.recordList.morning[i].type === 'raising') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.morning[i].list.length; j++) {
                sampleArr.push(this.recordList.morning[i].list[j]);
              }
              console.log('tabSelected - morning- raisingList :', sampleArr);
              this.record.raising = sampleArr;
            } else if (this.recordList.morning[i].type === 'turn') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.morning[i].list.length; j++) {
                sampleArr.push(this.recordList.morning[i].list[j]);
              }
              console.log('tabSelected - morning - turnList :', sampleArr);
              this.record.turn = sampleArr;
            }
          }
        }
      } else if (order === 1) {
        console.log('검증자 :', this.recordList.lunch);
        console.log('검증자 렝스 :', this.recordList.lunch.length);
        if (this.recordList.lunch.length > 0) {
          for (let i = 0; i < this.recordList.lunch.length; i++) {
            if (this.recordList.lunch[i].type === 'swallow') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.lunch[i].list.length; j++) {
                sampleArr.push(this.recordList.lunch[i].list[j]);
              }
              console.log('tabSelected - lunch - swallowList :', sampleArr);
              this.record.swallow = sampleArr;
            } else if (this.recordList.lunch[i].type === 'raising') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.lunch[i].list.length; j++) {
                sampleArr.push(this.recordList.lunch[i].list[j]);
              }
              console.log('tabSelected - lunch - raisingList :', sampleArr);
              this.record.raising = sampleArr;
            } else if (this.recordList.lunch[i].type === 'turn') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.lunch[i].list.length; j++) {
                sampleArr.push(this.recordList.lunch[i].list[j]);
              }
              console.log('tabSelected - lunch - turnList :', sampleArr);
              this.record.turn = sampleArr;
            }
          }
        }
      } else if (order === 2) {
        console.log('검증자 :', this.recordList.evening);
        console.log('검증자 렝스 :', this.recordList.evening.length);
        if (this.recordList.evening.length > 0) {
          for (let i = 0; i < this.recordList.evening.length; i++) {
            if (this.recordList.evening[i].type === 'swallow') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.evening[i].list.length; j++) {
                sampleArr.push(this.recordList.evening[i].list[j]);
              }
              console.log('tabSelected - evening - swallowList :', sampleArr);
              this.record.swallow = sampleArr;
            } else if (this.recordList.evening[i].type === 'raising') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.evening[i].list.length; j++) {
                sampleArr.push(this.recordList.evening[i].list[j]);
              }
              console.log('tabSelected - evening - raisingList :', sampleArr);
              this.record.raising = sampleArr;
            } else if (this.recordList.evening[i].type === 'turn') {
              const sampleArr = [];
              for (let j = 0; j < this.recordList.evening[i].list.length; j++) {
                sampleArr.push(this.recordList.evening[i].list[j]);
              }
              console.log('tabSelected - evening - turnList :', sampleArr);
              this.record.turn = sampleArr;
            }
          }
        }
      }
      this.view.isRecordTableVisible = true;
    },
    calendarPop() {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.calendarType = 1;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
    transToType() {
      return (order) => {
        if (order === 0) {
          return 'structure';
        }
        if (order === 1) {
          return 'water';
        }
        if (order === 2) {
          return 'solid';
        }
        if (order === 3) {
          return 'vocalization';
        }
      };
    },
    getOrderToTime() {
      return (time) => {
        if (time === 'morning') {
          return 0;
        } if (time === 'lunch') {
          return 1;
        } if (time === 'evening') {
          return 2;
        }
      };
    },
  },
  components: {
    SideMenu,
    RecordTable,
    CalendarPopup,
    RecordNavigation,
    Tabs,
    Tab,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    position: relative;
    .tab-box {
      .tab-wrapper {
        padding: 34px 32px;
        max-height: 490px;
        overflow-y: scroll;
      }
    }
    .training {
      .item {
        display: flex;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(108, 109, 112, 0.1);
        border: solid 1px rgb(187, 188, 193);
        margin: 30px 0 0 0;
        &:first-of-type {
          margin: 0;
        }
        .title {
          width: 182px;
          background-color: rgba(49, 87, 84, 0.1);
          padding: 22px 0;
          h2 {
            @include initfontKor(
              $font-size: 26px,
              $line-height: 36px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #363739,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            margin: 0 0 5px 0;
            width: 100%;
          }
          p.point {
            @include initfontKor(
              $font-size: 24px,
              $line-height: 30px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fd8b11,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
        }
        .content {
          width: calc(100% - 182px);
        }
      }
    }
  }
}
</style>
