<template>
  <div id="app">
    <router-view v-if="view.isPrepared" />
    <etc-popup
      v-if="checkEtcPopup"
      :popupSet="view.popupSet"
      ref="etcPop"
      @version-api-called="linkBack"
    />
  </div>
</template>
<script>
import errorParser from '@/utils/error-parser';
import EtcPopup from '@/components/modal/EtcPopup.vue';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';

export default {
  props: {},
  data() {
    return {
      view: {
        popupSet: {},
        versionOption: null,
        isPrepared: true,
        isRock: false,
      },
    };
  },
  created() {
    window.callbackDeviceInfo = this.callbackDeviceInfo;
    window.callbackAutoLogin = this.callbackAutoLogin;
    document.location.href = 'samkim://getDeviceInfo';
  },
  methods: {
    ...mapMutations('auth', ['SET_FCMTOKEN', 'SET_DEVICEID', 'SET_APPVERSION']),
    ...mapMutations('basic', ['SET_ETC_POPUP']),
    ...mapActions('auth', ['LOGIN_AUTO']),
    ...mapActions('util', ['FETCH_APP_VERSION']),
    linkBack(url) {
      document.location.href = `samkim://appDownload?url=${url}`;
      this.$refs.etcPop.updateIng('업데이트 파일을 다운로드 \n 받는 중입니다.');
    },
    async callbackDeviceInfo(getDeviceData) {
      console.log('콜백디바이스 인포 진입');
      if (getDeviceData !== '') {
        const deviceDataSet = JSON.parse(getDeviceData);
        const { fcmToken } = deviceDataSet;
        const { deviceId } = deviceDataSet;
        const { appVersion } = deviceDataSet;
        const { isAutoLogin } = deviceDataSet;
        console.log('=== 콜백디바이스인포에서 가져온 CallbackDeviceInfo 정보 ===');
        console.log(' fcmToken :', fcmToken);
        console.log(' deviceId :', deviceId);
        console.log(' appVersion :', appVersion);
        console.log(' isAutoLogin :', isAutoLogin);
        this.SET_FCMTOKEN(fcmToken);
        this.SET_DEVICEID(deviceId);
        this.SET_APPVERSION(appVersion);
        console.log('콜벡디바이스인포 저장됨');
        if (isAutoLogin) {
          await this.versionCheck();
          if (!this.view.isRock) {
            await this.checkAuto();
          }
        } else {
          await this.versionCheck('end');
        }
      } else {
        console.log('콜백디바이스 인포에서 에러남');
        // 콜백디바이스 인포 에러일떄를 정리하면 좋을 듯
      }
    },
    checkAuto() {
      console.log('오토로그인을 호출함');
      document.location.href = 'samkim://autoLogin';
    },
    async callbackAutoLogin(getAutoLogin) {
      /** 보여주기 처리 */
      console.log('콜백 오토로그인 진입');
      console.log('콜백오토로그인의 값', getAutoLogin);
      if (getAutoLogin !== undefined) {
        const deviceDataSet = JSON.parse(getAutoLogin);
        const { deviceId } = deviceDataSet;
        const { fcmToken } = deviceDataSet;
        const { sessionToken } = deviceDataSet;
        localStorage.setItem('token', sessionToken);
        try {
          console.log('오토로그인 API 시작점 진입');
          console.log('오토로그인 - deviceId :', deviceId);
          console.log('오토로그인 - fcmToken :', fcmToken);
          console.log('헤더토큰 검증 - ', sessionToken);
          const payload = {
            deviceId,
            fcmToken,
          };
          await this.LOGIN_AUTO(payload).then((data) => {
            console.log('로그인 후 발급받은 토큰', data.response.token);
            localStorage.setItem('token', data.response.token);
            localStorage.setItem('id', data.response.id);
            if (localStorage.getItem('userName') !== data.response.userName) {
              localStorage.removeItem('passedDailyComment');
            }
            localStorage.setItem('userName', data.response.userName);
            const url = `samkim://refreshSessionToken?sessionToken=${data.response.token}`;
            document.location.href = url;
          });
        } catch (error) {
          localStorage.removeItem('token');
          console.log('오토로그인 API 실패:');
          console.log('오토로그인 error: ', errorParser.parse(error));
          this.view.isPrepared = true;
        }
      } else {
        this.view.isPrepared = true;
      }
    },
    async versionCheck(string) {
      console.log('버전 로직 진입');
      console.log('앱이 가져다준 버전은?', this.GET_APPVERSION);
      if (string === undefined) {
        console.log('버전 이후 오토로그인 예정');
      } else {
        console.log('버전 이후 렌더 예정');
      }
      try {
        await this.FETCH_APP_VERSION().then((data) => {
          console.log('버전의 data :', data.response.option);
          /**
          this.SET_ETC_POPUP(true);
          this.view.popupSet.content = "업데이트 권고사항입니다. (테스트중)";
          this.view.popupSet.popImg = "warning.svg";
          this.view.popupSet.cancelBtnText = null;
          this.view.popupSet.confirmBtnText = "확인";
          this.view.popupSet.mobileLink =
            "https://dev.swallow.pro/media/public/apk/version.apk";
          this.view.popupSet.versionApiCall = true;
          if (string === "end") {
            this.view.isPrepared = true;
          }
           */

          if (this.GET_APPVERSION < data.response.version) {
            if (Number(data.response.option) === 0) {
              if (string === 'end') {
                this.view.isPrepared = true;
              }
              console.log('버전확인 요망 - 테스트 -');
            } else if (Number(data.response.option) === 1) {
              this.SET_ETC_POPUP(true);
              this.view.popupSet.content = '업데이트가 필요합니다. \n 진행하시겠습니까?';
              this.view.popupSet.popImg = 'warning.svg';
              this.view.popupSet.cancelBtnText = '취소';
              this.view.popupSet.confirmBtnText = '확인';
              this.view.popupSet.mobileLink = 'https://dev.swallow.pro/media/public/apk/version.apk';
              if (string === 'end') {
                this.view.isPrepared = true;
              }
            } else if (Number(data.response.option) === 2) {
              this.view.isRock = true;
              this.SET_ETC_POPUP(true);
              this.view.popupSet.content = '최신 버전으로 업데이트가 필요합니다.';
              this.view.popupSet.popImg = 'warning.svg';
              this.view.popupSet.cancelBtnText = null;
              this.view.popupSet.confirmBtnText = '확인';
              this.view.popupSet.mobileLink = 'https://dev.swallow.pro/media/public/apk/version.apk';
              this.view.popupSet.versionApiCall = true;
              this.view.popupSet.destination = true;
            }
          } else {
            console.log('현재버전과 동일합니다. -');
            if (string === 'end') {
              this.view.isPrepared = true;
            }
          }
        });
      } catch (error) {
        console.log('버전에서 error :', errorParser.parse(error));
        if (string === 'end') {
          this.view.isPrepared = true;
        }
      }
    },
  },
  computed: {
    ...mapState('basic', ['checkEtcPopup']),
    ...mapGetters('auth', ['GET_APPVERSION']),
  },
  components: {
    EtcPopup,
  },
};
</script>
<style lang="scss">
@import "assets/css/init.css";
@import "assets/fonts/stylesheet.css";
</style>
