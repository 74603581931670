<template>
  <div class="app-context deep flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">탐색안내</h5>
      <div class="banner">
        <div class="title-wrap">
          <h3 class="b-title">삶의 의미 탐색이란?</h3>
        </div>
        <div class="article first">
          <p>
            <b class="bigger point">삶</b
            ><b class="point">의 의미 탐색 프로그램(SMiLE)</b>은 삶의 소중함과 의미를
            찾아가는 여정입니다.
          </p>
          <p>총 40회로 구성이 되어있으며, 한 회당 약 15분 정도의 시간이 소요됩니다.</p>
        </div>
        <div class="article">
          <p><b class="bigger">다</b>양한 활동들을 통해 인생의 소중한 가치를 기억하고</p>
          <p>나눔과 베품을 실천할 수 있도록</p>
          <p>여러분을 안내하겠습니다.</p>
        </div>
        <div class="article">
          <p><b class="bigger">가</b>까운 친구와 이야기를 나누는 기분으로</p>
          <p>함께 해주시기 바랍니다.</p>
          <p>여러분의 당당하고 의미 있는 인생을 응원합니다.</p>
        </div>
        <div class="b-bg">
          <img src="@/assets/images/search/person.svg" />
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import SideMenu from "@/components/SideMenu.vue";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: true,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/choiceMeaning");
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = "테스트";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  components: {
    SideMenu,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    h5.page-title {
      margin: 0 0 37px 0;
    }
    .banner {
      padding: 0 40px;
      border-radius: 5px;
      border: solid 1px rgb(234, 235, 241);
      background-color: rgb(255, 255, 255);
      position: relative;
      min-height: 541px;
      overflow: hidden;
      &:after {
        content: "";
        background-image: url("~@assets/images/search/Information.png");
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
      }
      .title-wrap {
        height: 86px;
        position: relative;
        h3.b-title {
          position: absolute;
          top: 32px;
          @include initfontKor(
            $font-size: 30px,
            $line-height: 36px,
            $letter-spacing: -0.6px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $text-align: left
          );
          z-index: 1;
        }
      }
      .article {
        margin: 30px 0 0 0;
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $letter-spacing: -0.52px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $text-align: left
          );
          b {
            font-weight: normal;
            &.bigger {
              @include initfontKor(
                $font-size: 30px,
                $line-height: 34px,
                $letter-spacing: -0.6px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #363739,
                $text-align: left
              );
            }
            &.point {
              color: #fd8b11;
            }
          }
        }
        &.first {
          margin: 0;
        }
      }
      .b-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
      }
    }
  }
}
</style>
