<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">훈련안내</h5>
      <div class="notificaton-box">
        <h4 class="title">삼킴과 삼킴장애란?</h4>
        <button type="button" @click="goObstacle()">자세히</button>
      </div>
      <block-menu :menuList="menuList" :menuType="menuType"></block-menu>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import SideMenu from "@/components/SideMenu.vue";
import BlockMenu from "@component/BlockMenu.vue";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      menuType: 4,
      menuList: [
        {
          img: "infoItem01.png",
          name: "힘껏 오래 삼키기",
          content: "인후두 운동",
          isActive: false,
        },
        {
          img: "infoItem02.png",
          name: "힘껏 음도 높이기",
          content: "성대 운동",
          isActive: true,
        },
        {
          img: "infoItem03.png",
          name: "힘껏 혀 돌리기",
          content: "혀 운동",
          isActive: false,
        },
      ],
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: true,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/trainingDate");
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    goObstacle() {
      this.$router.push("/infoObstacle");
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = "테스트";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  components: {
    SideMenu,
    BlockMenu,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    h5.page-title {
      position: relative;
      padding: 20px 0 0 0px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #315754,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      margin: 0 0 38px 0;
      &:after {
        content: "";
        background-image: url("~@assets/images/util/mark.png");
        position: absolute;
        width: 26px;
        height: 26px;
        top: 0;
        left: -20px;
      }
    }
    .notificaton-box {
      display: flex;
      width: 100%;
      height: 100px;
      background: #fff;
      padding: 24px 22px 24px 40px;
      justify-content: space-between;
      border-radius: 5px;
      box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
      border: solid 0.5px #bbbcc1;
      margin: 0 0 30px 0;
      position: relative;
      &:after {
        content: "";
        background-image: url("~@assets/images/search/Information.png");
        position: absolute;
        top: 0;
        width: 80px;
        height: 80px;
        left: 0;
      }
      h4.title {
        z-index: 1;
        @include initfontKor(
          $font-size: 30px,
          $line-height: 52px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #363739,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      button {
        width: 226px;
        height: 52px;
        padding: 0;
        border: 0;
        border-radius: 4px;
        background: #315754;
        cursor: pointer;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 31px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
    }
  }
}
</style>
