<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
      :sideMenuDeep="true"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <swiper
        :swiperList="swiperList"
        @question-registered="askSubmit"
        @inspect-pop="inspectPop"
        :checkGds="true"
        v-if="view.listFetched"
      ></swiper>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="questionRegistered"
      @history-called="historyCall"
    />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      swiperList: {
        functional: [
          {
            question: 1,
            questionType: 3,
            category: 1,
            title: "평소에 숨 쉬는 것이 힘들다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 2,
            questionType: 3,
            category: 1,
            title: "평소에 식사할 때 숨이 차다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 3,
            questionType: 3,
            category: 1,
            title: "평소에 입이 건조하거나 목이 마르다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 4,
            questionType: 3,
            category: 1,
            title: "예전에 비해 한 끼 식사량이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 5,
            questionType: 3,
            category: 1,
            title: "예전에 비해 집에서의 식사 시간이 오래 걸린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 6,
            questionType: 3,
            category: 1,
            title: "음식을 입에 넣으면서 흘린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 7,
            questionType: 3,
            category: 1,
            title: "음식을 씹으면서 흘린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 8,
            questionType: 3,
            category: 1,
            title: "컵으로 물 마실 때 흘린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 9,
            questionType: 3,
            category: 1,
            title: "음식을 씹기가 힘들다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 10,
            questionType: 3,
            category: 1,
            title: "음식을 먹은 후에 혀 밑에 음식물이 남아있다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 11,
            questionType: 3,
            category: 1,
            title: "알약을 넘기기가 힘들다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 12,
            questionType: 3,
            category: 1,
            title: "마른 음식 (예: 건빵, 크래커)을 먹기가 힘들다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 13,
            questionType: 3,
            category: 1,
            title: "음식을 삼킬 때 목이 아프다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 14,
            questionType: 3,
            category: 1,
            title: "물이나 음식이 코로 넘어온다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 15,
            questionType: 3,
            category: 1,
            title: "물이나 액체를 삼키는 것이 어렵다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 16,
            questionType: 3,
            category: 1,
            title: "물이나 액체에 사레가 걸린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 17,
            questionType: 3,
            category: 1,
            title: "예전에 비해 발음이 어눌해졌다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
          {
            question: 18,
            questionType: 3,
            category: 1,
            title: "예전에 비해 말하는 목소리가 변했다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴기능",
          },
        ],
        chew: [
          {
            question: 19,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 체중이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 20,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 한 끼 식사량이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 21,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 식사시간이 오래 걸린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 22,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 먹고 싶은 것을 제대로 못 먹는다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 23,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 먹는 즐거움이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 24,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 사람들과의 모임을 꺼린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 25,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 외식하는 것을 꺼린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 26,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 우울하다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 27,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 속상하다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
          {
            question: 28,
            questionType: 3,
            category: 2,
            title: "씹는 문제 때문에 스트레스를 받는다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "씹기 삶의 질",
          },
        ],
        swallow: [
          {
            question: 29,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 체중이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 30,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 한 끼 식사량이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 31,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 식사시간이 오래 걸린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 32,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 먹고 싶은 것을 제대로 못 먹는다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 33,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 먹는 즐거움이 줄었다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 34,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 사람들과의 모임을 꺼린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 35,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 외식하는 것을 꺼린다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 36,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 우울하다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 37,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 속상하다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
          {
            question: 38,
            questionType: 3,
            category: 3,
            title: "삼키는 문제 때문에 스트레스를 받는다",
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다",
            ],
            selectedVal: "",
            currentTitle: "삼킴 삶의 질",
          },
        ],
        gds: [
          {
            question: 39,
            questionType: 1,
            category: 4,
            title: "현재의 생활에 대체적으로 만족하십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 40,
            questionType: 1,
            category: 4,
            title: "요즈음 들어 활동량이나 의욕이 많이 떨어지셨습니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 41,
            questionType: 1,
            category: 4,
            title: "자신이 헛되이 살고 있다고 느끼십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 42,
            questionType: 1,
            category: 4,
            title: "생활이 지루하게 느껴질 때가 많습니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 43,
            questionType: 1,
            category: 4,
            title: "평소에 기분은 상쾌한 편입니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 44,
            questionType: 1,
            category: 4,
            title: "자신에게 불길한 일이 닥칠 것 같아 불안하십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 45,
            questionType: 1,
            category: 4,
            title: "대체로 마음이 즐거운 편이십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 46,
            questionType: 1,
            category: 4,
            title: "절망적이라는 느낌이 자주 드십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 47,
            questionType: 1,
            category: 4,
            title: "바깥에 나가기가 싫고 집에만 있고 싶습니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 48,
            questionType: 1,
            category: 4,
            title: "비슷한 나이의 다른 노인들보다 기억력이\n 더 나쁘다고 느끼십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 49,
            questionType: 1,
            category: 4,
            title: "현재 살아있다는 것이 즐겁게 생각되십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 50,
            questionType: 1,
            category: 4,
            title: "지금의 내 자신이 아무 쓸모없는 사람이라고 느끼십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 51,
            questionType: 1,
            category: 4,
            title: "기력이 좋은 편이십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 52,
            questionType: 1,
            category: 4,
            title: "지금 자신의 처지가 아무런 희망도 없다고 느끼십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
          },
          {
            question: 53,
            questionType: 1,
            category: 4,
            title: "자신이 다른 사람들의 처지보다 더 못하다고 생각하십니까?",
            choiceVal: ["예", "아니오"],
            selectedVal: "",
            currentTitle: "GDS",
            isLast: true,
          },
        ],
      },
      notificationInfo: {
        title: "삼킴 설문",
        btnSet: [],
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      view: {
        gettedId: null,
        prev: "/evaluate",
        isPassedGds: false,
        listFetched: false,
      },
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
    console.log("id 찾아줘 :", this.$route.query.id);
    if (this.$route.query.id !== undefined) {
      this.fetchExplain();
    } else {
      this.view.listFetched = true;
    }
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", [
      "FETCH_EVALUATE_LIST",
      "CHECKING_EVALUATE",
      "SUBMIT_EVALUATE",
      "MODIFY_EVALUATE",
    ]),
    inspectPop() {
      this.SET_POPUP(true);
      this.popupSet.content = "입력해주세요";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    historyCall() {
      this.questionRegistered(true);
    },
    handleGoback() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다. \n종료하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.confirmBtnText = "예";
      if (this.swiperList.functional[0].selectedVal !== "") {
        this.popupSet.nextLink = null;
        this.popupSet.historyCall = true;
      } else {
        this.popupSet.nextLink = "evaluate";
        this.popupSet.destination = true;
      }
    },
    askSubmit() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가를 제출할까요?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = "예";
    },
    transArray() {
      const array = [];
      let sampleObj;
      let isPassedGds = true;
      for (let i = 0; i < this.swiperList.functional.length; i++) {
        if (
          this.swiperList.functional[i].selectedVal !== null &&
          this.swiperList.functional[i].selectedVal !== ""
        ) {
          sampleObj = {
            question: this.swiperList.functional[i].question,
            answer: this.returnChoiceOrder(i, "functional"),
            category: this.swiperList.functional[i].category,
          };
          array.push(sampleObj);
          if (
            this.swiperList.functional[i].selectedVal !== "전혀\n그렇지 않다" &&
            this.swiperList.functional[i].selectedVal !== ""
          ) {
            isPassedGds = false;
          }
        }
      }
      console.log("transArray 내에서의 isPassedGds", isPassedGds);
      if (!isPassedGds) {
        /** 정상진행 프로세스 */
        for (let i = 0; i < this.swiperList.chew.length; i++) {
          if (
            this.swiperList.chew[i].selectedVal !== null &&
            this.swiperList.chew[i].selectedVal !== "" &&
            this.swiperList.chew[i].selectedVal !== undefined
          ) {
            sampleObj = {
              question: this.swiperList.chew[i].question,
              answer: this.returnChoiceOrder(i, "chew"),
              category: this.swiperList.chew[i].category,
            };
            array.push(sampleObj);
          } else if (this.swiperList.chew[i].selectedVal === undefined) {
            console.log("츄언디딩");
            sampleObj = {
              question: this.swiperList.chew[i].question,
              answer: "",
              category: this.swiperList.chew[i].category,
            };
            array.push(sampleObj);
          }
        }
        for (let i = 0; i < this.swiperList.swallow.length; i++) {
          if (
            this.swiperList.swallow[i].selectedVal !== null &&
            this.swiperList.swallow[i].selectedVal !== "" &&
            this.swiperList.swallow[i].selectedVal !== undefined
          ) {
            sampleObj = {
              question: this.swiperList.swallow[i].question,
              answer: this.returnChoiceOrder(i, "swallow"),
              category: this.swiperList.swallow[i].category,
            };
            array.push(sampleObj);
          } else if (this.swiperList.swallow[i].selectedVal === undefined) {
            console.log("오래삼키기언디딩");
            sampleObj = {
              question: this.swiperList.swallow[i].question,
              answer: "",
              category: this.swiperList.swallow[i].category,
            };
            array.push(sampleObj);
          }
        }
      } else {
        /** GDS로 직행한 경우 */
        for (let i = 0; i < this.swiperList.chew.length; i++) {
          sampleObj = {
            question: this.swiperList.chew[i].question,
            answer: "",
            category: this.swiperList.chew[i].category,
          };
          array.push(sampleObj);
        }
        for (let i = 0; i < this.swiperList.swallow.length; i++) {
          sampleObj = {
            question: this.swiperList.swallow[i].question,
            answer: "",
            category: this.swiperList.swallow[i].category,
          };
          array.push(sampleObj);
        }
      }

      for (let i = 0; i < this.swiperList.gds.length; i++) {
        if (
          this.swiperList.gds[i].selectedVal !== null &&
          this.swiperList.gds[i].selectedVal !== ""
        ) {
          sampleObj = {
            question: this.swiperList.gds[i].question,
            answer: this.returnChoiceOrder(i, "gds"),
            category: this.swiperList.gds[i].category,
          };
          array.push(sampleObj);
        }
      }
      console.log("array :", array);
      this.view.isPassedGds = isPassedGds;
      return array;
    },
    async questionRegistered(historyFlag) {
      if (this.$route.query.id === undefined) {
        try {
          const payload = {
            type: "survey",
            list: this.transArray(),
            isDirectGds: this.view.isPassedGds,
          };
          await this.SUBMIT_EVALUATE(payload).then(() => {
            this.SET_POPUP(true);
            this.popupSet.popType = "warn";
            this.popupSet.popImg = "success.svg";
            if (!historyFlag) {
              this.popupSet.content = "평가가 완료되었습니다.";
            } else {
              this.popupSet.content = "평가가 임시저장 되었습니다.";
            }
            this.popupSet.cancelBtnText = null;
            this.popupSet.nextLink = "/evaluate";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.popupSet.isApiCall = false;
            this.popupSet.historyCall = false;
          });
        } catch (error) {
          this.SET_POPUP(true);
          this.popupSet.content = String(errorParser.parse(error));
          this.popupSet.popImg = "warning.svg";
          this.popupSet.cancelBtnText = "확인";
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
        }
      } else {
        try {
          const payload = {
            type: "survey",
            list: this.transArray(),
            isDirectGds: this.view.isPassedGds,
            id: this.$route.query.id,
          };
          await this.MODIFY_EVALUATE(payload).then(() => {
            this.SET_POPUP(true);
            this.popupSet.popType = "warn";
            this.popupSet.popImg = "success.svg";
            this.popupSet.content = "평가가 수정되었습니다.";
            this.popupSet.cancelBtnText = null;
            this.popupSet.nextLink = "/evaluate";
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.destination = true;
            this.popupSet.isApiCall = false;
            this.popupSet.historyCall = false;
          });
        } catch (error) {
          this.SET_POPUP(true);
          this.popupSet.content = String(errorParser.parse(error));
          this.popupSet.popImg = "warning.svg";
          this.popupSet.cancelBtnText = "확인";
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
        }
      }
    },
    async checkExplain() {
      try {
        const payload = {
          type: "survey",
        };
        await this.CHECKING_EVALUATE(payload).then((data) => {
          if (data.response.isExist) {
            this.view.gettedId = data.response.id;
          }
        });
      } catch (error) {
        console.log("error :", String(errorParser.parse(error)));
      }
    },
    async fetchExplain() {
      try {
        const payload = {
          type: "survey",
          id: this.$route.query.id,
        };
        await this.FETCH_EVALUATE_LIST(payload).then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            if (i < 18) {
              this.swiperList.functional[i].selectedVal = this.returnChoiceVal(
                i,
                data.response.list[i].answer,
                "functional"
              );
            } else if (i < 28) {
              this.swiperList.chew[i - 18].selectedVal = this.returnChoiceVal(
                i - 18,
                data.response.list[i].answer,
                "chew"
              );
            } else if (i < 38) {
              this.swiperList.swallow[i - 28].selectedVal = this.returnChoiceVal(
                i - 28,
                data.response.list[i].answer,
                "swallow"
              );
            } else {
              this.swiperList.gds[i - 38].selectedVal = this.returnChoiceVal(
                i - 38,
                data.response.list[i].answer,
                "gds"
              );
            }
          }
          console.log("data-List :", data.response.list);
          this.view.listFetched = true;
        });
      } catch (error) {
        console.log("error-origin", error);
        console.log("error@ :", String(errorParser.parse(error)));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되었습니다.";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "완료";
      this.popupSet.destination = true;
    },
    async fillSwiperList() {
      await this.checkExplain();
      await this.fetchExplain();
    },
    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    returnChoiceVal() {
      return (index, idx, type) => {
        if (type === "functional") {
          this.swiperList.functional[index].flag = true;
          return this.swiperList.functional[index].choiceVal[idx];
        }
        if (type === "chew") {
          this.swiperList.chew[index].flag = true;
          return this.swiperList.chew[index].choiceVal[idx];
        }
        if (type === "swallow") {
          this.swiperList.swallow[index].flag = true;
          return this.swiperList.swallow[index].choiceVal[idx];
        }
        if (type === "gds") {
          this.swiperList.gds[index].flag = true;
          return this.swiperList.gds[index].choiceVal[idx];
        }
      };
    },
    returnChoiceOrder() {
      return (index, type) => {
        let returnThings;
        if (type === "functional") {
          if (this.swiperList.functional[index].selectedVal !== "") {
            return this.swiperList.functional[index].choiceVal.indexOf(
              this.swiperList.functional[index].selectedVal
            );
          } else {
            returnThings = "";
          }
          console.log("rere :", returnThings);
          return returnThings;
        }
        if (type === "chew") {
          if (this.swiperList.chew[index].selectedVal !== "") {
            returnThings = this.swiperList.chew[index].choiceVal.indexOf(
              this.swiperList.chew[index].selectedVal
            );
          } else {
            returnThings = "";
          }
          return returnThings;
        }
        if (type === "swallow") {
          if (this.swiperList.swallow[index].selectedVal !== "") {
            returnThings = this.swiperList.swallow[index].choiceVal.indexOf(
              this.swiperList.swallow[index].selectedVal
            );
          } else {
            returnThings = "";
          }
          return returnThings;
        }
        if (type === "gds") {
          if (this.swiperList.gds[index].selectedVal !== "") {
            returnThings = this.swiperList.gds[index].choiceVal.indexOf(
              this.swiperList.gds[index].selectedVal
            );
          } else {
            returnThings = "";
          }
          return returnThings;
        }
      };
    },
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper,
  },
  beforeDestroy() {
    this.SET_POPUP(false);
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
