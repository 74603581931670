<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption"></side-menu>
    <div class="wrap-context inner">
      <notification :notificationInfo="notificationInfo"></notification>
      <swiper :swiperList="swiperList" :swiperStatus="swiperStatus"></swiper>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";

export default {
  props: {},
  data() {
    return {
      notificationInfo: {
        title: "삼킴기능 임상평가",
        btnSet: [
          {
            img: "mainItem01.png",
            name: "훈련안내",
            isActionBtn: false
          },
          {
            img: "mainItem02.png",
            name: "훈련기록",
            isActionBtn: false
          }
        ]
      },
      swiperStatus: {
        sectionStartNum: [4, 9],
        listCurrentOrder: 1
      },
      swiperList: [],
      swiperListPreView: [
        {
          questionType: 1,
          title: "기도 삽관",
          choiceVal: ["아니오", "예"],
          selectedVal: "예"
        },
        {
          questionType: 1,
          title: "스피킹 밸브 유무",
          choiceVal: ["아니오", "예"],
          selectedVal: "아니오"
        },
        {
          questionType: 1,
          title: "호흡기 착용",
          choiceVal: ["아니오", "예"],
          selectedVal: "예"
        },
        {
          questionType: 4,
          title: "흡입(suction) 횟수",
          inputPlaceHolder: "회/일",
          selectedVal: "석션"
        },
        {
          questionType: 2,
          title: "섭식 양식",
          choiceVal: ["ORAL", "NG", "PEG", "기타"],
          selectedVal: "NG"
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "식사 시 자세 : 상체",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: "보통"
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "식사 시 자세 : 목",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "식사 시 자세 : 손가락",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "혀 움직이기 : 긴장도",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "혀 움직이기 : 범위",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "혀 움직이기 : 속도",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "볼 부풀리기",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "입술 다물기",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "연구개 움직임",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "기침소리 내게 하기",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        },
        {
          questionType: 3,
          header: "반응척도",
          title: "마른 연속 삼킴",
          choiceVal: ["정상", "경도", "중증도", "중도", "심도"],
          selectedVal: null
        }
      ],
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: true,
          link: ""
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate"
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate"
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning"
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType"
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType"
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage"
        }
      ]
    };
  },
  created() {
    this.getSwiperList();
  },
  methods: {
    getSwiperList() {
      /**
       * getters으로 리스트를 가져옴
       * length 가 0이 아니면 getters의 리스트를 가져옴
       * 그리고 status의 currentOrder 를 변경해줌
       * */
      this.swiperList = this.swiperListPreView;
      this.swiperStatus.listCurrentOrder = 6;
    }
  },
  computed: {},
  components: {
    SideMenu,
    Notification,
    Swiper
  }
};
</script>
<style lang="scss" scoped></style>
