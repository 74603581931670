<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context infomation-page">
      <div class="audio-player" :class="{ activated: plyrValue.isPrepared }">
        <vue-plyr :options="plyrOption" ref="plyr">
          <audio controls crossorigin playsinline>
            <source
              size="1080"
              :src="require(`@/assets/audio/${plyrValue.src}`)"
              type="audio/aac"
            />
          </audio>
        </vue-plyr>
      </div>
      <div class="close" @click="closeInfo(information.type)">
        <img src="@/assets/images/util/close.svg" />
      </div>
      <div class="content-wrap">
        <h5 class="title">
          {{ information.type !== "life" ? information.day : infomation.lifeDay }}일차
        </h5>
        <div class="audio-dummy" @click="audioToggle()">
          <img src="@/assets/images/util/icon_sound.svg" />
        </div>
        <p class="paragraph" v-html="information.comment"></p>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
import errorParser from "@/utils/error-parser";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      plyrOption: {
        autoplay: false,
        /**
          controls: ['play', 'fullscreen'],
           */
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: "audio-player"
        }
      },
      plyrValue: {
        duration: "",
        player: "",
        src: "sampleAudio.aac",
        isPrepared: false,
        isPlay: false
      },
      information: {},
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: true,
          link: ""
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate"
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate"
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning"
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType"
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType"
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage"
        }
      ],
      view: {
        isVisible: false
      }
    };
  },
  created() {
    this.getInfoWord(this.$route.query.type, this.$route.query.time);
  },
  mounted() {
    /** 시간업데이트 */
    this.$refs.plyr.player.on("timeupdate", data => {
      const value = data.detail.plyr;
      console.log("duration", value.currentTime);
    });
    /** 오디오 초기시작 */
    this.$refs.plyr.player.on("playing", data => {});

    /** 오디오 재개 */
    this.$refs.plyr.player.on("play", data => {});
    /** 오디오 중지 */
    this.$refs.plyr.player.on("pause", data => {});

    /** 오디오 완료 */
    this.$refs.plyr.player.on("ended", data => {
      this.closeInfo(type);
    });
    /** 오디오 전체화면 시작 */
    this.$refs.plyr.player.on("enterfullscreen", data => {});

    /** 오디오 전체화면 중지 */
    this.$refs.plyr.player.on("exitfullscreen", data => {});
    /**
    sessionStorage.removeItem("infoPage");
     */
  },
  methods: {
    ...mapActions("util", ["FETCH_COMMENT", "FETCH_COMMENT_TRAINING"]),
    closeInfo(type) {
      if (this.$refs.plyr.player.playing) {
        this.$refs.plyr.player.stop();
      }
      /** 정의할 부분 */
    },
    audioToggle() {
      if (this.$refs.plyr.player.playing) {
        this.$refs.plyr.player.pause();
      } else {
        this.$refs.plyr.player.play();
      }
    },
    async getInfoWord(type, time) {
      try {
        const info = {
          type,
          time
        };
        await this.FETCH_COMMENT(info).then(data => {
          const information = {
            type,
            time,
            comment: data.response.comment,
            day: data.response.day,
            lifeDay: data.response.lifeDay
          };
          this.information = information;
        });
      } catch (error) {
        console.log("error-parser :", errorParser.parse(error));
        console.log("error :", error);
      }
      this.$nextTick(_ => {
        this.initAudio(this.information.day);
      });
    },
    initSource(order) {
      this.$refs.plyr.player.source = {
        type: "audio",
        title: "Example title",
        sources: [
          {
            src: require(`@/assets/audio/sampleAudio${order}.mp3`)
          }
        ]
      };
      this.plyrValue.isPrepared = true;
    },
    initAudio(day) {
      this.plyrValue.player = this.$refs.plyr.player;
      this.initSource(day);
      /**
       * this.plyrValue.isPrepare = false;
         this.plyrValue.currentRepeatCount = 1;
       */
    }
  },
  computed: {
    computedLine() {
      return text => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        }
        return text;
      };
    },
    makeDayComment() {
      return (type, day) => {
        let word;
        if (type === "total") {
          if (day === 1) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치를 시작하는 <b>첫날</b>입니다.\n음식물 삼킬 때 사레 들릴까봐 걱정한 적이 있으셨나요? \n그저 나이만 드는 것 같아 우울할 때가 있나요?";
            return this.computedLine(word);
          }
          if (day === 2) {
            word =
              "안녕하세요. 삼킴 건강코치 <b>두 번째 날</b>입니다.\n좋은 하루 시작하셨는지요? \n오늘도 삼킴훈련부터 함께 해 볼까요.";
            return this.computedLine(word);
          }
          if (day === 3) {
            word =
              "안녕하세요. 삼킴 건강코치 <b>세 번째 날</b>입니다.\n 오늘도 힘내서 삼킴훈련을 시작하겠습니다.";
            return this.computedLine(word);
          }
          if (day === 4) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>네 번째 날</b>입니다.\n 활기찬 아침 시작하셨나요?";
            return this.computedLine(word);
          }
          if (day === 5) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치 <b>다섯 번째 날</b>입니다.\n 포기하지 않고 노력하시는 모습이 아름다워요!";
            return this.computedLine(word);
          }
          if (day === 6) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치 <b>여섯 번째 날</b>입니다.\n 상쾌하게 삼킴훈련을 시작해볼까요.";
            return this.computedLine(word);
          }
          if (day === 7) {
            word =
              "반갑습니다. 오늘은 삼킴 건강코치, <b>일곱 번째 날</b>입니다.\n 첫 주의 마지막 날이네요. 한 주간 고생 많으셨어요.";
            return this.computedLine(word);
          }
          if (day === 8) {
            word =
              "반갑습니다. 오늘은 삼킴 건강코치, <b>여덟 번째 날</b>입니다.\n 활기차게 하루 시작하셨나요? 삼킴훈련부터 시작할게요.";
            return this.computedLine(word);
          }
          if (day === 9) {
            word =
              "반갑습니다. 오늘은 삼킴 건강코치, <b>아홉 번째 날</b>입니다.\n 매일 조금씩 꾸준히 하는 것은 가장 좋은 훈련이에요.";
            return this.computedLine(word);
          }
          if (day === 10) {
            word =
              "반갑습니다. 오늘은 삼킴 건강코치를 시작한 지 벌써 <b>열 번째 날</b>이네요.\n 어제보다 나은 오늘을 꿈꾸며, 삼킴훈련 프로그램을 시작하겠습니다.";
            return this.computedLine(word);
          }
          if (day === 11) {
            word =
              "반갑습니다. 오늘은 삼킴 건강코치, <b>열한 번째 날</b>입니다.\n 새로운 마음으로 삼킴훈련부터 출발하겠습니다.";
            return this.computedLine(word);
          }
          if (day === 12) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열두 번째 날</b>입니다.\n 프로그램을 통해 삼킴이 많이 편안해지셨나요?";
            return this.computedLine(word);
          }
          if (day === 13) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열세 번째 날</b>입니다.\n 오늘은 어제보다 얼마나 나아졌을까요?";
            return this.computedLine(word);
          }
          if (day === 14) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열네 번째 날</b>입니다.\n 안전한 삼킴을 위한 삼킴훈련부터 시작해볼까요.";
            return this.computedLine(word);
          }
          if (day === 15) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열다섯 번째 날</b>입니다.\n 벌써 함께한 지 보름이나 되었습니다! 오늘도 기운차게 해봐요.";
            return this.computedLine(word);
          }
          if (day === 16) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열여섯 번째 날</b>입니다.\n 지난밤 개운하게 잘 주무셨나요?";
            return this.computedLine(word);
          }
          if (day === 17) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열일곱 번째 날</b>입니다.\n 오늘도 만나게 되어 반가워요.";
            return this.computedLine(word);
          }
          if (day === 18) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열여덟 번째 날</b>입니다.\n 오늘의 훈련도 응원하겠습니다!";
            return this.computedLine(word);
          }
          if (day === 19) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>열아홉 번째 날</b>입니다.\n 어제 하루의 마무리는 즐거우셨나요?";
            return this.computedLine(word);
          }
          if (day === 20) {
            word =
              "안녕하세요. 드디어 삼킴 건강코치, <b>스무 번째 날</b>입니다.\n 벌써 훈련의 반을 달려오셨어요!";
            return this.computedLine(word);
          }
          if (day === 21) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물한 번째 날</b>입니다.\n 오늘도 힘을 내서 함께 해주세요.";
            return this.computedLine(word);
          }
          if (day === 22) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물두 번째 날</b>입니다.\n 훈련을 시작하기 전 잠시 기지개를 켜보는 것은 어떨까요?";
            return this.computedLine(word);
          }
          if (day === 23) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물세 번째 날</b>입니다.\n 크게 소리내어 웃으면서 오늘의 프로그램 시작해볼까요.";
            return this.computedLine(word);
          }
          if (day === 24) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물네 번째 날</b>입니다.\n 기대하는 마음으로 오늘의 프로그램을 시작할게요.";
            return this.computedLine(word);
          }
          if (day === 25) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물다섯 번째 날</b>입니다.\n 프로그램에 참여할 준비가 되셨나요?";
            return this.computedLine(word);
          }
          if (day === 26) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물여섯 번째 날</b>입니다.\n 여러분의 하루를 응원합니다. 오늘도 삼킴훈련으로 프로그램을 시작하겠어요.";
            return this.computedLine(word);
          }
          if (day === 27) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물일곱 번째 날</b>입니다.\n 오늘도 즐겁게 시작해보실까요?";
            return this.computedLine(word);
          }
          if (day === 28) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물여덟 번째 날</b>입니다.\n 잠시 눈을 감고 심호흡을 크게 세 번만 쉬어 봅시다. 편안한 마음으로 시작해주세요.";
            return this.computedLine(word);
          }
          if (day === 29) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>스물아홉 번째 날</b>입니다.\n 편안한 마음으로 시작할게요.";
            return this.computedLine(word);
          }
          if (day === 30) {
            word =
              "안녕하세요. 삼킴 건강코치와 한 지 벌써 <b>서른 번째 날</b>이 되었어요.\n 한 달 동안 함께 해주셔서 고맙습니다. 오늘도 시작해봅시다.";
            return this.computedLine(word);
          }
          if (day === 31) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>서른한 번째 날</b>입니다.\n 오늘 하루 가장 멋진 사람은 바로 자신이라고 생각하며 힘차게 시작할게요.";
            return this.computedLine(word);
          }
          if (day === 32) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>서른두 번째 날</b>입니다.\n 더 나은 하루하루를 보내고 계시는가요?";
            return this.computedLine(word);
          }
          if (day === 33) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>서른세 번째 날</b>입니다.\n 3초간 손뼉 치며 소리 내 웃어봅시다. 한결 가벼워진 기분으로 시작해볼까요.";
            return this.computedLine(word);
          }
          if (day === 34) {
            word = "안녕하세요. 삼킴 건강코치, <b>서른네 번째 날</b>입니다.\n 오늘도 무탈하신가요?";
            return this.computedLine(word);
          }
          if (day === 35) {
            word =
              "안녕하세요. 삼킴 건강코치, <b>서른다섯 번째 날</b>입니다.\n 활기차게 삼킴훈련부터 시작해봅시다.";
            return this.computedLine(word);
          }
          if (day === 36) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>서른여섯 번째 날</b>입니다.\n 우리 프로그램의 마지막 일주일이네요. 그럼 삼킴훈련부터 시작하겠습니다.";
            return this.computedLine(word);
          }
          if (day === 37) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>서른일곱 번째 날</b>입니다.\n 목표에 도달하고 있나요? 결과도 중요하지만, 그 과정은 더 중요하답니다.";
            return this.computedLine(word);
          }
          if (day === 38) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>서른여덟 번째 날</b>입니다.\n 발전하기 위해 노력하시는 모습, 정말 훌륭하십니다.";
            return this.computedLine(word);
          }
          if (day === 39) {
            word =
              "안녕하세요. 오늘은 삼킴 건강코치, <b>서른아홉 번째 날</b>입니다.\n 안전한 삼킴을 위한 오늘의 프로그램, 지금 시작할게요.";
            return this.computedLine(word);
          }
          if (day === 40) {
            word =
              "안녕하세요. 드디어 삼킴 건강코치, <b>마지막 날</b>을 맞이했어요.\n 끝은 새로운 시작이라고 하죠. 함께하는 프로그램은 끝이지만\n 삼킴훈련에 대해 새로운 마음다짐을 갖게 된다면 정말 좋겠습니다.";
            return this.computedLine(word);
          }
        }
      };
    }
  },
  components: {
    SideMenu
  }
};
</script>
<style lang="scss" scoped>
.app-context {
  .side-menu-wrap {
    display: none;
  }
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    &.infomation-page {
      background-image: url("~@assets/images/util/informationDate.png");
      background-size: cover;
      width: 100%;
      margin: 0;
    }
    .audio-player {
      position: absolute;
      opacity: 0;
      &.activated {
        opacity: 0;
        position: relative;
      }
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      cursor: pointer;
    }
    .content-wrap {
      position: absolute;
      top: calc(50% - 60px);
      transform: translate(-50%, -50%);
      width: 830px;
      left: 50%;
      h5.title {
        @include initfontKor(
          $font-size: 48px,
          $line-height: 60px,
          $font-family: "BMJUA",
          $color: #315754,
          $letter-spacing: -0.96px,
          $text-align: center
        );
        margin: 0;
      }
      .audio-dummy {
        margin: 0 0 80px 0;
        text-align: center;
        cursor: pointer;
        padding: 10px;
      }
      p.paragraph {
        @include initfontKor(
          $font-size: 40px,
          $line-height: 60px,
          $font-family: "BMJUA",
          $color: #315754,
          $letter-spacing: -0.96px,
          $text-align: center
        );
      }
    }
  }
}
</style>
<style lang="scss">
.content-wrap p.paragraph > b {
  font-weight: normal;
  @include initfontKor(
    $font-size: 40px,
    $line-height: 60px,
    $font-family: "BMJUA",
    $color: #fd8b11,
    $letter-spacing: -0.96px,
    $text-align: center
  );
}
</style>
