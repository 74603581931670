<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <block-menu
        :menuList="menuList"
        :menuType="menuType"
        @check-link-flag="checkExplain"
      ></block-menu>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="explainRouter"
      @pw-passed="pwPassed"
    />
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
import BlockMenu from "@component/BlockMenu.vue";
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      popupSet: {},
      view: {
        gettedId: null,
      },
      menuType: 1,
      menuList: [
        {
          img: "mainItem01.png",
          name: "삼킴 임상평가",
          isActive: false,
          routerUrl: "clinical",
        },
        {
          img: "mainItem02.png",
          name: "삼킴 설문",
          isActive: true,
          routerUrl: "evaluateExplain",
        },
        {
          img: "mainItem04.png",
          name: "삼킴 기기측정",
          isActive: false,
          routerUrl: "",
        },
      ],
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", ["CHECKING_EVALUATE"]),
    pwPassed() {
      this.$router.push("/clinical");
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/");
      }
    },
    explainRouter() {
      this.$router.push({
        name: "EvaluateExplain",
        query: { id: this.view.gettedId },
      });
    },
    async checkExplain(index) {
      console.log("index :", index);
      if (index === 0) {
        this.popupSet = {};
        this.SET_POPUP(true);
        this.popupSet.popType = "checkPw";
      } else if (index === 1) {
        try {
          const payload = {
            type: "survey",
          };
          await this.CHECKING_EVALUATE(payload).then((data) => {
            if (data.response.isCompleted) {
              this.view.gettedId = data.response.id;
              this.popupSet = {};
              this.SET_POPUP(true);

              this.popupSet.content = "이미 작성한 평가입니다.\n 다시 작성하시겠습니까?";
              this.popupSet.popImg = "warning.svg";
              this.popupSet.cancelBtnText = "취소";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.nextLink = null;
              this.popupSet.isApiCall = true;
            } else if (data.response.isExist) {
              this.view.gettedId = data.response.id;
              this.popupSet = {};
              this.SET_POPUP(true);
              this.popupSet.content =
                "작성 중인 평가가 있습니다.\n 이어서 작성하시겠습니까?";
              this.popupSet.popImg = "warning.svg";
              this.popupSet.cancelBtnText = "취소";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.nextLink = null;
              this.popupSet.isApiCall = true;
            } else {
              this.$router.push(this.menuList[index].routerUrl);
            }
          });
        } catch (error) {
          console.log("error :", String(errorParser.parse(error)));
        }
      } else {
        this.$router.push(this.menuList[index].routerUrl);
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  components: {
    DefaultPopup,
    SideMenu,
    BlockMenu,
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    margin: 143px 116px 0 auto;
  }
}
</style>
