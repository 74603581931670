<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
      :sideMenuDeep="true"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <swiper
        :swiperList="swiperList"
        @question-registered="askSubmit"
        @inspect-pop="inspectPop"
        @call-warn-pop="warnPop"
        @app-protocol-timer="protocolTimer"
        @app-protocol-timer2="protocolTimer2"
        @current-now="currentPage"
        :swiperLimit="true"
        :isSwallow="true"
      ></swiper>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="questionRegistered"
      @history-called="historyCall"
    />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      swiperList: {
        swallow: [
          {
            questionType: 7,
            question: 4,
            selectedVal: "",
            swallowImg: "3ml.svg",
            swallowAmount: 3,
            swallowSection: 3,
            swallowNum: 1,
          },
          {
            questionType: 7,
            question: 4,
            selectedVal: "",
            swallowImg: "3ml.svg",
            swallowAmount: 3,
            swallowSection: 3,
            swallowNum: 2,
          },
          {
            questionType: 2,
            question: 1,
            title: "증상",
            choiceVal: ["없음", "목\n가다듬기", "기침", "젖은 음성"],
            selectedVal: "",
            swallowSection: 3,
            swallowImg: "3ml.svg",
            swallowAmount: 3,
          },
          {
            questionType: 3,
            question: 2,
            title: "설골 - 후두 상승",
            subTitle: "속도",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 3,
            swallowImg: "3ml.svg",
            swallowAmount: 3,
          },
          {
            questionType: 3,
            question: 3,
            title: "설골 - 후두 상승",
            subTitle: "범위",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 3,
            swallowImg: "3ml.svg",
            swallowAmount: 3,
          },
          {
            questionType: 1,
            question: 4,
            title: "물 3ml",
            choiceVal: ["좋음", "나쁨"],
            selectedVal: "",
            swallowSection: 3,
            swallowImg: "3ml.svg",
            swallowAmount: 3,
            isLast: true,
          },
          {
            questionType: 7,
            question: 4,
            selectedVal: "",
            swallowImg: "9ml.svg",
            swallowAmount: 9,
            swallowSection: 9,
            swallowNum: 3,
          },
          {
            questionType: 7,
            question: 4,
            selectedVal: "",
            swallowImg: "9ml.svg",
            swallowAmount: 9,
            swallowSection: 9,
            swallowNum: 4,
          },
          {
            questionType: 2,
            question: 1,
            title: "증상",
            choiceVal: ["없음", "목\n가다듬기", "기침", "젖은 음성"],
            selectedVal: "",
            swallowSection: 9,
            swallowImg: "9ml.svg",
            swallowAmount: 9,
          },
          {
            questionType: 3,
            question: 2,
            title: "설골 - 후두 상승",
            subTitle: "속도",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 9,
            swallowImg: "9ml.svg",
            swallowAmount: 9,
          },
          {
            questionType: 3,
            question: 3,
            title: "설골 - 후두 상승",
            subTitle: "범위",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 9,
            swallowImg: "9ml.svg",
            swallowAmount: 9,
          },
          {
            questionType: 1,
            question: 4,
            title: "물 9ml",
            choiceVal: ["좋음", "나쁨"],
            selectedVal: "",
            swallowSection: 9,
            swallowImg: "9ml.svg",
            swallowAmount: 9,
            isLast: true,
          },
          /**  */
          {
            questionType: 2,
            question: 1,
            title: "증상",
            choiceVal: ["없음", "목\n가다듬기", "기침", "젖은 음성"],
            selectedVal: "",
            swallowSection: 90,
            swallowImg: "90ml.svg",
            swallowAmount: 90,
          },
          {
            questionType: 3,
            question: 2,
            title: "설골 - 후두 상승",
            subTitle: "속도",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 90,
            swallowImg: "90ml.svg",
            swallowAmount: 90,
          },
          {
            questionType: 3,
            question: 3,
            title: "설골 - 후두 상승",
            subTitle: "범위",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            swallowSection: 90,
            swallowImg: "90ml.svg",
            swallowAmount: 90,
          },
          {
            questionType: 1,
            question: 4,
            title: "물 90ml",
            choiceVal: ["좋음", "나쁨"],
            selectedVal: "",
            swallowSection: 90,
            swallowImg: "90ml.svg",
            swallowAmount: 90,
            isLast: true,
          },
        ],
      },
      notificationInfo: {
        title: "물 삼키기",
        btnSet: [
          {
            img: "search.png",
            name: "평가조회",
            routerUrl: "evaluateRecord",
            isActionBtn: false,
          },
        ],
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
      view: {
        first: [
          {
            flag: false,
            timerStart: false,
          },
          {
            flag: false,
            timerStart: false,
          },
        ],
        second: [
          {
            flag: false,
            timerStart: false,
          },
          {
            flag: false,
            timerStart: false,
          },
        ],
        currentPage: 1,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", ["SUBMIT_EVALUATE"]),
    historyCall() {
      this.$router.push("/clinical");
    },
    currentPage(page) {
      this.view.currentPage = page;
    },
    protocolTimer2(string, startOrder) {
      console.log("protocolTimer2 :");
      console.log("string :", string);
      if (string === "restart") {
        console.log("protocolTimer2- restart");
        const url = "samkim://waterPlay";
        document.location.href = url;
      } else if (string === "pause") {
        console.log("protocolTimer2- pause");
        const url = "samkim://waterPause";
        document.location.href = url;
      } else if (string === "reset") {
        console.log("protocolTimer2- reset");
        if (startOrder === 1) {
          this.view.first[0].flag = false;
        } else if (startOrder === 2) {
          this.view.first[1].flag = false;
        } else if (startOrder === 3) {
          this.view.second[0].flag = false;
        } else if (startOrder === 4) {
          this.view.second[1].flag = false;
        }
      }
    },
    protocolTimer(status, category, startOrder) {
      console.log("protocolTimer :");
      console.log("status :", status);
      console.log("category :", category);
      console.log("startOrder: ", startOrder);
      const isBefore = startOrder === 1 || startOrder === 3 ? true : false;
      const transCategory = startOrder === 1 || startOrder === 2 ? 3 : 9;
      if (status === "start") {
        console.log("protocolTimer- start");
        const url =
          "samkim://waterStart?isBefore=" + isBefore + "&category=" + transCategory;
        document.location.href = url;
        if (category === 3 && startOrder === 1) {
          this.view.first[0].timerStart = true;
        } else if (category === 3 && startOrder === 2) {
          this.view.first[1].timerStart = true;
        } else if (category === 9 && startOrder === 1) {
          this.view.second[0].timerStart = true;
        } else if (category === 9 && startOrder === 2) {
          this.view.second[1].timerStart = true;
        }
      } else if (status === "done") {
        console.log("protocolTimer- done");
        const url = "samkim://waterStop";
        document.location.href = url;
        if (startOrder === 1) {
          this.view.first[0].flag = true;
        } else if (startOrder === 2) {
          this.view.first[1].flag = true;
        } else if (startOrder === 3) {
          this.view.second[0].flag = true;
        } else if (startOrder === 4) {
          this.view.second[1].flag = true;
        }
      }
    },
    warnPop() {
      this.SET_POPUP(true);
      this.popupSet.content = "좋음을 선택해야 진행할 수 있습니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    inspectPop() {
      this.SET_POPUP(true);
      this.popupSet.content = "입력해주세요";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    handleGoback() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다. \n종료하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.confirmBtnText = "예";
      this.popupSet.nextLink = null;
      this.popupSet.historyCall = true;
      /**
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/clinical");
      }
       */
    },
    askSubmit(index) {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가를 제출할까요?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.param = index;
      this.popupSet.confirmBtnText = "예";
    },
    transArray(index) {
      const array = [];
      let sampleObj;
      for (let i = 0; i < this.swiperList.swallow.length; i++) {
        if (index >= i) {
          if (this.swiperList.swallow[i].question !== 4) {
            sampleObj = {
              question: String(this.swiperList.swallow[i].question),
              answer: this.returnChoiceOrder(this.swiperList.swallow[i]),
              category: this.swiperList.swallow[i].swallowSection,
              etcText: null,
            };
            array.push(sampleObj);
          }
        }
      }
      return array;
    },
    async questionRegistered(index) {
      console.log("이곳에 진입");
      try {
        const payload = {
          type: "water",
          list: this.transArray(index),
        };
        await this.SUBMIT_EVALUATE(payload).then((data) => {
          const mutatedCategory = this.view.second[1].flag ? 9 : 3;
          setTimeout(() => {
            const url =
              "samkim://waterEnd?detailId=" +
              data.response.id +
              "&category=" +
              mutatedCategory;
            document.location.href = url;
          }, 150);
          this.SET_POPUP(true);
          this.popupSet.popType = "warn";
          this.popupSet.popImg = "success.svg";
          this.popupSet.content = "평가가 완료되었습니다.";
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = "/clinical";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.isApiCall = false;
        });
      } catch (error) {
        this.SET_POPUP(true);
        this.popupSet.content = String(errorParser.parse(error));
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/clinical";
      this.popupSet.confirmBtnText = "완료";
      this.popupSet.destination = true;
    },
    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = "normal";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "종료";
    },
  },
  beforeDestroy() {
    if (
      this.view.currentPage === 1 &&
      !this.view.first[0].flag &&
      this.view.first[0].timerStart
    ) {
      const url = "samkim://waterStop";
      document.location.href = url;
    } else if (
      this.view.currentPage === 2 &&
      !this.view.first[1].flag &&
      this.view.first[1].timerStart
    ) {
      const url = "samkim://waterStop";
      document.location.href = url;
    } else if (
      this.view.currentPage === 7 &&
      !this.view.second[0].flag &&
      this.view.second[0].timerStart
    ) {
      const url = "samkim://waterStop";
      document.location.href = url;
    } else if (
      this.view.currentPage === 8 &&
      !this.view.second[1].flag &&
      this.view.second[1].timerStart
    ) {
      const url = "samkim://waterStop";
      document.location.href = url;
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    returnChoiceOrder() {
      return (text) => text.choiceVal.indexOf(text.selectedVal);
    },
    initNewLine() {
      return (text) => text.split("\n").join("");
    },
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
