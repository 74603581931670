<template>
  <div class="app-context flex deep share">
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
        v-if="view.isCardPrepare"
      ></notification>
      <div class="info-area">
        <div class="list-area" v-if="this.cardStatus.list.day !== 39">
          <div
            class="list"
            v-for="(item, index) in shareLifeList.slice(1, 5)"
            :key="index + 'A'"
            @click="goDetail(index)"
          >
            <div class="list-item">
              <div class="span-list">
                <span>{{ item.title }}</span>
              </div>
              <div class="circle-list">
                <div
                  class="item-circle"
                  v-for="(mitem, mIndex) in item"
                  :key="mIndex"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="list-area b" v-else>
          <div class="list-container" v-for="(item, index) in shareFinalList" :key="index">
            <div class="list-item">
              <div class="span-list">
                <div class="span-item a">
                  <span>{{index+1}}</span>
                </div>
                <div class="span-item b">
                 <span>{{ item.day }}일차</span>
                </div>
              </div>
              <div class="Btnwrap">
                <button @click="goDetail2(index)">확인하기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/Notification.vue';
import { mapMutations, mapActions } from 'vuex';
import errorParser from '@/utils/error-parser';

export default {
  props: {},
  data() {
    return {
      listTitle: ['', '유년시절', '청년기', '성인 / 중년기', '장년 / 노년기'],
      shareLifeList: [],
      shareFinalList: [],
      notificationInfo: {
        title: '',
        btnSet: [],
      },
      ogTag: {
        title: '소중한 나의역사, 그 모든 순간',
        description: '삶의 의미 탐색',
        image: null,
        url: null,
      },
      view: {
        isCardPrepare: false,
      },
      cardStatus: {
        cardType: 1,
        list: {},
      },
    };
  },
  head: {
    meta() {
      return [
        {
          property: 'og:title',
          content: this.ogTag.title,
        },
        { property: 'og:description', content: this.ogTag.description },
        {
          property: 'og:image',
          content: '',
        },
        {
          property: 'og:url',
          content: '',
        },
        { name: 'description', content: this.ogTag.description },
      ];
    },
  },
  created() {
    console.log('준비물 점검 ID :', this.$route.query.id);
    console.log('준비물 점검 DAY :', this.$route.query.day);

    this.prepareLifeList(this.$route.query.userId);
    this.fetchShareLife(this.$route.query.id);

    if (this.$route.query.day === '39') {
      this.splitShareLifeList(this.$route.query.userId);
    }
  },
  mounted() {
    document.querySelector('#app').style.backgroundColor = '#ebf2f1';
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('life', ['FETCH_LIFE', 'FETCH_LIFE_LIST_SHARE']),
    async prepareLifeList(userId) {
      await this.fetchLifeList(userId);
    },
    async fetchShareLife(id) {
      try {
        await this.FETCH_LIFE(id).then((data) => {
          console.log(data, 'data22222222');
          this.cardStatus.list = data.response;
          this.view.isCardPrepare = true;
          this.notificationInfo.title = `[${data.response.day}일차] ${data.response.title}`;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async splitShareLifeList(userId) {
      try {
        const finalArr = [];
        await this.FETCH_LIFE_LIST_SHARE(userId).then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            if (data.response.list[i].day !== 1 && data.response.list[i].day !== 2 && data.response.list[i].day !== 3
              && data.response.list[i].day !== 4 && data.response.list[i].day !== 5 && data.response.list[i].day !== 10
              && data.response.list[i].day !== 21 && data.response.list[i].day !== 22 && data.response.list[i].day !== 33
              && data.response.list[i].day !== 39 && data.response.list[i].day !== 40) {
              data.response.list[i].isFlag = true;
              finalArr.push(data.response.list[i]);
            }
          }
          this.shareFinalList = finalArr;
        });
      } catch (error) {
        console.log('error : ', errorParser.parse(error));
        console.log('error:', error);
      }
    },
    async fetchLifeList(userId) {
      try {
        const sampleArr = [];
        await this.FETCH_LIFE_LIST_SHARE(userId).then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            data.response.list[i].title = this.listTitle[i];
            sampleArr.push(data.response.list[i]);
          }
          this.shareLifeList = sampleArr;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    goDetail(index) {
      console.log(index, 'index');
      this.$router.push({
        name: 'ShareLife',
        query: {
          id: this.shareLifeList[index + 1].id,
          day: this.shareLifeList[index + 1].day,
        },
      });
    },
    goDetail2(index) {
      console.log(index, 'index');
      this.$router.push({
        name: 'ShareLife',
        query: {
          id: this.shareFinalList[index].id,
          day: this.shareFinalList[index].day,
          isFlag: true,
        },
      });
    },
  },
  computed: {},
  components: {
    Notification,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  #app .app-context.share.flex {
    min-height: 1024px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  #app .app-context.share.flex {
  }
}
@media screen and (max-width: 1023px) {
  #app .app-context.share.flex {
    .wrap-context {
      margin: 25px 55px;
      width: calc(100% - 110px);
      padding: 0 0 135px 0;
    }
  }
}
@media screen and (max-width: 768px) {
  #app .app-context.share.flex {
    .wrap-context {
      margin: 25px;
      width: calc(100% - 50px);
      padding: 0 0 135px 0;
    }
  }
}

@media screen and (max-width: 480px) {
  #app .app-context.share {
    .wrap-context {
      margin: 20px 30px;
      width: calc(100% - 60px);
    }
  }
}

.app-context {
  &.share {
    height: auto;
    .wrap-context {
      margin: 40px auto;
    }
  }
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;

    .info-area {
      position: relative;
      min-height: 740px;
      justify-content: center;
      align-content: center;
      background: #fff;

      /* 6일차 CSS */
      .list-area {
        display: flex;
        flex-flow: wrap;
        padding: 51px 20px;
        @media screen and (max-width: 480px) {
          padding: 20px;
        }
        .list {
          flex: 1;
          @media screen and (max-width: 768px) {
            flex: 1 1 40%;
            margin: 15px;
          }
          @media screen and (max-width: 480px) {
            flex: 1 1 40%;
            margin: 5px;
          }
          &:nth-child(1) {
            opacity: 0.7;
            @media screen and (max-width: 768px) {
              .list-item {
                margin: auto 0 auto auto;
              }
            }
            @media screen and (max-width: 480px) {
              .list-item {
                margin: auto;
              }
            }
          }
          &:nth-child(2) {
            opacity: 0.8;
            @media screen and (max-width: 768px) {
              .list-item {
                margin: auto auto auto 0;
              }
            }
            @media screen and (max-width: 480px) {
              .list-item {
                margin: auto;
              }
            }
          }
          &:nth-child(3) {
            opacity: 0.9;
            @media screen and (max-width: 768px) {
              .list-item {
                margin: auto 0 auto auto;
              }
            }
            @media screen and (max-width: 480px) {
              .list-item {
                margin: auto;
              }
            }
          }
          &:nth-child(4) {
            @media screen and (max-width: 768px) {
              .list-item {
                margin: auto auto auto 0;
              }
            }
            @media screen and (max-width: 480px) {
              .list-item {
                margin: auto;
              }
            }
          }
          &:nth-child(1) {
            .list-item {
              .circle-list {
                .item-circle {
                  &:nth-child(2) {
                    opacity: 0;
                  }
                  &:nth-child(3) {
                    opacity: 0;
                  }
                  &:nth-child(4) {
                    opacity: 0;
                  }
                  &:nth-child(5) {
                    opacity: 0.5;
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            .list-item {
              .circle-list {
                .item-circle {
                  &:nth-child(2) {
                    opacity: 0;
                  }
                  &:nth-child(3) {
                    opacity: 0;
                  }
                  &:nth-child(4) {
                    opacity: 0.5;
                  }
                  &:nth-child(5) {
                    opacity: 1;
                  }
                }
              }
            }
          }
          &:nth-child(3) {
            .list-item {
              .circle-list {
                .item-circle {
                  &:nth-child(2) {
                    opacity: 0;
                  }
                  &:nth-child(3) {
                    opacity: 0.5;
                  }
                  &:nth-child(4) {
                    opacity: 0.5;
                  }
                  &:nth-child(5) {
                    opacity: 1;
                  }
                }
              }
            }
          }

          .list-item {
            width: 190px;
            height: 190px;
            margin: auto;
            background-color: #fd8b11;
            border-radius: 6px;
            box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);

            @media screen and (max-width: 480px) {
              width: 150px;
              height: 150px;
            }
            @media screen and (max-width: 376px) {
              width: 132px;
              height: 132px;
            }
            .span-list {
              padding: 18px 0 0 16px;
              @media screen and (max-width: 376px) {
                padding: 14px 0 0 12px;
              }
              span {
                @media screen and (max-width: 480px) {
                  font-size: 22px;
                }
                @media screen and (max-width: 376px) {
                  font-size: 20px;
                }
                color: #ffffff;
                font-size: 28px;
                font-family: "AppleSDGothicNeoB00";
                text-align: center;
                line-height: 1.21;
                letter-spacing: -0.56px;
              }
            }
            .circle-list {
              position: absolute;
              margin: 80px 0 0 130px;
              width: 50px;
              height: 50px;
              display: grid;
              gap: 5px;
              grid-template-columns: repeat(2, 1fr);
              place-items: center;
              @media screen and (max-width: 480px) {
                margin: 55px 0 0 100px;
                width: 40px;
                height: 40px;
                gap: 5px;
              }
              @media screen and (max-width: 376px) {
                margin: 57px 0 0 95px;
                width: 25px;
                height: 25px;
                gap: 5px;
              }
              .item-circle {
                @media screen and (max-width: 480px) {
                  width: 13px;
                  height: 13px;
                }
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background-color: #ffffff;

                &:first-of-type {
                  display: none;
                }
                &:nth-child(2) {
                  opacity: 0.2;
                }
                &:nth-child(3) {
                  opacity: 0.5;
                }
                &:nth-child(4) {
                  opacity: 0.5;
                }
              }
            }
          }
        }
         /* 6일차 CSS 끝*/

        .list-container {
          width: 100%;
          border-bottom: 1px solid #e1e5ec;

          .list-item {
            display: flex;
            padding: 10px;

            .span-list {
              width: 50%;
              display: flex;
              @media screen and (max-width: 480px) {
                width: 65%;
              }
              .span-item {
                text-align: left;
                margin-left: 10%;

                span {
                  font-family: "AppleSDGothicNeoB00";
                  font-size: 24px;
                  font-weight: 100;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  letter-spacing: -0.48px;
                }
                @media screen and (max-width: 480px) {
                  span {
                    font-size: 15px;
                    line-height: 2.33;
                  }
                }
                &.a {
                  color: #fe8c12;
                  font-family: "AppleSDGothicNeoR00";
                  margin: auto 10%;
                  width: 10%;
                }
                &.b {
                  color: #363739;
                  margin: 0;
                  width: 30%;
                }
              }

            }
            .Btnwrap {
              width: 50%;
              text-align: right;
              margin-right: 3%;

              button {
                background-color: #315754;
                border-radius: 2px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                font-family: "AppleSDGothicNeoR00";
                font-size: 24px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: -0.48px;
                text-align: left;
                color: #ffffff;
                padding: 3px 15px;

                @media screen and (max-width: 480px) {
                  font-size: 17px;
                }
              }
            }

          }
        }
      }

    }
    .low-margin {
      margin: 0 0 20px 0;
      font-size: 26px;
      font-weight: normal;
      font-family: "AppleSDGothicNeoB00";
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
