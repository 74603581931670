<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification :notificationInfo="notificationInfo"></notification>
      <block-menu
        :menuList="menuList"
        :menuType="menuType"
        @check-link-flag="checkQuestion"
      ></block-menu>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import BlockMenu from "@component/BlockMenu.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      menuType: 3,
      notificationInfo: {
        title: "삼킴 임상평가",
        btnSet: [
          {
            img: "search.png",
            name: "평가조회",
            routerUrl: "evaluateRecord",
            isActionBtn: false,
          },
        ],
      },
      menuList: [
        {
          /**
                img: 'clinical01.png',
                 */
          img: "evalItem01.png",
          name: "삼킴구조와\n기능",
          isActive: false,
          routerUrl: "structure",
        },
        {
          /**
                img: 'clinical02.png',
                */
          img: "evalItem02.png",
          name: "물 삼키기",
          isActive: true,
          routerUrl: "swallow",
        },
        {
          /**
                img: 'clinical03.png',
                */
          img: "evalItem03.png",
          name: "고체 씹기",
          isActive: false,
          routerUrl: "chew",
        },
        {
          /**
                img: 'clinical04.png',
                */
          img: "evalItem04.png",
          name: "아~/퍼/터/커",
          isActive: true,
          routerUrl: "vocalization",
        },
      ],
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", ["CHECKING_EVALUATE"]),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/evaluate");
      }
    },
    async checkQuestion(index) {
      console.log("index :", index);
      let paramTxt;
      if (index === 0) {
        paramTxt = "structure";
      } else if (index === 1) {
        paramTxt = "water";
      } else if (index === 2) {
        paramTxt = "solid";
      } else if (index === 3) {
        paramTxt = "vocalization";
      }
      try {
        const payload = {
          type: paramTxt,
        };
        await this.CHECKING_EVALUATE(payload).then((data) => {
          if (!data.response.isExist) {
            if (index === 0) {
              this.$router.push("/structure");
            } else if (index === 1) {
              this.$router.push("/swallow");
            } else if (index === 2) {
              this.$router.push("/chew");
            } else if (index === 3) {
              this.$router.push("/vocalization");
            }
          } else {
            this.SET_POPUP(true);
            this.popupSet.content =
              "이미 평가를 완료하였습니다.\n 새로 평가를 진행하시겠습니까?";
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "취소";
            this.popupSet.confirmBtnText = "확인";
            if (index === 0) {
              this.popupSet.nextLink = "/structure";
            } else if (index === 1) {
              this.popupSet.nextLink = "/swallow";
            } else if (index === 2) {
              this.popupSet.nextLink = "/chew";
            } else if (index === 3) {
              this.popupSet.nextLink = "/vocalization";
            }
          }
        });
      } catch (error) {
        console.log("error :", String(errorParser.parse(error)));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = "테스트";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  beforeDestroy() {},
  components: {
    SideMenu,
    Notification,
    BlockMenu,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
  }
}
</style>
