<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <div class="camera-context" v-if="view.loadDone">
        <div class="item-box">
          <div
            class="animator-wrap"
            :class="{
              active: animate.value !== null,
              pause: plyrValue.isViewMilestone,
            }"
          >
            <div class="animator" v-if="plyrValue.isViewMilestone">
              <div class="btn-wrap">
                <div class="item">
                  <button type="button" @click="btnClick('prepare')">
                    연습 영상 다시보기
                  </button>
                </div>
                <div
                  class="item"
                  v-if="
                    plyrValue.currentSetCount !== plyrValue.setCount ||
                    plyrValue.currentRepeatCount !== plyrValue.repeatCount
                  "
                >
                  <button
                    type="button"
                    @click="btnClick('training')"
                    v-text="
                      view.pastCurrentRepeat !== null
                        ? '훈련 시작하기'
                        : '다음 세트 시작하기'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="animator" v-else>
              <!--
              <div class="half-circle-timer">
                <span v-text="animate.value" />
              </div>
              -->
              <div
                class="training-notification"
                :class="{
                  active: animate.value !== null,
                  prepare: animate.value === '준비',
                  start: animate.value === '시작',
                }"
              >
                <span v-text="animate.value" />
              </div>
              <div class="info-wrap">
                <div class="holder">
                  <h4
                    class="title"
                    :class="{
                      push:
                        plyrValue.currentRepeatCount !== 0 &&
                        plyrValue.currentRepeatCount !== 5,
                    }"
                    v-text="
                      plyrValue.currentRepeatCount === 0 ||
                      plyrValue.currentRepeatCount === 5
                        ? '물 한 숟가락'
                        : '침'
                    "
                  ></h4>
                  <div class="content">
                    <div class="img-wrap">
                      <img
                        src="@assets/images/training/spoon.png"
                        v-if="
                          plyrValue.currentRepeatCount === 0 ||
                          plyrValue.currentRepeatCount === 5
                        "
                      />
                      <img src="@assets/images/training/mouse.svg" v-else />
                    </div>
                    <!--
                    <
                      v-if="
                        plyrValue.currentRepeatCount === 0 || plyrValue.currentRepeatCount === 4
                      "
                    >
                      5ml
                    </>
                    -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="video-player">
            <vue-plyr :options="plyrOption" ref="plyr">
              <video controls crossorigin playsinline>
                <source
                  :src="require(`@/assets/video/${plyrValue.src}`)"
                  type="video/mp4"
                />
              </video>
            </vue-plyr>
          </div>
        </div>
        <div class="item-box activated">
          <div class="camera-attach"></div>
          <div class="training-status">
            <div
              class="item"
              v-for="(set, setIndex) in plyrValue.setCount"
              :key="setIndex + 'a'"
            >
              <h3 class="i-header" v-text="setIndex + 1 + '세트'"></h3>
              <div class="i-content">
                <span
                  class="num"
                  v-for="(repeat, repeatIndex) in plyrValue.repeatCount"
                  :key="repeatIndex + 'a'"
                  v-text="repeatIndex + 1"
                  :class="{
                    active:
                      (plyrValue.currentRepeatCount !== null &&
                        repeatIndex + 1 <= plyrValue.currentRepeatCount &&
                        plyrValue.currentSetCount >= setIndex + 1) ||
                      setIndex + 1 < plyrValue.currentSetCount,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @start-protocol="initProtocol"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      plyrOption: {
        autoplay: false,
        /**
          controls: ['play', 'fullscreen'],
           */
        controls: ['play', 'mute', 'volume'],
        //  'progress',
        fullscreen: {
          enabled: false,
          fallback: false,
          iosNative: false,
          container: 'video-player',
        },
      },
      plyrValue: {
        duration: '',
        player: '',
        /**
        src: "swallow_practice_new.mp4",
         */
        src: 'swallow_practice_mix.mp4',
        isPrepare: true,
        currentRepeatCount: null,
        repeatCount: 3,
        setCount: 2,
        currentSetCount: 1,
        isViewMilestone: false,
        setDone: false,
        allSetClear: false,
      },
      sideMenuDeep: true,
      notificationInfo: {
        title: '힘껏 오래 삼키기',
        btnSet: [
          {
            img: 'icon_information.svg',
            name: '훈련안내',
            routerUrl: 'trainingInfo',
            isActionBtn: false,
          },
          {
            img: 'icon_record.svg',
            name: '훈련기록',
            routerUrl: 'trainingRecord',
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: false,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      animate: {
        intervalObj: null,
        initValue: null,
        value: null,
        isPause: false,
        isDone: false,
      },
      apiStatus: {
        detailId: null,
      },
      view: {
        currenyType: 'practice',
        isFirst: false,
        comment: '',
        isFirstTraining: false,
        isLastTraining: false,
        loadVideo: {
          practice: false,
          water: false,
          split: false,
        },
        loadDone: false,
        pastCurrentRepeat: null,
        isFloatCount: false,
        isFirstFloatCount: false,
        isFirstFloatCountDone: false,
        isSecondFloatCount: false,
        isSecondFloatCountDone: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    console.log('swallow - type :', this.$route.query.type);
    console.log('swallow - time :', this.$route.query.time);
    console.log('swallow - day :', this.$route.query.day);
    console.log('swallow - number :', this.$route.query.number);
    console.log('swallow - set :', this.$route.query.set);
    console.log('swallow - isLastCount :', this.$route.query.isLastCount);
    this.checkTraining(
      this.$route.query.type,
      this.$route.query.time,
      this.$route.query.day,
    );
  },
  mounted() {
    console.log('마운티드발동');
    this.initAttach();
    /**
    this.SET_POPUP(true);
    this.popupSet.popType = "endTraining";
    this.popupSet.title = `삼킴 훈회`;
    this.popupSet.popImg = "warning.svg";
    this.popupSet.param = 1;
    this.popupSet.trainingName = "swallow";
    this.popupSet.content = "우네리";
    this.popupSet.isProtocol = true;
    this.popupSet.popView = "morning";
    this.popupSet.popDay = 2;
    this.popupSet.cancelBtnText = "확인";
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('training', [
      'CHECK_TRAINING',
      'START_TRAINING',
      'FETCH_TRAINING',
      'SEND_TRAINING_DATA',
    ]),
    ...mapActions('util', ['FETCH_COMMENT_TRAINING']),
    inifCountFlagment() {
      this.view.isFirstFloatCount = false;
      this.view.isFirstFloatCountDone = false;
      this.view.isSecondFloatCount = false;
      this.view.isSecondFloatCountDone = false;
    },
    loadPopAfterMediaOn() {
      console.log('start - popup 이후');
      this.$refs.plyr.player.play();
    },
    loadVideoItem() {
      this.plyrValue.player = this.$refs.plyr.player;
      /** 시간업데이트 */
      this.plyrValue.player.on('timeupdate', (data) => {
        const value = data.detail.plyr;
        if (this.view.currentType === 'practice') {
          console.log('duration - 연습', value.currentTime);
          console.log('practice > this.view.isFirstFloatCount : ', this.view.isFirstFloatCount);
          console.log('practice > this.view.isFirstFloatCountDone : ', this.view.isFirstFloatCountDone);
          console.log('practice > this.view.isSecondFloatCount : ', this.view.isSecondFloatCount);
          console.log('practice > this.view.isSecondFloatCountDone : ', this.view.isSecondFloatCountDone);
          if (
            value.currentTime > 40
            && !this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = true;
            console.log('1번째 연습타이머 시작');
            const url = 'samkim://swallowTimerStart';
            document.location.href = url;
          } else if (
            value.currentTime > 44
            && this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = false;
            this.view.isFirstFloatCountDone = true;
            console.log('1번째 연습타이머 끝');
            const url = 'samkim://swallowTimerEnd';
            document.location.href = url;
          }
          if (
            value.currentTime > 59
            && !this.view.isSecondFloatCount
            && !this.view.isSecondFloatCountDone
          ) {
            this.view.isSecondFloatCount = true;
            console.log('2번째 연습타이머 시작');
            const url = 'samkim://swallowTimerStart';
            document.location.href = url;
          } else if (
            value.currentTime > 63
            && this.view.isSecondFloatCount
            && !this.view.isSecondFloatCountDone
          ) {
            this.view.isSecondFloatCount = false;
            this.view.isSecondFloatCountDone = true;
            console.log('2번째 연습타이머 끝');
            const url = 'samkim://swallowTimerEnd';
            document.location.href = url;
          }
        } else if (this.view.currentType === 'water') {
          console.log('duration - 훈련', value.currentTime);
          console.log('water > this.view.isFirstFloatCount : ', this.view.isFirstFloatCount);
          console.log('water > this.view.isFirstFloatCountDone : ', this.view.isFirstFloatCountDone);
          if (
            value.currentTime > 11
            && !this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = true;
            console.log('훈련 타이머 시작');
            const url = 'samkim://swallowTimerStart';
            document.location.href = url;
          } else if (
            value.currentTime > 14
            && this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = false;
            this.view.isFirstFloatCountDone = true;
            console.log('훈련 타이머 끝');
            const url = 'samkim://swallowTimerEnd';
            document.location.href = url;
          }
        } else if (this.view.currentType === 'split') {
          console.log('duration - 훈련', value.currentTime);

          console.log('split > this.view.isFirstFloatCount : ', this.view.isFirstFloatCount);

          console.log('split > this.view.isFirstFloatCountDone : ', this.view.isFirstFloatCountDone);
          if (
            value.currentTime > 7.5
            && !this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = true;
            console.log('훈련 타이머 시작');
            const url = 'samkim://swallowTimerStart';
            document.location.href = url;
          } else if (
            value.currentTime > 10.5
            && this.view.isFirstFloatCount
            && !this.view.isFirstFloatCountDone
          ) {
            this.view.isFirstFloatCount = false;
            this.view.isFirstFloatCountDone = true;
            console.log('훈련 타이머 끝');
            const url = 'samkim://swallowTimerEnd';
            document.location.href = url;
          }
        }
        /**

       */
      });
      /** 비디오 초기시작 */
      this.$refs.plyr.player.on('playing', (data) => {});

      /** 비디오 재개 */
      this.$refs.plyr.player.on('play', (data) => {
        console.log('비디오 시작');
        if (this.animate.value !== null) {
          this.animate.value = null;
          clearInterval(this.animate.intervalObj);
        }
        if (this.view.isFirst && !this.plyrValue.isPrepare) {
          this.$refs.plyr.player.pause();
          this.callCountAnimation();
        }
        if ((this.view.isFirstFloatCount && this.view.isFloatCount) || (this.view.isSecondFloatCount && this.view.isFloatCount)) {
          this.view.isFloatCount = false;
          console.log('비디오 재개');
          const url = 'samkim://swallowTimerStart';
          document.location.href = url;
        }
        this.view.isFirstFloatCountDone = false;
        this.view.isSecondFloatCountDone = false;
      });
      /** 비디오 중지 */
      this.$refs.plyr.player.on('pause', (data) => {
        if (!this.view.isFirst) {
          clearInterval(this.animate.intervalObj);
        }
        if ((this.view.isFirstFloatCount && !this.view.isFloatCount) || (this.view.isSecondFloatCount && !this.view.isFloatCount)) {
          this.view.isFloatCount = true;
          console.log('비디오 중지');
          const url = 'samkim://swallowTimerStop';
          document.location.href = url;
        }
      });

      /** 비디오 완료 */
      this.$refs.plyr.player.on('ended', (data) => {
        console.log('비디오 종료');
        const { currentRepeatCount } = this.plyrValue;
        const { currentSetCount } = this.plyrValue;
        console.log('카운트 체크 :', currentRepeatCount);
        if (currentRepeatCount !== null && this.view.pastCurrentRepeat === null) {
          /** 동영상 준비  */
          if (currentRepeatCount < this.plyrValue.repeatCount) {
            this.plyrValue.currentRepeatCount = currentRepeatCount + 1;
          }
          if (this.plyrValue.currentRepeatCount < this.plyrValue.repeatCount) {
            if (this.plyrValue.currentRepeatCount === 5) {
              if (this.view.currentType !== 'water') {
                this.initSource('swallow_water_mix.mp4');
                this.view.currentType = 'water';
              }
            } else if (this.view.currentType !== 'split') {
              this.initSource('swallow_split_mix.mp4');
              this.view.currentType = 'split';
            }
            this.callCountAnimation();
          } else if (currentSetCount < this.plyrValue.setCount) {
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
            /** 다음세트 시작일 때 버튼 선택자를 부릅니다. */
          } else {
            this.plyrValue.setDone = true;
            if (this.plyrValue.allSetClear) {
              this.plyrValue.isViewMilestone = true;
            }
            console.log('이거찾아 :', this.view.isLastTraining);
            if (this.view.isLastTraining) {
              if (this.$route.query.isLastCount) {
                this.loadPop(
                  'training',
                  'end',
                  this.$route.query.day,
                  /**
                this.getTimeToOrder(this.$route.query.time)
                 */
                  'evening',
                );
              } else {
                this.loadPop(
                  'training',
                  'end',
                  this.$route.query.day,
                  this.getTimeToOrder(this.$route.query.time),
                );
              }
            } else if (!this.plyrValue.allSetClear) {
              const url = 'samkim://cameraSwallowPreviewEnd';
              document.location.href = url;
              setTimeout(() => {
                this.SET_POPUP(true);
                this.popupSet.popType = 'warn';
                this.popupSet.popImg = 'success.svg';
                this.popupSet.content = '훈련이 완료되었습니다.';
                this.popupSet.cancelBtnText = null;
                this.popupSet.isProtocol = false;
                this.popupSet.nextLink = '/trainingDate';
                this.popupSet.confirmBtnText = '확인';
                this.popupSet.destination = true;
              }, 150);
            }
          }
          if (!this.plyrValue.allSetClear) {
            console.log('진입진입스');
            this.sendTrainingData(
              this.apiStatus.detailId,
              this.plyrValue.currentRepeatCount,
              this.plyrValue.currentSetCount,
            );
          }

          console.log('number: ', this.plyrValue.currentRepeatCount);
          console.log('set: ', this.plyrValue.currentSetCount);
        } else {
          if (this.view.pastCurrentRepeat !== null) {
            const samSu = this.view.pastCurrentRepeat;
            if (samSu === 5) {
              if (this.view.currentType !== 'water') {
                this.initSource('swallow_water_mix.mp4');
                this.view.currentType = 'water';
              }
            } else if (this.view.currentType !== 'split') {
              this.initSource('swallow_split_mix.mp4');
              this.view.currentType = 'split';
            }
            /**
            this.plyrValue.currentRepeatCount = samSu;
             */
            this.view.pastCurrentRepeat = null;
          } else {
            console.log('처음 연습 이후 진입');
            this.plyrValue.currentRepeatCount = 0;
            if (this.view.currentType !== 'water') {
              this.initSource('swallow_water_mix.mp4');
              this.view.currentType = 'water';
            }
          }
          this.callCountAnimation();
        }
        if (this.plyrValue.isPrepare) {
          if (this.view.currentType !== 'water') {
            this.initSource('swallow_water_mix.mp4');
            this.view.currentType = 'water';
          }
        }
      });
      /** 비디오 전체화면 시작 */
      this.$refs.plyr.player.on('enterfullscreen', (data) => {});

      /** 비디오 전체화면 중지 */
      this.$refs.plyr.player.on('exitfullscreen', (data) => {});
      if (this.view.loadVideo.practice && !this.view.isFirstTraining) {
        this.$refs.plyr.player.play();
      }
      if (
        this.view.loadVideo.practice
        && this.view.isFirstTraining
        && this.getTimeToOrder(this.$route.query.time) === 'morning'
      ) {
        this.$refs.plyr.player.play();
      }
      console.log('인자검색 practice :', this.view.loadVideo.practice);
      console.log('인자검색 water :', this.view.loadVideo.water);
      console.log('인자검색 split :', this.view.loadVideo.split);
    },
    async loadPop(type, time, day, trainingTime) {
      console.log('swallow - 시작안내문구 로직 시작');
      console.log('type :', type);
      console.log('time :', time);
      console.log('day :', day);
      console.log('trainingTime :', trainingTime);
      try {
        const info = {
          type,
          time,
          day,
          trainingTime,
        };
        await this.FETCH_COMMENT_TRAINING(info).then((data) => {
          console.log('fetchComment : ', data);
          console.log(
            'fetchComment - parse : ',
            JSON.parse(JSON.stringify(data.response)),
          );
          console.log('fetchComment - inner : ', data.response.comment);
          if (data.response.comment !== null) {
            this.view.comment = data.response.comment;
          } else {
            this.view.comment = '멘트 점검중';
          }
          if (Number(this.$route.query.time) === 0 && time === 'start') {
            console.log('[훈련멘트] 첫타임 첫훈련 (멘트없음)');
            this.loadPopAfterMediaOn();
          } else {
            console.log('팝업 호출요망');
            const url = 'samkim://cameraSwallowPreviewEnd';
            document.location.href = url;
            setTimeout(() => {
              console.log('셋타임아웃 진입');
              this.SET_POPUP(true);
              this.popupSet.popType = 'endTraining';
              this.popupSet.title = `삼킴 훈련 ${this.getCountToOrder(
                this.$route.query.time,
              )}회`;
              this.popupSet.popImg = 'warning.svg';
              this.popupSet.param = this.getCountToOrder(this.$route.query.time);
              this.popupSet.trainingName = 'swallow';
              this.popupSet.content = this.view.comment;
              if (time === 'start') {
                this.popupSet.isProtocol = true;
              } else {
                this.popupSet.isProtocol = false;
              }
              this.popupSet.popView = time;
              this.popupSet.popDay = this.$route.query.day;
              if (time !== 'end') {
                this.popupSet.cancelBtnText = '확인';
              } else {
                if (this.$route.query.isLastCount && this.view.isLastTraining) {
                  this.popupSet.isLastTrainingCount = true;
                }
                this.popupSet.cancelBtnText = null;
                this.popupSet.nextLink = '/trainingDate';
                this.popupSet.confirmBtnText = '확인';
                this.popupSet.destination = true;
              }
            }, 150);
          }

          /** 메인페이지 진입시 삶의의미 팝업을 띄울 수 있게
          if (this.$route.query.isLastCount) {
            if (time === "end") {
              localStorage.removeItem("passedDailyComment");
            }
          }
           */
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
        this.popupSet.isProtocol = false;
      } else {
        this.$router.push('/trainingDate');
      }
    },
    checkSetNum(number1, number2) {
      let numberVal;
      if (number1 !== number2) {
        numberVal = number1 - 1;
      } else {
        numberVal = number1;
      }
      return numberVal;
    },
    async sendTrainingData(detailId, num, set) {
      console.log('detailId :', detailId);
      console.log('num :', num);
      console.log('set :', set);
      try {
        const payload = {
          detailId,
          list: [
            {
              number: num,
              set,
            },
          ],
        };
        await this.SEND_TRAINING_DATA(payload).then((data) => {
          console.log(
            '서버저장완료',
            'num :',
            num,
            'set :',
            set,
            'isCompleted :',
            data.response.isCompleted,
          );
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    setTrainingStatus(number, set) {
      console.log('training-status -number', number);
      console.log('training-status -set', set);
      this.plyrValue.repeatCount = Number(number);
      this.plyrValue.setCount = Number(set);
    },
    async fetchTraining(id) {
      try {
        await this.FETCH_TRAINING(id).then((data) => {
          /**  시간세팅 */
          this.view.loadDone = true;
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          console.log('시간세팅-data :', data);
          console.log('시간 포문', data.response.list.length);
          for (let i = data.response.list.length - 1; i >= 0; i--) {
            console.log('회차 :', i);
            if (data.response.list[i].number > 0) {
              console.log('i 진입점 :', i, ' 현재 데이터', data.response.list[i]);
              this.plyrValue.currentSetCount = data.response.list[i].set;
              this.plyrValue.currentRepeatCount = data.response.list[i].number;
              break;
            }
          }
          if (this.plyrValue.currentRepeatCount === null) {
            console.log('연습파일을 넣어줬어요');
            this.view.loadVideo.practice = true;
          } else if (this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount) {
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
          } else if (this.plyrValue.currentRepeatCount === 4) {
            console.log('현재 카운트1 :', this.plyrValue.currentRepeatCount);
            this.view.isFirst = true;
            this.plyrValue.isViewMilestone = true;
            this.view.pastCurrentRepeat = this.plyrValue.currentRepeatCount;
            console.log('물파일을 넣어줬어요');
            this.view.loadVideo.water = true;
          } else {
            console.log('현재 카운트2 :', this.plyrValue.currentRepeatCount);
            this.view.isFirst = true;
            this.plyrValue.isViewMilestone = true;
            this.view.pastCurrentRepeat = this.plyrValue.currentRepeatCount;
            console.log('침파일을 넣어줬어요');
            this.view.loadVideo.split = true;
          }
          if (
            this.plyrValue.isViewMilestone
            && this.plyrValue.currentSetCount === this.plyrValue.setCount
            && this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount
          ) {
            this.plyrValue.allSetClear = true;
          }
          if (this.view.isFirstTraining) {
            this.loadPop(
              'training',
              'start',
              this.$route.query.day,
              this.getTimeToOrder(this.$route.query.time),
            );
            /** 앱종료 감지 후 카메라 넣어주기 */
          }
          /**
          if (
            this.plyrValue.isViewMilestone &&
            this.plyrValue.currentSetCount === this.plyrValue.setCount &&
            this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount
          ) {
            this.plyrValue.allSetClear = true;
          } else {
            this.loadPop(
              this.getCommentTypeToOrder(this.$route.query.type),
              "start",
              this.$route.query.day,
              this.getCommentTypeToOrder(this.$route.query.time)
            );
          }
          */
          this.initVideo();
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async trainingStart(type, time, day) {
      const factor = {
        type: this.getTypeToOrder(type),
        time: this.getTimeToOrder(time),
        day,
      };
      try {
        await this.START_TRAINING(factor).then((data) => {
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          this.apiStatus.detailId = data.response.detailId;
          this.fetchTraining(data.response.detailId);
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async checkTraining(type, time, day) {
      /**
      console.log("type", type);
      console.log("time", time);
      console.log("day", day);
       */
      const factor = {
        time: this.getTimeToOrder(time),
        day,
      };
      console.log('factor :', factor);
      try {
        await this.CHECK_TRAINING(factor).then((data) => {
          console.log('data :', data);
          this.apiStatus.detailId = data.response.swallow.detailId;

          if (data.response.raising.isCompleted && data.response.turn.isCompleted) {
            this.view.isLastTraining = true;
          }
          if (!data.response.swallow.isExist) {
            if (!data.response.raising.isExist && !data.response.turn.isExist) {
              this.view.isFirstTraining = true;
            }
            this.trainingStart(
              this.$route.query.type,
              this.$route.query.time,
              this.$route.query.day,
            );
          } else {
            this.fetchTraining(data.response.swallow.detailId);
          }
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.isProtocol = false;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    btnClick(comment) {
      console.log('comment :', comment);
      if (comment === 'prepare') {
        if (this.view.currentType !== 'practice') {
          this.initSource('swallow_practice_mix.mp4');
          this.view.currentType = 'practice';
        }
        if (
          this.plyrValue.currentSetCount !== this.plyrValue.setCount
          || this.plyrValue.currentRepeatCount !== this.plyrValue.repeatCount
        ) {
          if (this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentRepeatCount = null;
          }
        } else {
          this.plyrValue.allSetClear = true;
        }
        const { currentSetCount } = this.plyrValue;
        if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
          this.plyrValue.currentSetCount = currentSetCount + 1;
        }
        this.plyrValue.isPrepare = true;
        this.$refs.plyr.player.play();
      } else if (comment === 'training') {
        if (this.view.pastCurrentRepeat !== null) {
          const returnValue = this.view.pastCurrentRepeat;
          this.plyrValue.currentRepeatCount = returnValue;
          this.view.pastCurrentRepeat = null;
          console.log('사용할 리턴벨류', returnValue);
          if (returnValue === 5) {
            console.log('물 넣어주기');
            if (this.view.currentType !== 'water') {
              this.initSource('swallow_water_mix.mp4');
              this.view.currentType = 'water';
            }
          } else {
            console.log('침 넣어주기');
            if (this.view.currentType !== 'split') {
              this.initSource('swallow_split_mix.mp4');
              this.view.currentType = 'split';
            }
          }
        } else {
          const { currentSetCount } = this.plyrValue;
          if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentSetCount = currentSetCount + 1;
          }
          this.plyrValue.currentRepeatCount = 0;
          if (this.view.currentType !== 'water') {
            this.initSource('swallow_water_mix.mp4');
            this.view.currentType = 'water';
          }
        }
        this.callCountAnimation();
      }
      this.inifCountFlagment();
      this.plyrValue.isViewMilestone = false;
      this.plyrValue.setDone = false;
    },
    initSource(file) {
      this.$refs.plyr.player.source = {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: require(`@/assets/video/${file}`),
            type: 'video/mp4',
            size: 480,
          },
        ],
      };
      this.plyrValue.isPrepare = false;
      this.inifCountFlagment();
    },
    initVideo() {
      console.log('비디오 준비');
      if (this.view.loadVideo.practice) {
        this.view.currentType = 'practice';
        this.plyrValue.src = 'swallow_practice_mix.mp4';
      }
      if (this.view.loadVideo.water) {
        this.view.currentType = 'water';
        this.plyrValue.src = 'swallow_water_mix.mp4';
      }
      if (this.view.loadVideo.split) {
        this.view.currentType = 'split';
        this.plyrValue.src = 'swallow_split_mix.mp4';
      }
      this.$nextTick((_) => {
        this.loadVideoItem();
        // this.view.isFirstFloatCountDone = false;
        // this.view.isSecondFloatCountDone = false;
      });
    },
    initProtocol() {
      const url = 'samkim://cameraSwallowPreviewStart';
      document.location.href = url;
      if (this.view.isFirstTraining) {
        this.$refs.plyr.player.play();
      }
    },
    initAttach() {
      const url = 'samkim://cameraSwallowPreviewStart';
      document.location.href = url;
    },
    callCountAnimation() {
      let currentValue;
      this.animate.intervalObj = setInterval(() => {
        currentValue = this.animate.value;
        if (currentValue === null) {
          this.animate.value = '준비';
        } else if (currentValue === '준비') {
          this.animate.value = '시작';
        } else if (currentValue === '시작') {
          clearInterval(this.animate.intervalObj);
          if (this.view.isFirst) {
            this.view.isFirst = false;
          }
          this.$refs.plyr.player.play();
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    getCommentTypeToOrder() {
      return (order) => {
        console.log('getCommentTypeToOrder -order :', order);
        console.log('getCommentTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'total';
        }
        if (Number(order) === 1) {
          return 'life';
        }
        if (Number(order) === 2) {
          return 'training';
        }
      };
    },
    getTypeToOrder() {
      return (order) => {
        console.log('getTypeToOrder -order :', order);
        console.log('getTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'swallow';
        }
        if (Number(order) === 1) {
          return 'raising';
        }
        if (Number(order) === 2) {
          return 'turn';
        }
      };
    },
    getTimeToOrder() {
      return (order) => {
        console.log('getTimeToOrder -order :', order);
        console.log('getTimeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'morning';
        }
        if (Number(order) === 1) {
          return 'lunch';
        }
        if (Number(order) === 2) {
          return 'evening';
        }
      };
    },
    getCountToOrder() {
      return (order) => {
        console.log('getCountToOrder -order :', order);
        console.log('getCountToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return '1';
        }
        if (Number(order) === 1) {
          return '2';
        }
        if (Number(order) === 2) {
          return '3';
        }
      };
    },
  },
  beforeDestroy() {
    const url = 'samkim://cameraSwallowPreviewEnd';
    document.location.href = url;
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .training-notification {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 125px;
      height: 53px;
      border-radius: 19px;
      border: 1px solid #fd8b11;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      opacity: 0;
      z-index: 5;
      top: 50%;
      &.active {
        opacity: 1;
      }
      &.prepare {
        background: #fff;
        span {
          color: #fd8b11;
        }
      }
      &.start {
        background: #fd8b11;
        span {
          color: #fff;
        }
      }
      span {
        display: block;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 24px,
          $font-family: "AppleSDGothicNeoB00",
          $letter-spacing: -0.52px,
          $text-align: left
        );
      }
    }
    .low-margin {
      margin: 0 0 56px 0;
    }

    .directive.low {
      margin: 0 0 51px 0;
    }

    .camera-context {
      display: flex;
      position: relative;
      .item-box {
        width: calc(50% - 5px);
        position: relative;
        min-height: 524px;
        background: #676767;
        /**
        &.holder {
          &:after {
            content: "";
            width: 30px;
            height: 1px;
            background: #315754;
            position: absolute;
            left: -40px;
            top: 0;
          }
        }
         */
        .animator-wrap {
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          transition: 0.5s;
          display: none;
          &.active {
            display: block;
          }
          &.pause {
            display: block;
            background: rgba(54, 61, 65, 0.5);
          }
          .animator {
            .btn-wrap {
              display: flex;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              flex-wrap: wrap;
              .item {
                width: 100%;
                &:first-of-type {
                  margin: 0;
                  button {
                    background: #21978c;
                    &:after {
                      background-image: url("~@assets/images/util/icon_replay.svg");
                    }
                  }
                }
                margin: 20px 0 0 0;
                button {
                  width: 290px;
                  border: 0;
                  padding: 0 40px 0 17.5px;
                  @include initfontKor(
                    $font-size: 30px,
                    $line-height: 52px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: left
                  );
                  position: relative;
                  background: #fd8b11;
                  cursor: pointer;
                  &:after {
                    content: "";
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 11px;
                    background-image: url("~@assets/images/util/icon_nextplay.svg");
                  }
                }
              }
            }
            .half-circle-timer {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgb(54, 61, 65);
              span {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            .info-wrap {
              width: 100px;
              box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.45);
              background: rgb(54, 55, 57);
              overflow: hidden;
              border-radius: 5px;
              left: 7px;
              position: absolute;
              top: 7px;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              padding: 6px 0;
              .holder {
              }
              h4.title {
                &.push {
                  padding: 0 4px 0 0;
                  font-family: "AppleSDGothicNeoB00";
                }
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 27px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #fff,
                  $letter-spacing: -0.38px,
                  $text-align: center
                );
                margin: 0 0 8px 0;
                width: 100%;
              }
              .content {
                display: flex;
                align-items: center;
                justify-content: center;
                .img-wrap {
                  img {
                    max-width: 63px;
                  }
                }
                p {
                  @include initfontKor(
                    $font-size: 19px,
                    $line-height: 24px,
                    $font-family: "AppleSDGothicNeoL00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                  margin: 0 0 0 5px;
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          margin: 0 0 0 10px;
        }
        &.activated {
          .camera-attach {
            height: 100%;
            background: #676767;
          }
          .training-status {
            padding: 15px;
            background: rgb(54, 61, 65);
            position: absolute;
            width: 100%;
            bottom: 0;
            min-height: 126px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .item {
              width: 100%;
              margin: 10px 0 0 0;
              display: flex;
              border-radius: 4px;
              overflow: hidden;
              background: #e9edec;
              &:first-of-type {
                margin: 0;
              }
              h3.i-header {
                width: 60px;
                @include initfontKor(
                  $font-size: 20px,
                  $line-height: 38px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.4px,
                  $text-align: center
                );
                background: #e9edec;
              }
              .i-content {
                width: calc(100% - 60px);
                display: flex;
                span.num {
                  display: block;
                  background: #e9edec;
                  border: 0px solid #fff;
                  border-width: 0 1px;
                  min-width: 37px;
                  @include initfontKor(
                    $font-size: 20px,
                    $line-height: 36px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #315754,
                    $letter-spacing: -0.4px,
                    $text-align: center
                  );
                  &.active {
                    background: #315754;
                    color: #fff;
                    font-family: "AppleSDGothicNeoB00";
                  }
                }
              }
            }
            &:first-of-type {
            }
          }
        }
      }
    }
  }
}
.video-player {
  height: 100%;
}
</style>
