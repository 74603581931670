<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
      :sideMenuDeep="true"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <swiper
        :swiperList="swiperList"
        @question-registered="askSubmit()"
        @inspect-pop="inspectPop"
      ></swiper>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="questionRegistered"
      @history-called="historyCall"
    />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      swiperList: {
        chew: [
          {
            questionType: 6,
            title: "보통 속도",
            selectedVal: "" /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
          },
          {
            questionType: 6,
            title: "최대 속도",
            selectedVal: "" /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
          },
          {
            questionType: 3,
            title: "턱 움직임",
            subTitle: "범위 및 규칙성",
            choiceVal: ["0", "1", "2", "3", "4"],
            selectedVal: "",
            isLast: true,
          },
        ],
      },
      notificationInfo: {
        title: "고체 씹기",
        btnSet: [
          {
            img: "search.png",
            name: "평가조회",
            routerUrl: "evaluateRecord",
            isActionBtn: false,
          },
        ],
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("evaluate", ["SUBMIT_EVALUATE"]),
    historyCall() {
      this.$router.push("/clinical");
    },
    inspectPop() {
      this.SET_POPUP(true);
      this.popupSet.content = "입력해주세요";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    handleGoback() {
      /**
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/clinical");
      }
       */
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다. \n종료하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.confirmBtnText = "예";
      this.popupSet.nextLink = null;
      this.popupSet.historyCall = true;
    },
    askSubmit() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가를 제출할까요?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = "예";
    },
    transArray() {
      const sampleArray = [];
      let sampleObj;
      sampleObj = {
        normalTime:
          this.swiperList.chew[0].value.sec + ":" + this.swiperList.chew[0].value.milisec,
        normalNumber: Number(this.swiperList.chew[0].value.chewNum),
        normalNumberPerSecond: Number(this.swiperList.chew[0].value.persecondChewNum),
        maxTime:
          this.swiperList.chew[1].value.sec + ":" + this.swiperList.chew[1].value.milisec,
        maxNumber: Number(this.swiperList.chew[1].value.chewNum),
        maxNumberPerSecond: Number(this.swiperList.chew[1].value.persecondChewNum),
        range: Number(this.swiperList.chew[2].selectedVal),
      };
      console.log("만든 재료는?", sampleObj);
      sampleArray.push(sampleObj);
      return sampleArray;
    },
    async questionRegistered() {
      console.log("이곳에 진입함");
      try {
        console.log("try 에 진입");
        const payload = {
          type: "solid",
          list: this.transArray(),
        };
        await this.SUBMIT_EVALUATE(payload).then(() => {
          console.log("정상진행");
          this.SET_POPUP(true);
          this.popupSet.popType = "warn";
          this.popupSet.popImg = "success.svg";
          this.popupSet.content = "평가가 완료되었습니다.";
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = "/clinical";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
          this.popupSet.isApiCall = false;
        });
      } catch (error) {
        this.SET_POPUP(true);
        this.popupSet.content = String(errorParser.parse(error));
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되었습니다.";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "완료";
      this.popupSet.destination = true;
    },
    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
