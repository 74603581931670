<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="true"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
        @share-pop-call="sharePopCall"
      ></notification>
      <information-posture-card
        :information="information"
        :identification="identificationOpt"
        v-if="view.isCardPrepared"
      ></information-posture-card>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup";
import InformationPostureCard from "@/components/InformationPostureCard";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      information: {},
      identificationOpt: {},
      view: {
        isCardPrepared: false,
      },
      notificationInfo: {
        title: "자세법",
        btnSet: [
          {
            img: "icon_share.svg",
            name: "공유하기",
            routerUrl: "Share",
            isActionBtn: true,
          },
          {
            img: "icon_list.svg",
            name: "활동목록",
            routerUrl: "postureList",
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: "",
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate",
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate",
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning",
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: true,
          link: "choiceType",
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType",
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage",
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
    console.log("준비물 점검 ID :", this.$route.query.id);
    console.log("준비물 점검 QuestionOrder :", this.$route.query.questionOrder);
    console.log("준비물 점검 isExist :", this.$route.query.isExist);
    if (!this.$route.query.isExist) {
      this.checkPosture(Number(this.$route.query.id));
    }
    this.prepareInfo(
      Number(this.$route.query.id),
      Number(this.$route.query.questionOrder)
    );
  },
  mounted() {
    this.callCheckOut();
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("feeding", ["EXECUTE_POSITION"]),
    sharePopCall() {
      const paramObj = {
        title: this.information.question,
        questionOrder: Number(this.$route.query.questionOrder),
      };
      const param =
        window.location.href
          .substr(0, window.location.href.indexOf("?"))
          .replace("infomationPosturePage", "share/posture") +
        "?questionOrder=" +
        Number(this.$route.query.questionOrder) +
        "&id=" +
        Number(this.$route.query.id);
      this.SET_POPUP(true);
      this.popupSet.popType = "share";
      this.popupSet.paramObj = paramObj;
      this.popupSet.param = param;
    },
    async checkPosture(id) {
      console.log("체크포스터 진입");
      try {
        const payload = {
          id,
        };
        await this.EXECUTE_POSITION(payload).then((data) => {
          console.log("execute_position data :", data);
        });
      } catch (error) {
        console.log("error: ", errorParser.parse(error));
        console.log("error :", error);
      }
    },
    callCheckOut() {
      this.SET_POPUP(true);
      this.popupSet.content =
        "인지 저하 등의 이유로\n혼자서 자세법을 실시하기 어려운 경우,\n 보호자분의 도움이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    prepareInfo(id, questionOrder) {
      console.log("prepare 준비물 :", id, questionOrder);
      let cardInfo;
      if (questionOrder === 1) {
        /** 음식을 입안에서 조작하기가 어렵다면 */
        cardInfo = {
          question: "음식을 입안에서 조작하기가 어렵다면?",
          answer: "고개를 뒤로 젖히세요",
          content:
            "입안에서 음식을 잘 조작하지 못하면 적절한 시점에 음식물을 목 뒤로 넘켜서 삼키기가 어려울 수 있습니다. 이때는 입안에 음식을 넣은 후 고개를 뒤로 젖혀서 삼키면 도움이 됩니다. ",
          referenceImgList: ["referenceImgA.png"],
          tooltipList: [
            "음식을 입안 뒤쪽에 넣습니다.",
            "고개를 뒤로 젖힙니다.",
            "음식을 삼킵니다.",
            "고개를 젖힌 채로 3초 동안 기다립니다.",
          ],
          notification: "* 사레의 위험이 있는 경우에는 이 자세를 피해주세요.",
        };
      } else if (questionOrder === 2) {
        /** 음식을 입안에서 조작하기가 어렵다면 */
        cardInfo = {
          question: "음식을 입밖으로 밀어낸다면?",
          answer: "고개를 뒤로 젖히세요",
          content:
            "혀가 긴장되어 음식을 입 밖으로 밀어내면 삼킬 수 있는 음식이 적고, 남은 음식이 뒤쪽(목구멍)으로 넘어가기 어려워 충분한 영양 섭취에 방해가 됩니다. 이때는 고개를 뒤로 젖히고 음식을 입안 뒤쪽에 넣어서 삼키면 도움이 됩니다. ",
          referenceImgList: ["referenceImgA.png"],
          tooltipList: [
            "음식을 입안 뒤쪽에 넣습니다.",
            "고개를 뒤로 젖힙니다.",
            "음식을 삼킵니다.",
            "고개를 젖힌 채로 3초 동안 기다립니다.",
          ],
          notification: "* 사레의 위험이 있는 경우에는 이 자세를 피해주세요.",
        };
      } else if (questionOrder === 3) {
        cardInfo = {
          question: "음식을 흘리면서 식사한다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "입이 잘 다물어지지 않아 음식을 흘리면서 먹으면 식사가 불편하고 영양 섭취에 방해가 됩니다. 이때는 몸을 뒤로 기대고 고개를 숙여서 삼키면 도움이 됩니다.",
          referenceImgList: ["referenceImgB.png"],
          tooltipList: [
            "뒤로 기대어 몸을 약간(30°정도) 세웁니다.",
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 4) {
        cardInfo = {
          question: "음식을 삼키기 전에 사레에 걸린다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "꿀꺽 삼키기 전에 음식이 의도치 않게 목구멍으로 넘어가면 사레에 걸릴 수 있습니다. 이때는 똑바로 앉아 고개를 앞으로 숙여서 삼키면 도움이 됩니다.",
          referenceImgList: ["referenceImgC.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 5) {
        cardInfo = {
          question: "음식을 삼키는 중에 사레에 걸린다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "삼킬 때 음식이 기도로 들어가면 사레에 걸릴 수 있습니다. 이때는 똑바로 앉아 고개를 앞으로 숙여서 삼키면 도움이 됩니다.",
          referenceImgList: ["referenceImgC.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 6) {
        cardInfo = {
          question: "음식을 삼킨 후에 사레에 걸린다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "삼킨 후에 사레에 걸린다면 목구멍에 남아있던 음식물이 기도로 들어간 것일 수 있습니다. 바른 자세로 앉아 고개를 앞으로 숙여서 삼키고, 삼킨 후 기침을 하면 도움이 됩니다.",
          referenceImgList: ["referenceImgC.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
            "기침을 합니다.",
            "침을 한번 더 삼킵니다.",
          ],
          notification: "* 한입씩 삼킬 때마다 이 동작을 반복합니다.",
        };
      } else if (questionOrder === 7) {
        cardInfo = {
          question: "음식을 삼킨 후에 목소리가 변한다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "삼킨 후에 목소리가 변한다면 음식물이 기도로 들어갔을 가능성이 있습니다. 식사 시 바로 앉아 고개를 앞으로 숙여서 삼키고, 삼킨 후 기침을 하면 도움이 됩니다.",
          referenceImgList: ["referenceImgC.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
            "기침을 합니다.",
            "침을 한번 더 삼킵니다.",
          ],
          notification: "* 한입씩 삼킬 때마다 이 동작을 반복합니다.",
        };
      } else if (questionOrder === 8) {
        cardInfo = {
          question: "음식을 삼킨 후 목구멍에 이물감이 느껴진다면?",
          answer: "고개를 앞으로 숙이세요",
          content:
            "삼킨 후 목구멍에 이물감이 느껴진다면 사레에 걸렸거나 목구멍에 음식물이 남아있다는 신호일 수 있습니다. 식사 시 바로 앉아 고개를 앞으로 숙여서 삼키고, 삼킨 후 기침을 하면 도움이 됩니다.",
          referenceImgList: ["referenceImgC.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 앞으로 숙여 턱을 당깁니다.",
            "음식을 삼킵니다.",
            "기침을 합니다.",
            "침을 한번 더 삼킵니다.",
          ],
          notification:
            "* 한입씩 삼킬 때마다 이 동작을 반복합니다. 이물감이 지속되면 내원 진료를 권장합니다.",
        };
      } else if (questionOrder === 9) {
        cardInfo = {
          question: "음식물이 목으로 역류한다면?",
          answer: "뒤로 기대어 앉으세요",
          content:
            "식도 장애가 있거나 배의 근육이 긴장하면 삼킨 음식이 목으로 올라올 수 있습니다. 식사 시 뒤로 기대어 앉아 온몸의 긴장을 풀면 중력에 의해 음식을 아래로 운반하는데 도움이 됩니다.",
          referenceImgList: ["referenceImgD.png"],
          tooltipList: [
            "뒤로 기대어 몸을 약간 (60° 정도) 세웁니다.",
            "몸의 긴장을 풉니다.",
            "음식을 입에 넣습니다.",
            "음식을 삼킵니다.",
          ],
          notification: "* 역류 증상은 소화기 내과 진료를 권장합니다.",
        };
      } else if (questionOrder === 10) {
        cardInfo = {
          question: "음식물이 목으로 역류한다면?",
          answer: "식사 후 앉은 자세를 유지하세요",
          content:
            "식도 장애가 있거나 배의 근육이 긴장하면 삼킨 음식이 목으로 올라올 수 있습니다. 식사 후 바로 눕지 말고, 2시간 이상 편안히 뒤로 기대어 앉은 자세를 유지하도록 권장합니다.",
          referenceImgList: ["referenceImgE.png"],
          tooltipList: [
            "식사를 합니다.",
            "뒤로 기대어 몸을 약간 (60° 정도) 세웁니다.",
            "몸의 긴장을 풉니다.",
            "앉은 자세를 2시간 이상 유지합니다.",
          ],
          notification: "* 역류 증상은 소화기 내과 진료를 권장합니다.",
        };
      } else if (questionOrder === 11) {
        cardInfo = {
          question: "음식물이 코로 역류한다면?",
          answer: "코를 막아주세요",
          content:
            "삼킬 떄 코를 막으면 압력이 높아져서 음식이 목으로 넘어가는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgF.png"],
          tooltipList: ["음식을 입에 넣습니다.", "코를 막습니다.", "음식을 삼킵니다."],
          notification: null,
        };
      } else if (questionOrder === 12) {
        cardInfo = {
          question: "혀 또는 턱에 마비가 있다면?",
          answer: "고개를 뒤로 젖히세요",
          content:
            "혀 또는 턱을 온전히 움직이지 못하면 음식을 씹고 삼키기가 어려울 수 있습니다. 이때는 입안에 음식을 넣은 후 고개를 뒤로 젖혀서 삼키면 도움이 됩니다.",
          referenceImgList: ["referenceImgA.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "고개를 뒤로 젖힙니다.",
            "음식을 삼킵니다.",
            "고개를 젖힌 채로 3초 동안 기다립니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 13) {
        cardInfo = {
          question: "편마비가 있다면?",
          answer: "고개를 옆으로 돌리세요",
          content:
            "한쪽 입 또는 목구멍이 마비되면 음식을 제대로 삼키기가 어렵고 사레에 걸릴 수 있습니다. 이때 고개를 마비된 쪽으로 돌리면 음식을 온전한 쪽으로 삼키는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgG.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "마비가 있는 쪽으로 고개를 돌립니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 14) {
        cardInfo = {
          question: "편마비가 있다면?",
          answer: "고개를 옆으로 기울이세요",
          content:
            "한쪽 입 또는 목구멍이 마비되면 음식물 제대로 삼키기가 어렵고 사레에 걸릴 수 있습니다. 이때 고개를 옆으로 기울이면 중력에 의해 온전한 쪽으로 음식이 내려가 안전하게 삼키는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgH.png"],
          tooltipList: [
            "음식을 입에 넣습니다.",
            "온전한 쪽으로 고개를 기울입니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 15) {
        cardInfo = {
          question: "편마비가 있다면?",
          answer: "옆으로 누우세요",
          content:
            "한쪽 입 또는 목구멍이 마비되면 음식물 제대로 삼키기가 어렵고 사레에 걸릴 수 있습니다. 이때 고개를 옆으로 기울이면 중력에 의해 온전한 쪽으로 음식이 내려가 안전하게 삼키는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgI.png"],
          tooltipList: [
            "온전한 쪽을 아래로 두고 옆으로 눕습니다./",
            "상체를 약간 올리고 자세를 고정합니다.",
            "음식을 입에 넣습니다.",
            "음식을 삼킵니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 16) {
        cardInfo = {
          question: "음식물이 목으로 역류한다면?",
          answer: "수면 시 상체를 약간 올려주세요",
          content:
            "음식이 위장에서 목으로 역류하면 사레와 폐렴의 위험이 있습니다. 수면 시 상체를 약간 올려서 자면 역류를 방지하는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgJ.png"],
          tooltipList: [
            "편하게 눕습니다.",
            "몸을 약간 (15° 정도) 올립니다. ",
            "수면을 취합니다.",
          ],
          notification: "* 역류 증상은 소화기 내과 진료를 권장합니다.",
        };
      } else if (questionOrder === 17) {
        cardInfo = {
          question: "침을 많이 흘린다면?",
          answer: "엎드려서 고개를 옆으로 돌리세요",
          content:
            "침을 잘 삼키지 못하면 침이나 가래가 기도로 들어가 사레에 걸릴 수 있습니다. 이때 엎드린 자세로 얼굴을 옆으로 돌리면 침이나 가래가 입밖으로 흘러나와 사레의 위험을 줄이는 데 도움이 됩니다.",
          referenceImgList: ["referenceImgK.png"],
          tooltipList: [
            "엎드려 눕습니다.",
            "몸을 약간 돌려 편한 자세를 취합니다.",
            "얼굴을 옆으로 돌립니다.",
          ],
          notification: null,
        };
      } else if (questionOrder === 18) {
        cardInfo = {
          question: "액체를 마실 때 고개를 젖힌다면?",
          answer: "보조도구를 사용하세요",
          content:
            "물이나 음료를 마실 때 고개를 뒤로 젖히면 사레에 걸릴 수 있습니다. 이때 보조도구(예: 코 부분이 잘린 컵, 빨대컵, 일자 빨대, 티수푼 등)를 사용하면 도움이 됩니다.",
          referenceImgList: [
            "referenceImgL01.png",
            "referenceImgL02.png",
            "referenceImgL03.png",
          ],
          tooltipList: [],
          notification: null,
        };
      } else if (questionOrder === 19) {
        cardInfo = {
          question: "음식을 입안에서 운반하기가 어렵다면?",
          answer: "보조도구를 사용하세요",
          content:
            "음식을 입안에서 뒤쪽(목구멍)으로 운반하기 어려우면 삼키는 데 방해가 되고 사레의 위험이 있습니다. 이때는 보조도구(예: 드레싱 통, 빨대컵, 일자 빨대, 티스푼 등)를 사용해서 입 뒤쪽(목구멍 근처)에 음식을 넣어주면 도움이 됩니다.",
          referenceImgList: [
            "referenceImgM01.png",
            "referenceImgM02.png",
            "referenceImgM03.png",
          ],
          tooltipList: [],
          notification: null,
        };
      } else if (questionOrder === 20) {
        cardInfo = {
          question: "입이 자주 마른다면?",
          answer: "수시로 수분을 보충해 주세요",
          content:
            "나이가 들면 침이 줄어들어서 입이 마를 수 있습니다. 구강이 건조하면 세균이 쉽게 번식하므로 물을 자주 마시고, 젖은 거즈로 입안을 자주 닦아주세요. 수분을 보충하는데 도움을 주는 구강용품(예: 구강 스프레이 또는 연고)이나 가습기를 사용하는 것도 도움이 됩니다.",
          referenceImgList: ["referenceImgN01.png", "referenceImgN02.png"],
          tooltipList: [],
          notification: null,
        };
      } else if (questionOrder === 21) {
        cardInfo = {
          question: "구강위생관리는 어떻게 하나요?",
          answer: "젖은 거즈로 입안을 자주 닦아 주시고 식사 전후에 양치를 하세요",
          content:
            "구강이 청결하지 않으면 폐렴의 위험이 높아집니다. 식사 전후에 양치를 해서 구강을 깨끗하게 하고, 젖은 거즈로 입안을 자주 닦아 주면 구강위생관리에 도움이 됩니다.",
          referenceImgList: [],
          tooltipList: [],
          notification: null,
        };
      } else if (questionOrder === 22) {
        cardInfo = {
          question: "잦은 사레는 위험한 증상인가요?",
          answer: "네, 흡인성 폐렴의 위험이 높아질 수 있습니다.",
          content:
            "사레가 반복되면 흡인성 폐렴의 위험이 높아질 수 있습니다. 특히 사레에 걸려도 기침을 하지 않는 경우는 더욱 위험합니다. 자주 사레에 걸린다면 의료기관의 전문가와 상담하시길 바랍니다.",
          referenceImgList: ["referenceImgO.png"],
          tooltipList: [],
          notification: null,
        };
      } else if (questionOrder === 23) {
        cardInfo = {
          question: "나이가 들며 음식을 삼키기 힘든가요?",
          answer: "네, 일반적으로 음식을 삼키기 어려워집니다.",
          content:
            "음식을 삼키기 어려운 것은 나이가 들면서 나타나는 자연스러운 증상입니다. 근육과 치아가 약해지고 입안의 감각이 둔해지며, 구강이 건조해져서 예전보다 음식을 삼키기 어려울 수 있습니다. 음식을 안전하게 먹을 수 있는 자세법 및 식이법이 도움이 됩니다.",
          referenceImgList: [],
          tooltipList: [],
          notification: null,
        };
      }
      /** id 값으로 [get]/activity/feeding 를 조회하고 galleryImgList에 이미지를 넣어줌 */
      /** 조회시 image 값이 있을 때 */
      this.information = cardInfo;
      const optObj = {
        order: questionOrder,
        id,
      };
      this.identificationOpt = optObj;
      this.view.isCardPrepared = true;
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/postureList");
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = "테스트";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    InformationPostureCard,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 20px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
