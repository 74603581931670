<template>
  <div class="text">
    <p v-text="animate.value"></p>
    <circular-count-down-timer :initial-value="timer.value" :steps="timer.value" :stroke-width="5"
      :seconds-stroke-color="'#f00'" :underneath-stroke-color="'lightgrey'"
      :seconds-fill-color="'#00ffff66'" :size="200" :padding="4" :show-minute="true"
      :show-second="true" :show-negatives="false" :paused="timer.isPause" :notify-every="'second'"
      @update="secondOccure" ref="countdown" v-for="a in timer.init" :key="a">
    </circular-count-down-timer>
    <!--
    <p @click="toggle()">pause</p>
    <p @click="retryTimer()">재시작스</p>
    -->
  </div>
</template>

<script>
  export default {
    props: {

    },
    data() {
      return {
        status: {
          initSet: 2,
          initRepeat: 5,
          currentSet: 2,
          currentRepeat: 4,
        },
        timer: {
          value: 5,
          isPause: false,
          isDone: false,
          init: 1,
        },
        animate: {
          intervalObj: null,
          initValue: 5,
          value: null,
          isPause: false,
          isDone: false,
        },
      };
    },
    created() {},
    mounted() {
      this.callCountAnimation();
    },
    methods: {
      init() {
        this.timer.isPause = false;
      },
      checkStatus(set, repeat) {
        if (set < 2) {
          if (repeat < 5) {
            this.status.currentRepeat = repeat + 1;
          } else {
            this.status.currentSet = set + 1;
            this.status.currentRepeat = 1;
          }
        } else if (repeat < 5) {
          this.status.currentRepeat = repeat + 1;
        }
      },
      trainingStart() {
        setTimeout(() => {
          this.checkStatus(this.status.currentSet, this.status.currentRepeat);
          this.retryTimer(this.timer.init);
        }, 1000);
      },
      secondOccure(status) {
        console.log('st', status.value);
        if (status.value === 0) {
          this.timer.isDone = true;
          this.timer.isPause = true;
          if (this.status.currentSet === 2 && this.status.currentRepeat === 5) {
            console.log('모든 요청 끝');
          } else {
            this.callCountAnimation();
          }
        }
      },
      toggle() {
        if (this.timer.isDone) {
          if (!this.animate.isPause) {
            clearInterval(this.intervalObj);
          } else {
            this.callCountAnimation();
          }
          this.animate.isPause = !this.animate.isPause;
        } else {
          this.timer.isPause = !this.timer.isPause;
        }
      },
      callCountAnimation() {
        let currentValue;
        this.intervalObj = setInterval(() => {
          currentValue = this.animate.value === null ?
            this.animate.initValue : this.animate.value;
          if (this.animate.value === null) {
            this.animate.value = this.animate.initValue;
          } else if (currentValue > 1) {
            this.animate.value = currentValue - 1;
          } else if (currentValue === 1) {
            this.animate.value = '준비';
          } else if (currentValue === '준비') {
            this.animate.value = '시작';
            this.animate.isDone = true;
            clearInterval(this.intervalObj);
            this.trainingStart();
          }
        }, 1000);
      },
      retryTimer(val) {
        if (val === 1) {
          this.timer.init = 0;
        }
        setTimeout(() => {
          console.log('타이머 객체 초기화');
          this.timer.init = 1;
          this.timer.isPause = false;
          this.animate.value = null;
          this.animate.isDone = false;
        }, 1);
      },
      stop() {
        setTimeout(() => {
          this.isPause = true;
        }, 2300);
      },
    },
    computed: {},
    components: {},
  };
</script>
<style lang="scss">
  #wrapper {
    circle {
      fill: transparent;
    }

    .circle-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
    }

    .second-txt {
      display: none;
    }
  }
</style>
