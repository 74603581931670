<template>
  <div class="swiper-module">
    <div class="progress-bar" :class="{ hide: swiperType === 1 }">
      <div class="num">
        <p class="current">{{ status.currentPage }}</p>
        <p>/{{ status.currentTotalPage }}</p>
      </div>
      <div class="bar">
        <div class="progress"></div>
      </div>
    </div>
    <div class="swiper-wrap">
      <swiper class="swiper" :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(slideItem, slideIndex) in swiperList[
            status.keyArray[status.currentSection - 1]
          ]"
          :key="slideIndex + 'A'"
        >
          <div
            class="current-swallow-section"
            v-if="slideItem.swallowSection !== undefined"
          >
            <div class="img-wrap">
              <img :src="require(`@/assets/images/swallow/${slideItem.swallowImg}`)" />
            </div>
            <h3 v-text="slideItem.swallowAmount + 'ml'"></h3>
          </div>
          <!-- 이곳에 뿌리내릴거 -->
          <div
            class="current-swallow-section huge"
            v-if="slideItem.currentTitle !== undefined"
          >
            <h3 v-text="slideItem.currentTitle"></h3>
          </div>
          <!-- 1번,2번 타입 -->
          <div
            class="s-context"
            v-if="slideItem.questionType === 1 || slideItem.questionType === 2"
            :class="{
              fillSqure:
                slideItem.questionType === 1 && slideItem.swallowSection !== undefined,
            }"
          >
            <h3 class="title" v-html="computedLine(slideItem.title)" v-if="checkGds"></h3>
            <h3 class="title" v-text="slideItem.title" v-else></h3>

            <div
              class="choice-question"
              :class="{
                twin: slideItem.questionType === 1,
                squad: slideItem.questionType === 2,
              }"
            >
              <button
                class="c-btn"
                type="button"
                v-for="(choiceItem, choiceIndex) in slideItem.choiceVal"
                :key="choiceIndex + 'B'"
                @click="
                  choiceQuestion(
                    slideIndex,
                    slideItem.questionType,
                    choiceItem,
                    choiceIndex,
                    slideItem.isLast
                  )
                "
                v-html="computedLine(choiceItem)"
                :class="{ active: slideItem.selectedVal === choiceItem }"
              ></button>
            </div>
          </div>
          <!-- 1번, 2번 타입 끝-->
          <!-- 3번 타입 -->
          <div
            class="s-context"
            v-if="slideItem.questionType === 3"
            :class="{
              hugeMargin: slideItem.subTitle === undefined,
            }"
          >
            <div class="degree">
              <h3 class="title" v-text="slideItem.title" />
              <p class="sub-title" v-text="slideItem.subTitle"></p>
              <div class="whole-center">
                <div class="scale-wrap">
                  <div
                    class="item"
                    @click="choiceScale(slideIndex, scareItem, slideItem.isLast)"
                    v-for="(scareItem, scareIndex) in slideItem.choiceVal"
                    :key="scareIndex + 'C'"
                  >
                    <div class="circle-wrap">
                      <div
                        class="circle"
                        :class="{ active: slideItem.selectedVal === scareItem }"
                      />
                    </div>
                    <h3 class="scale" v-html="computedLine(scareItem)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 3번 타입 끝-->
          <!-- 4번 타입 시작 -->
          <div class="s-context" v-if="slideItem.questionType === 4">
            <h3 class="title" v-text="slideItem.title"></h3>
            <div class="whole-center">
              <div class="input-complex">
                <div class="input-wrap" :class="{ active: view.isHighlighted }">
                  <input
                    type="text"
                    v-model="slideItem.value"
                    @click="isHighlighted(true)"
                    @keyup="checkInput(slideIndex)"
                    @blur="isHighlighted(false)"
                  />
                </div>
                <h3 class="unit" v-text="slideItem.inputPlaceHolder"></h3>
              </div>
            </div>
          </div>
          <!-- 4번 타입 끝-->
          <!-- 5번 타입 시작 -->
          <div class="s-context short" v-if="slideItem.questionType === 5">
            <div class="degree">
              <h3 class="title" v-text="slideItem.title" />
              <div class="whole-center">
                <!-- 으하하 -->
                <div class="scale-box">
                  <div
                    class="scale-wrap"
                    v-for="(scaleWrapper, scaleWrapperIndex) in slideItem.contentArray"
                    :key="scaleWrapperIndex + 'D'"
                  >
                    <p class="sub-title" v-text="scaleWrapper.subTitle"></p>
                    <div
                      class="item"
                      @click="choiceScaleTwin(slideIndex, scaleWrapperIndex, scareIndex)"
                      v-for="(scareItem, scareIndex) in scaleWrapper.choiceVal"
                      :key="scareIndex + 'C'"
                    >
                      <div class="circle-wrap">
                        <div
                          class="circle"
                          :class="{
                            active: result[slideIndex].choicedOrder === scareIndex,
                          }"
                        />
                      </div>
                      <h3 class="scale" v-text="scareItem" />
                    </div>
                  </div>
                </div>
                <!-- 하하흐흐 -->
              </div>
            </div>
          </div>
          <!-- 5번 타입 끝 -->
          <!-- 6번 타입 시작 -->
          <div class="s-context short" v-if="slideItem.questionType === 6">
            <div class="timer">
              <h3 class="title short" v-text="slideItem.title" />
              <div class="whole-center">
                <timer
                  @get-timer-info="getTimer"
                  v-if="!slideItem.isOnlyTime"
                  :isLastQuestion="slideItem.isLast ? true : false"
                ></timer>
                <timer
                  @get-timer-info="getTimerOnly"
                  v-else
                  :isOnlyTime="true"
                  :isLastQuestion="slideItem.isLast ? true : false"
                ></timer>
              </div>
            </div>
          </div>
          <div
            class="s-context short fillSqure inNoti"
            v-if="slideItem.questionType === 7"
          >
            <div class="notification-wrap" v-if="slideItem.swallowNum === 1">
              <div class="noti">
                <p>물 3ml(1 티스푼 정도)를 미리 준비함</p>
                <p>
                  “지금부터 ‘아~’ 소리를 5초간 끊이지 않게 내주세요.” 말한 후, ‘Start’
                  버튼을 누름
                </p>
              </div>
            </div>
            <div class="notification-wrap" v-if="slideItem.swallowNum === 2">
              <div class="noti">
                <p>준비한 물 3ml(1티스푼 정도)를 삼키게 함(four-finger method)</p>
                <p>
                  “지금부터 ‘아~’ 소리를 5초간 끊이지 않게 내주세요.” 말한 후, ‘Start’
                  버튼을 누름
                </p>
              </div>
            </div>
            <div class="notification-wrap" v-if="slideItem.swallowNum === 3">
              <div class="noti">
                <p>물 9ml(1 티스푼 정도)를 미리 준비함</p>
                <p>
                  “지금부터 ‘아~’ 소리를 5초간 끊이지 않게 내주세요.” 말한 후, ‘Start’
                  버튼을 누름
                </p>
              </div>
            </div>
            <div class="notification-wrap" v-if="slideItem.swallowNum === 4">
              <div class="noti">
                <p>준비한 물 9ml(1티스푼 정도)를 삼키게 함(four-finger method)</p>
                <p>
                  “지금부터 ‘아~’ 소리를 5초간 끊이지 않게 내주세요.” 말한 후, ‘Start’
                  버튼을 누름
                </p>
              </div>
            </div>
            <div class="timer">
              <div class="whole-center">
                <timer-reduce
                  @get-timer-reducer="getTimerReducer"
                  @get-timer-reducer2="getTimerReducer2"
                  :startOrder="slideItem.swallowNum"
                  :timerType="slideItem.swallowAmount"
                ></timer-reduce>
              </div>
            </div>
          </div>
          <div class="s-context short" v-if="slideItem.questionType === 8">
            <div class="timer">
              <h3 class="title short" v-text="slideItem.title" />
              <div class="whole-center">
                <timer-reduce-complex
                  :isLastQuestion="slideItem.isLast ? true : false"
                  :startOrder="slideItem.startOrder"
                  @getTimerReducerFlex="getTimerReducerFlex"
                  @get-timer-reducer="getTimerReducer"
                ></timer-reduce-complex>
              </div>
            </div>
          </div>
          <div class="s-context short" v-if="slideItem.questionType === 22">
            <div class="timer">
              <h3 class="title short" v-text="slideItem.title" />
              <div class="whole-center">
                <timer-ask
                  @get-timer-info="getTimerOnly"
                  @get-timer-vocalize="getTimerVocalize"
                  :isOnlyTime="true"
                  :isLastQuestion="slideItem.isLast ? true : false"
                  ref="myTimerAsk"
                ></timer-ask>
              </div>
            </div>
          </div>
          <!-- 6번타입 끝-->
        </swiper-slide>
      </swiper>
      <div
        class="custom-swiper-button-prev"
        :class="{
          hide: this.status.currentPage === 1 && this.status.currentSection === 1,
        }"
        @click="prev(status.listCurrentNum, status.currentPage)"
      >
        <img src="@assets/images/swiper/icon_prev_new.svg" />
      </div>
      <div
        class="custom-swiper-button-next"
        :class="{
          hide:
            this.swiperList[this.status.keyArray[this.status.currentSection - 1]]
              .length === this.status.currentPage &&
            this.status.currentSection === this.status.totalSection,
        }"
        @click="next(status.listCurrentNum, status.currentPage, swiperLimit)"
      >
        <img src="@assets/images/swiper/icon_next_new.svg" />
      </div>
    </div>
    <swiper-popup
      v-if="checkSwiperPopup"
      :popupSet="popupSet"
      @transed-pop-data="getPopData"
    />
  </div>
</template>

<script>
import SwiperPopup from '@/components/modal/SwiperPopup';
import TimerAsk from '@page/evaluation/TimerAsk';
import Timer from '@page/evaluation/Timer';
import TimerReduce from '@page/evaluation/TimerReduce';
import TimerReduceComplex from '@page/evaluation/TimerReduceComplex';
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    swiperList: {
      type: Object,
    },
    swiperStatus: {
      type: Object,
    },
    swiperType: {
      type: Number,
      default: 0,
    },
    swiperSubType: {
      type: String,
    },
    swiperLimit: {
      type: Boolean,
      default: false,
    },
    isSwallow: {
      type: Boolean,
      default: false,
    },
    isVocalRecord: {
      type: Boolean,
      default: false,
    },
    checkGds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupSet: {},
      swiperOption: {
        slidesPerView: 1,
        setWrapperSize: true,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      status: {
        listCurrentNum: null,
        currentPage: 1,
        currentTotalPage: null,
        currentSection: null,
        totalSection: null,
        listTotalNum: null,
        sectionStartNum: null,
        sectionLength: null,
      },
      view: {
        isHidePrev: false,
        isHideNext: false,
        isHighlighted: false,
        isStored: false,
      },
      result: [],
    };
  },
  created() {
    this.makeResult();
    this.setCurrentSwiper(this.status.listCurrentNum);
  },
  mounted() {
    /** list 를 for 문 돌림 */
    this.$refs.mySwiper.$swiper.slideTo(this.status.currentPage - 1);
    this.progressUp(this.status.currentPage, this.status.currentTotalPage);
  },
  methods: {
    ...mapMutations('basic', ['SET_SWIPER_POPUP']),
    pushRetry() {},
    slideCallParent() {
      this.next(this.status.listCurrentNum, this.status.currentPage, this.swiperLimit);
    },
    getTimerReducerFlex(value, startOrder, string) {
      console.log('value :', value);
      console.log('시작순서 :', startOrder);
      console.log('오리진 string :', string);
      if (value !== null) {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].value = value;
        if (value.count > 0) {
          this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            this.status.currentPage - 1
          ].flag = true;
        } else {
          this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            this.status.currentPage - 1
          ].flag = false;
        }
      }
      this.$emit('app-protocol-complex', string, startOrder);

      /**
      if (isLastQuestion) {
        this.$emit("question-registered");
      }
       */
    },
    getTimerVocalize(string, type, isStoredState) {
      console.log('getTimerVocalize :', isStoredState);
      if (string === 'stop') {
        this.view.isStored = true;
      }
      if (isStoredState !== undefined) {
        this.$emit(
          'app-protocol-vocalize',
          string,
          type,
          this.status.currentPage,
          isStoredState,
        );
      } else {
        this.$emit('app-protocol-vocalize', string, type, this.status.currentPage);
      }
    },
    getTimerReducer2(string, startOrder) {
      this.$emit('app-protocol-timer2', string, startOrder);
    },
    getTimerReducer(isDone, string, type, startOrder, stopBtnState) {
      if (isDone) {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].value = 'done';

        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = true;
        if (string === 'done' && type !== null) {
          this.$emit('app-protocol-timer', string, type, startOrder);
        }
      } else {
        console.log('else- string', string);
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].value = '';

        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = false;
        if (string === 'start' && type !== null) {
          this.$emit('app-protocol-timer', string, type, startOrder);
        }
        if (string === 'complexReset') {
          this.$emit('app-protocol-complex', 'reset', startOrder, stopBtnState);
        }
      }
    },
    getTimer(timerInfo, boolean, isQuestion) {
      console.log('timerInfo :', timerInfo);
      console.log('boolean :', boolean);
      console.log('isQuestion :', isQuestion);
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        this.status.currentPage - 1
      ].value = timerInfo;
      if (!boolean) {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = false;
      } else if (timerInfo.persecondChewNum > 0) {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = true;
      } else {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = false;
      }
      if (isQuestion) {
        this.$emit('question-registered');
      }
    },
    getTimerOnly(timerInfo, boolean, isQuestion) {
      console.log('타이머재료', timerInfo);
      console.log('논리값', boolean);
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        this.status.currentPage - 1
      ].value = timerInfo;
      if (!boolean) {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = false;
      } else {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          this.status.currentPage - 1
        ].flag = true;
        if (isQuestion !== undefined) {
          this.$emit('question-registered-proceed');
        }
      }
    },
    choiceScale(index, item, isLast) {
      console.log('choice-index', index);
      console.log('choice-item', item);
      console.log('is-last', isLast);
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].flag = true;
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].value = item;
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].selectedVal = item;
      if (isLast !== undefined) {
        this.$emit('question-registered');
      }
    },
    choiceScaleTwin(parentIndex, wrapIndex, order) {
      /** flag 반복문 필요 */
      (this.result[index].flag = true),
      (this.result[index].valueArray[wrapIndex].value = this.swiperList[
        parentIndex
      ].contentArray[wrapIndex].choiceVal[order]);
    },
    checkInput(index) {
      this.view.isHighlighted = true;
      const checkWhiteSpace = this.swiperList[
        this.status.keyArray[this.status.currentSection - 1]
      ][index].value.replace(/\s/gi, '');
      if (checkWhiteSpace === '') {
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          index
        ].flag = false;
        return false;
      }
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].flag = true;
      return true;
    },
    isHighlighted(bool) {
      this.view.isHighlighted = bool;
    },
    getPopData(value) {
      console.log('매개변수', value);
      const index = this.status.listCurrentNum - 1;
      console.log('색인', index);
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].flag = true;
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].value = value;
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].selectedVal = '기타';
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].etcVal = value;
      this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
        index
      ].choicedOrder = this.swiperList[this.status.keyArray[this.status.currentSection - 1]][index]
        .choiceVal.length - 1;
    },
    callEtcPop(index) {
      this.SET_SWIPER_POPUP(true);
      this.popupSet.storedInputData = this.swiperList[
        this.status.keyArray[this.status.currentSection - 1]
      ][index].etcVal;
      (this.popupSet.popType = 'write'), (this.popupSet.content = '내용을 입력해주세요.');
      this.popupSet.cancelBtnText = '입력취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '입력완료';
    },
    setCurrentSwiper(order) {
      console.log('스와이퍼 세팅에 사용할 것', order);
      console.log('스와이퍼 렝스 :', this.status.sectionStartNum.length);
      this.status.totalSection = this.status.sectionStartNum.length + 1;
      if (this.status.sectionStartNum.length === 1) {
        this.status.currentPage = order;
        this.status.currentTotalPage = this.status.sectionLength[0];
        this.status.currentSection = 1;
        this.status.totalSection = 1;
      } else if (order < this.status.sectionStartNum[0]) {
        this.status.currentPage = order;
        this.status.currentTotalPage = this.status.sectionStartNum[0] - 1;
        this.status.currentSection = 1;
      } else {
        const lengthVal = this.status.sectionStartNum.length;
        const arrayLoop = this.status.sectionStartNum.length - 1;
        console.log('lengthVal :', lengthVal);
        console.log('arrayLoop: ', arrayLoop);
        for (let i = 0; i < lengthVal; i++) {
          /** 현재수 19,  최대수 53
           *  섹션: 19 // 29 // 39 */
          if (order >= this.status.sectionStartNum[arrayLoop - i]) {
            /** 진입 i === 2 */
            this.status.currentPage = order - this.status.sectionStartNum[arrayLoop - i] + 1;
            if (order >= this.status.sectionStartNum[arrayLoop]) {
              this.status.currentTotalPage = this.status.listTotalNum - this.status.sectionStartNum[arrayLoop - i] + 1;
            } else {
              console.log('arrayLoop! :', arrayLoop);
              console.log('i! :', i);
              console.log('목적1:', this.status.sectionStartNum[arrayLoop - i + 1]);
              console.log('목적2:', this.status.sectionStartNum[arrayLoop - i]);
              this.status.currentTotalPage = this.status.sectionStartNum[arrayLoop - i + 1]
                - this.status.sectionStartNum[arrayLoop - i];
            }
            this.status.currentSection = Number(arrayLoop - i + 1) + 1;
            break;
          }
        }
      }
    },
    progressUp(currentPage, totalPage) {
      console.log('current', currentPage);
      console.log('total', totalPage);
      document.querySelector('.progress').style.width = `${Math.floor(
        (currentPage / totalPage) * 100,
      )}%`;
    },
    choiceQuestion(index, type, item, choiceIndex, isLast) {
      console.log('index :', index);
      console.log('type: ', type);
      console.log('item: ', item);
      if (type === 1) {
        (this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          index
        ].flag = true),
        (this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          index
        ].value = this.transVal(type, item));
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          index
        ].selectedVal = item;
      } else if (type === 2) {
        console.log('choiceIndex :', choiceIndex);
        console.log('item :', item);
        if (choiceIndex === 3 && item === '기타') {
          this.callEtcPop(index);
        } else {
          (this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            index
          ].flag = true),
          (this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            index
          ].value = item);
          this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            index
          ].selectedVal = item;
        }
      }
      if (isLast !== undefined) {
        if (this.swiperLimit) {
          console.log('swiperLimit 진입');
          if (
            this.returnChoiceOrder(
              this.swiperList[this.status.keyArray[this.status.currentSection - 1]][index],
            ) === 1
            && this.swiperList[this.status.keyArray[this.status.currentSection - 1]][index]
              .question === 4
          ) {
            console.log('이곳에 진입해야됩니다');
            this.$emit('question-registered', index);
          } else if (
            this.swiperList[this.status.keyArray[this.status.currentSection - 1]][index]
              .swallowAmount === 90
          ) {
            this.$emit('question-registered', index);
          }
        } else {
          console.log('swiperLimit 미진입');
          this.$emit('question-registered');
        }
      }
    },
    makeResult() {
      let total = 0;
      let current = 0;
      let currentSample = 0;
      const sectionLength = [];
      const sectionStartNum = [];
      const excu = Object.entries(this.swiperList);
      const keyArray = [];
      const valueArray = [];
      for (const [key, value] of excu) {
        keyArray.push(key);
        valueArray.push(value);
      }
      this.status.keyArray = keyArray;
      let firstNull = false;
      for (let i = 0; i < keyArray.length; i++) {
        sectionLength.push(this.swiperList[keyArray[i]].length);

        if (i === 0) {
          sectionStartNum.push(this.swiperList[keyArray[i]].length + 1);
        } else if (i < keyArray.length - 1) {
          let sectionValue = 0;
          for (let x = 0; x < sectionLength.length; x++) {
            sectionValue += sectionLength[x];
          }
          sectionStartNum.push(sectionValue + 1);
        }
        for (let j = 0; j < this.swiperList[keyArray[i]].length; j++) {
          /** 공통 적용 시작 */
          total += 1;
          if (this.swiperList[keyArray[i]][j].selectedVal !== undefined) {
            if (this.swiperList[keyArray[i]][j].selectedVal !== '') {
              this.swiperList[keyArray[i]][j].flag = true;
            } else {
              if (j === 0 && i === 0) {
                firstNull = true;
              }
              for (let k = 0; k < i; k++) {
                currentSample += this.swiperList[keyArray[k]].length;
              }
              if (current === 0) {
                current = currentSample + j;
              }
              this.swiperList[keyArray[i]][j].flag = false;
            }
            this.swiperList[keyArray[i]][j].value = this.swiperList[keyArray[i]][
              j
            ].selectedVal;
          }
          if (this.swiperList[keyArray[i]][j].choiceVal !== undefined) {
            /** 공통 적용 끝 */
            for (let k = 0; k < this.swiperList[keyArray[i]][j].choiceVal.length; k++) {
              if (
                this.swiperList[keyArray[i]][j].questionType === 1
                || this.swiperList[keyArray[i]][j].questionType === 3
              ) {
                /** 정의 준비중 */
              } else if (this.swiperList[keyArray[i]][j].questionType === 2) {
                /** 기타 클릭시 etc - value 처리 */
                if (this.swiperList[keyArray[i]][j].selectedVal === '기타') {
                  this.swiperList[keyArray[i]][j].value = this.swiperList[keyArray[i]][
                    j
                  ].etcVal;
                }
              }
            }
          }
        }
      }
      console.log('firstNull', firstNull);
      this.status.listTotalNum = total;
      if (firstNull) {
        this.status.listCurrentNum = 1;
      } else if (current === 0) {
        this.status.listCurrentNum = total;
      } else {
        this.status.listCurrentNum = current + 1;
      }
      this.status.sectionStartNum = sectionStartNum;
      this.status.sectionLength = sectionLength;
    },
    prev(order, current) {
      console.log('prev-order', order);
      console.log('prev-current', current);
      if (this.checkGds) {
        this.status.listCurrentNum = order - 1;
        if (current !== 1) {
          if (this.isVocalRecord) {
            this.$emit('return-currentpage', current - 1);
          }
          this.status.currentPage = current - 1;
          this.$refs.mySwiper.$swiper.slidePrev();
        } else {
          const { currentSection } = this.status;
          if (
            this.checkGds
            && this.status.currentSection === 4
            && this.status.currentPage === 1
            && this.checkDirectGds()
          ) {
            this.checkIsDirectGds('prev');
          } else {
            this.status.currentSection = currentSection - 1;
            this.status.currentPage = this.status.sectionLength[
              this.status.currentSection - 1
            ];
            this.status.currentTotalPage = this.status.sectionLength[
              this.status.currentSection - 1
            ];
            setTimeout(() => {
              this.$refs.mySwiper.$swiper.slideTo(this.status.currentPage - 1);
            }, 1);
          }
        }
        this.progressUp(this.status.currentPage, this.status.currentTotalPage);
      } else if (
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
          current - 1
        ].flag
      ) {
        this.status.listCurrentNum = order - 1;
        if (current !== 1) {
          if (this.isVocalRecord) {
            this.$emit('return-currentpage', current - 1);
          }
          this.status.currentPage = current - 1;
          this.$refs.mySwiper.$swiper.slidePrev();
        } else {
          const { currentSection } = this.status;
          if (
            this.checkGds
              && this.status.currentSection === 4
              && this.status.currentPage === 1
              && this.checkDirectGds()
          ) {
            this.checkIsDirectGds('prev');
          } else {
            this.status.currentSection = currentSection - 1;
            this.status.currentPage = this.status.sectionLength[
              this.status.currentSection - 1
            ];
            this.status.currentTotalPage = this.status.sectionLength[
              this.status.currentSection - 1
            ];
            setTimeout(() => {
              this.$refs.mySwiper.$swiper.slideTo(this.status.currentPage - 1);
            }, 1);
          }
        }
        this.progressUp(this.status.currentPage, this.status.currentTotalPage);
      } else {
        this.$emit('inspect-pop');
      }

      this.$emit('current-now', current - 1);
    },
    checkIsDirectGds(string) {
      if (string === 'next') {
        this.status.currentSection = this.status.currentSection + 3;
        this.status.currentPage = 1;
        this.status.currentTotalPage = this.status.sectionLength[
          this.status.currentSection - 1
        ];
      } else if (string === 'prev') {
        this.status.currentSection = 1;
        this.status.currentPage = this.status.sectionLength[
          this.status.currentSection - 1
        ];
        this.status.currentTotalPage = this.status.sectionLength[
          this.status.currentSection - 1
        ];
      }
      setTimeout(() => {
        this.$refs.mySwiper.$swiper.slideTo(this.status.currentPage - 1);
      }, 1);
    },
    checkDirectGds() {
      const targetArr = this.swiperList.functional;
      let isPassedGds = true;
      for (let i = 0; i < targetArr.length; i++) {
        if (
          targetArr[i].value !== '전혀\n그렇지 않다'
          && targetArr[i].selectedVal !== '전혀\n그렇지 않다'
        ) {
          isPassedGds = false;
        }
      }
      return isPassedGds;
    },
    next(order, current, isLimit) {
      console.log('order: ', order);
      console.log('current: ', current);
      console.log('total: ', this.status.currentTotalPage);
      console.log('먼저테스트', isLimit);
      if (
        isLimit
        && this.swiperList[this.status.keyArray[this.status.currentSection - 1]][current - 1]
          .questionType === 1
        && this.returnChoiceOrder(
          this.swiperList[this.status.keyArray[this.status.currentSection - 1]][
            current - 1
          ],
        ) === 1
      ) {
        this.$emit('call-warn-pop');
      } else if (
        this.swiperList[this.status.keyArray[this.status.currentSection - 1]][current - 1]
          .flag
      ) {
        this.status.listCurrentNum = order + 1;
        if (current !== this.status.currentTotalPage) {
          if (
            this.isVocalRecord
            && this.status.currentPage === 1
            && !this.view.isStored
          ) {
            this.view.isStored = true;
            setTimeout(() => {
              this.$emit('next-lock-vocalize');
            }, 100);
          } else {
            if (this.isVocalRecord) {
              this.$emit('return-currentpage', current + 1);
            }
            this.status.currentPage = current + 1;
            this.$refs.mySwiper.$swiper.slideNext();
          }
        } else {
          const { currentSection } = this.status;
          if (
            this.checkGds
            && this.status.currentSection === 1
            && this.status.currentPage === 18
            && this.checkDirectGds()
          ) {
            this.checkIsDirectGds('next');
          } else {
            this.status.currentSection = currentSection + 1;
            this.status.currentPage = 1;
            this.status.currentTotalPage = this.status.sectionLength[
              this.status.currentSection - 1
            ];
            setTimeout(() => {
              this.$refs.mySwiper.$swiper.slideTo(this.status.currentPage - 1);
            }, 1);
          }
        }
        this.progressUp(this.status.currentPage, this.status.currentTotalPage);
      } else {
        this.$emit('inspect-pop');
      }
      this.$emit('current-now', current + 1);
    },
  },
  computed: {
    ...mapState('basic', ['checkSwiperPopup']),
    returnChoiceOrder() {
      if (this.swiperLimit) {
        return (text) => text.choiceVal.indexOf(text.selectedVal);
      }
    },
    computedLine() {
      return (text) => {
        if (text.indexOf('\n') !== -1) {
          return text.split('\n').join('<br />');
        }
        return text;
      };
    },
    transVal() {
      return (type, value) => {
        if (type === 1) {
          if (value === '예') {
            return '0';
          }
          if (value === '아니오') {
            return '1';
          }
        }
      };
    },
  },
  components: {
    SwiperPopup,
    Timer,
    TimerAsk,
    TimerReduce,
    TimerReduceComplex,
  },
};
</script>
<style lang="scss" scoped>
.swiper-module {
  margin: 0;

  .progress-bar {
    margin: 24px 0 10px auto;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    &.hide {
      display: none;
    }

    .num {
      display: flex;
      margin: 0 17px 0 0;
      width: auto;
      justify-content: center;

      p {
        @include initfontKor(
          $font-size: 18px,
          $line-height: 21px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #acb3c9,
          $letter-spacing: 1.4px,
          $text-align: left
        );

        &.current {
          color: #363739;
        }
      }
    }

    .bar {
      width: calc(100% - 61px);
      border: 1px solid #e9e9e9;
      border-radius: 2px;
      height: 16px;
      overflow: hidden;
      background: #fff;
      .progress {
        height: inherit;
        background: #fd8b11;
      }
    }
  }

  .swiper-wrap {
    width: 100%;
    height: 524px;
    background: #fff;
    .swiper {
      cursor: default;
    }
    .current-swallow-section {
      display: flex;
      border: solid 0px #eaebf1;
      border-width: 0 1px 1px 0;
      border-radius: 5px;
      width: 135px;
      height: 59px;
      align-items: center;
      justify-content: center;
      position: absolute;
      &.huge {
        width: 160px;
      }
      .img-wrap {
        margin: 0 7px 0 0;

        img {
        }
      }

      h3 {
        @include initfontKor(
          $font-size: 30px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #8b8d94,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }

    .s-context {
      margin: 67px 0 0 0;
      &.inNoti.fillSqure {
        margin: 70px 0 0 0;
        .timer .whole-center .stop-watch {
          position: relative;
          width: 755px;
        }
      }
      &.short {
        margin: 52px 0 0 0;
      }
      &.hugeMargin {
        margin: 105px 0 0 0;
      }
      &.fillSqure {
        .choice-question {
          button.active {
            box-shadow: 0 0 0px 10px #fd8b12;
            // background: #fd8b12;
          }
        }
      }
      .notification-wrap {
        padding: 0;
        max-width: 755px;
        margin: 0 auto 25px auto;
        .noti {
          padding: 15px 20px;
          border-radius: 5px;
          border: solid 2px #ebf2f1;
          p {
            position: relative;
            padding: 0 0 0 37.5px;
            margin: 5px 0 0 0;
            @include initfontKor(
              $font-size: 24px,
              $line-height: 34px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: left
            );
            &:nth-of-type(1) {
              margin: 0;
              &:before {
                content: "1";
              }
            }
            &:nth-of-type(2) {
              &:before {
                content: "2";
              }
            }
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 0;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background: #ebf2f1;
              @include initfontKor(
                $font-size: 17px,
                $line-height: 26px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #89a19d,
                $letter-spacing: -0.44px,
                $text-align: center
              );
            }
          }
        }
      }
      h2.header {
      }

      h3.title {
        @include initfontKor(
          $font-size: 36px,
          $line-height: 42px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fd8b11,
          $letter-spacing: -0.72px,
          $text-align: left
        );
        text-align: center;
        margin: 0 0 38px 0;
        &.short {
          margin: 0 0 10px 0;
        }
      }

      .whole-center {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 190px;

        .input-complex {
          display: flex;

          .input-wrap {
            border-radius: 4px;
            background: #ebf2f1;

            &.active {
              border-radius: 4px;
              box-shadow: 0 3px 6px 0 rgba(54, 55, 57, 0.15);
              border: solid 2px #fd8b11;
            }

            input {
              background: transparent;
              padding: 0 12px;
              border: 0;
              width: 200px;
              @include initfontKor(
                $font-size: 22px,
                $line-height: 54px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #363739,
                $letter-spacing: -0.44px,
                $text-align: center
              );
            }
          }

          h3.unit {
            @include initfontKor(
              $font-size: 36px,
              $line-height: 54px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #363739,
              $letter-spacing: -0.72px,
              $text-align: center
            );
            margin: 0 0 0 15px;
          }
        }
      }

      .degree {
        h3.title {
          margin: 0 0 5px 0;
        }

        p.sub-title {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.52px,
            $text-align: center
          );
          margin: 0 0 30px 0;
        }
        .scale-box {
          .scale-wrap {
            flex-wrap: wrap;
            padding: 60px 0 0 0;
            margin: 30px 0 0 0;
            position: relative;
            .sub-title {
              width: 100%;
              position: absolute;
              top: 0;
            }
            &:first-of-type {
              margin: 0;
              &:before {
                content: "";
                width: calc(100% - 76px);
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                background: #eaebf1;
                position: absolute;
              }
              padding: 55px 0 15px 0;
            }
          }
        }
        .scale-wrap {
          display: flex;
        }
      }

      .choice-question {
        display: flex;
        justify-content: center;

        button {
          border: 0;
          background: transparent;
          height: 220px;
          border-radius: 5px;
          box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
          background-color: rgb(49, 87, 84);
          margin: 0;
          cursor: pointer;
          @include initfontKor(
            $font-size: 36px,
            $line-height: 42px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #fff,
            $letter-spacing: -0.72px,
            $text-align: center
          );

          &:first-of-type {
            margin: 0 !important;
          }

          &.active {
            box-shadow: 0 0 0px 10px rgb(253, 139, 18);
          }
        }

        &.squad {
          button {
            margin: 0 0 0 30px;
            width: 149px;
          }
        }

        &.twin {
          button {
            width: 220px;
            margin: 0 0 0 80px;
            position: relative;
            overflow: hidden;

            &:nth-of-type(1) {
              &:after {
                background-image: url("~@assets/images/evaluation/box_yes.png");
              }
            }

            &:nth-of-type(2) {
              &:after {
                background-image: url("~@assets/images/evaluation/box_no.png");
              }
            }

            &:after {
              content: "";
              position: absolute;
              width: 100px;
              height: 100px;
              right: 0;
              bottom: 0;
              z-index: 1;
            }
          }
        }
      }

      .scale-wrap {
        position: relative;
        display: flex;
        width: 720px;

        &:after {
          content: "";
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: calc(100% - 76px);
          height: 42px;
          background: linear-gradient(to right, #c1e2df 35%, #ffb76b);
          border-radius: 21px;
        }

        .item {
          margin: 0 0 0 30px;
          display: flex;
          width: 120px;
          flex-wrap: wrap;
          justify-content: center;

          &:first-of-type {
            margin: 0;
          }

          .circle-wrap {
            width: 30px;
            height: 42px;
            position: relative;
            margin: 0 0 30px 0;

            .circle {
              position: absolute;
              left: 0;
              top: 50%;
              width: inherit;
              height: 30px;
              background: #fff;
              z-index: 1;
              border-radius: 50%;
              transform: translateY(-50%);

              &.active {
                box-shadow: 0 3px 10px 0 rgba(54, 55, 57, 0.3);
                background-color: #fd8b11;
              }
            }
          }

          h3.scale {
            width: 100%;
            text-align: center;
            @include initfontKor(
              $font-size: 26px,
              $line-height: 36px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #363739,
              $letter-spacing: -0.52px,
              $text-align: center
            );
          }
        }
      }
    }
  }
}
</style>
