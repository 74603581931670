import { util } from "@/api";

const state = {
  util: {
    entry: []
  }
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
  SET_ENTRY_DATA(state, info) {
    state.util.entry = info;
  }
};

const getters = {
  GET_ENTRY_DATA: state => state.util.entry
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_APP_VERSION({}) {
    return util.fetchAppVersion();
  },
  FETCH_COMMENT({}, payload) {
    return util.fetchComment(payload);
  },
  FETCH_COMMENT_TRAINING({}, payload) {
    return util.fetchCommentTraining(payload);
  },
  FETCH_ENTRY({}) {
    return util.fetchEntry();
  },
  FETCH_ENTRY_FCM({}, payload) {
    return util.fetchEntryFcm(payload);
  },
  FETCH_ALERM_LIST({}) {
    return util.fetchAlermList();
  },
  FETCH_ALERM_LIST_PAGE({}, payload) {
    return util.fetchAlermListPage(payload);
  },
  READ_PUSH({}) {
    return util.readPush();
  },
  CHANGE_PUSH({}, payload) {
    return util.changePush(payload);
  },
  FETCH_NOTICE_LIST({}) {
    return util.fetchNoticeList();
  },
  FETCH_NOTICE_LIST_PAGE({}, payload) {
    return util.fetchNoticeListPage(payload);
  },
  FETCH_NOTICE_DETAIL({}, payload) {
    return util.fetchNoticeDetail(payload);
  },
  FETCH_USER_INFO({}) {
    return util.fetchUserInfo();
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
