<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">탐색목록</h5>
      <result-list
        :resultList="resultList"
        @share-pop-call="sharePopCall"
        @lifelist-clicked="listClicked"
        @isProceed-meaning="meaningDone"
      ></result-list>
      <pagination
        @btn-click="paginationClick"
        :paginationInfo="paginationInfo"
        v-if="view.isListLoaded"
      ></pagination>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @share-clicked="changeShareFlag" />
    <information-popup
      v-if="checkInformationPopup"
      :informationPopupSet="informationPopupSet"
      @share-pop-call="sharePopCall"
      @close-pop="closedPop"
      @start-pop="startPop"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import ResultList from '@/components/ResultList.vue';
import Pagination from '@/components/Pagination.vue';
import InformationPopup from '@/components/modal/InformationPopup';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      listStatus: {
        currentPage: null,
      },
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: null,
        totalPage: null,
        currentPage: null,
        prevApiUrl: null,
        nextApiUrl: null,
      },
      resultList: {
        status: '',
        list: [],
        header: ['일차', '제목', '진행율', '탐색하기', '결과공유', '최종 학습일'],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: true,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      apiStatus: {
        realDay: null,
        day: null,
        lifeDay: null,
        trainingDay: null,
        isTotalCompleted: null,
        isLifeCompleted: null,
        isTrainingCompleted: null,
        currentOrder: null,
      },
      informationPopupSet: {},
      view: {
        isListLoaded: false,
        fetchList: [],
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    window.lifeOut = this.lifeOut;
    this.prepareLifeList(false);
    this.prepareShareList();
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_INFORMATION_POPUP']),
    ...mapActions('life', ['FETCH_LIFE_LIST', 'FETCH_LIFE_LIST_PAGE', 'FETCH_LIFE_LIST_FULL']),
    ...mapActions('util', ['FETCH_ENTRY']),
    async prepareShareList() {
      const sampleArr = [];
      await this.FETCH_LIFE_LIST_FULL().then((data) => {
        for (let i = 0; i < data.response.list.length; i++) {
          sampleArr.push(data.response.list[i]);
        }
        this.view.fetchList = sampleArr;
      });
    },
    changeShareFlag() {
      console.log('공유하기를 클릭함');
      localStorage.setItem('isShareLock', true);
    },
    meaningDone() {
      this.SET_POPUP(true);
      this.popupSet.content = '이미 완료하였습니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    async lifeOut(day, isDone) {
      console.log('day :', day);
      console.log('isDone :', isDone);
      // 해당 day로 삶의의미 종료팝업 호출한뒤
      // emit 설계함 , 공유하기 대응, 나가기 대응 , 팝인팝
      if (isDone) {
        this.informationPopupSet.commentType = 'life';
        this.informationPopupSet.commentTime = 'end';
        this.informationPopupSet.commentDay = day;
        this.informationPopupSet.isStartApiCall = false;
        this.informationPopupSet.commentTrainingTime = null;
        this.informationPopupSet.isApiCall = true;
        this.informationPopupSet.popType = 1;
        this.SET_INFORMATION_POPUP(true);
      } else {
        this.prepareLifeList(false);
      }
    },
    closedPop(day) {
      console.log('클로스팝 호출됨');
      this.prepareLifeList(false);
    },
    startPop(day) {
      console.log('스타트 누름');
      console.log('삶의의미 진입호출');
      console.log('day:', day);
      const url = `samkim://lifeActivity?day=${day}`;
      document.location.href = url;
    },
    async prepareLifeList(isSpread) {
      await this.checkEntry(isSpread);
      await this.fetchResult();
    },
    async checkEntry(isSpread, isProgress, day) {
      console.log('isSpread: ', isSpread);
      try {
        await this.FETCH_ENTRY().then((data) => {
          console.log('정보 :', data);
          this.apiStatus.realDay = data.response.realDay;
          this.apiStatus.day = data.response.day;
          this.apiStatus.lifeDay = data.response.lifeDay;
          this.apiStatus.trainingDay = data.response.trainingDay;
          this.apiStatus.isTotalCompleted = data.response.totalCompleted;
          this.apiStatus.isTrainingCompleted = data.response.trainingCompleted;
          this.apiStatus.isLifeCompleted = data.response.lifeCompleted;
          if (isProgress !== undefined || day !== undefined) {
            if (isSpread && !isProgress) {
              this.informationPopupSet.commentType = 'life';
              this.informationPopupSet.commentTime = 'start';
              this.informationPopupSet.isStartApiCall = true;
              this.informationPopupSet.commentDay = data.response.lifeDay;
              this.informationPopupSet.commentTrainingTime = null;
              this.informationPopupSet.popType = 1;
              this.SET_INFORMATION_POPUP(true);
            } else if (isSpread && isProgress) {
              console.log('이미 진행률이 설정된 진입 day :', day);
              const url = `samkim://lifeActivity?day=${day}`;
              document.location.href = url;
            }
          }
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    listClicked(item) {
      console.log('item :', item);
      console.log('day :', item.day);
      console.log('apiStatus.realDay :', this.apiStatus.realDay);
      console.log('apiStatus.day :', this.apiStatus.day);
      console.log('apiStatus.lifeDay :', this.apiStatus.lifeDay);
      console.log('apiStatus.trainingDay :', this.apiStatus.trainingDay);
      console.log('apiStatus.isTotalCompleted :', this.apiStatus.isTotalCompleted);
      console.log('apiStatus.isTrainingCompleted :', this.apiStatus.isTrainingCompleted);
      console.log('apiStatus.isLifeCompleted :', this.apiStatus.isLifeCompleted);
      this.checkEntry(true, this.isProceedingLife(item.progress), item.day);
      /**
      if (item.day < this.apiStatus.lifeDay) {
        console.log("과거 금지 진입");
        this.SET_POPUP(true);
        this.popupSet.content = "이미 완료하였습니다.";
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (item.day > this.apiStatus.lifeDay) {
        console.log("미래 금지 진입");
        this.SET_POPUP(true);
        if (this.apiStatus.isLifeCompleted) {
          this.popupSet.content = "예정된 탐색훈련을 모두 마쳤습니다.";
        } else {
          this.popupSet.content = `${this.apiStatus.lifeDay}일차 탐색을 먼저 진행해주세요`;
        }
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (this.apiStatus.isLifeCompleted) {
        this.SET_POPUP(true);
        this.popupSet.content = "이미 완료하였습니다.";
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else {
        this.checkEntry(true, this.isProceedingLife(item.progress), item.day);
      }
       */

      /**
      if (item.day < this.apiStatus.lifeDay) {
        console.log("과거 금지 진입");
        // 과거금지
        this.SET_POPUP(true);
        this.popupSet.content = "이미 완료하였습니다.";
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (item.day > this.apiStatus.lifeDay) {
        console.log("미래 금지 진입");
        // 미래금지
        this.SET_POPUP(true);
        if (this.apiStatus.isLifeCompleted) {
          this.popupSet.content = `${
            this.apiStatus.lifeDay + 1
          }일차 탐색을 먼저 진행해주세요`;
        } else {
          this.popupSet.content = `${this.apiStatus.lifeDay}일차 탐색을 먼저 진행해주세요`;
        }
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (this.apiStatus.realDay === this.apiStatus.day) {
        console.log("실제일과 현재 일치");
        if (item.day === this.apiStatus.lifeDay) {
          if (!this.apiStatus.isTrainingCompleted) {
            this.SET_POPUP(true);
            this.popupSet.content = `${this.apiStatus.trainingDay}일차 훈련을 먼저 진행해주세요`;
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          } else if (this.apiStatus.isLifeCompleted) {
            this.SET_POPUP(true);
            this.popupSet.content = "이미 완료하였습니다.";
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          } else {
            // 연결
            this.checkEntry(true);
          }
        }
      } else if (this.apiStatus.trainingDay === this.apiStatus.lifeDay) {
        console.log("훈련일과 동일할 경우 진입");
        this.SET_POPUP(true);
        this.popupSet.content = `${this.apiStatus.trainingDay}일차 훈련을 먼저 진행해주세요`;
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else {
        this.checkEntry(true);
      }
       */
      /**
      this.$router.push({
        name: "ShareLife",
        query: {
          id: id,
        },
      });
       */
    },
    async fetchResult(page) {
      console.log('page!!!:', page);
      // **  **/
      if (page !== undefined) {
        try {
          await this.FETCH_LIFE_LIST_PAGE(page).then((data) => {
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = Number(page);
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.resultList.list = data.response.list;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error :', error);
        }
      } else {
        try {
          await this.FETCH_LIFE_LIST().then((data) => {
            console.log('data :', data.response.list);
            console.log('data.title', data.response.list.title);
            // if (data.reponse.list[0].title.indexOf('소중한 나의 역사, 그 모든 순간') !== -1) {
            //   data.response.list[0].title.replace('소중한 나의 역사, 그 모든 순간', '소중한 나의 역사,\n 그 모든 순간');
            // }
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = 1;
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.resultList.list = data.response.list;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error :', error);
        }
      }
    },
    handleGoback() {
      console.log('리듀서 -  목록');
      console.log('플래그 상태 - 목록:', this.checkInformationPopup);
      if (this.checkInformationPopup) {
        this.SET_INFORMATION_POPUP(false);
      } else {
        this.$router.push('/choiceMeaning');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    /**
    sharePopCall(item) {
      let param;
      if (item.day === 6) {
        console.log("팝에서 온 아이템 :");
        param = `${window.location.href.replace("searchList", "share/lifelist")}?id=${
          item.id
        }`;
        console.log("param : ", param);
      } else {
        console.log("팝에서 온 아이템 :");
        param = `${window.location.href.replace("searchList", "share/life")}?id=${
          item.id
        }&day=${item.day}&title=${encodeURI(item.title)}`;
        console.log("param :", param);
      }
      this.SET_POPUP(true);
      this.popupSet.popType = "share";
      this.popupSet.paramObj = item;
      this.popupSet.param = param;
    },
*/

    sharePopCall(item) {
      let param;
      if (item.day === 6) {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('searchList', 'share/lifelist')}?id=${
          item.id
        }&userId=${localStorage.getItem('id')}`;
        console.log('param : ', param);
      } else if (item.day === 10) {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('searchList', 'share/life')}?id=${item.id}&day=${
          item.day
        }&title=${encodeURI(item.title)}&userId=${localStorage.getItem('id')}`;
      } else if (item.day === 39) {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('searchList', 'share/lifelist')}?id=${
          item.id
        }&userId=${localStorage.getItem('id')}&day=${item.day}`;
        console.log('param : ', param);
      } else {
        console.log('팝에서 온 아이템 :');
        param = `${window.location.href.replace('searchList', 'share/life')}?id=${item.id}&day=${
          item.day
        }&title=${encodeURI(item.title)}`;
      }
      console.log('param :', param);
      this.SET_POPUP(true);
      this.popupSet.popType = 'share';
      this.popupSet.paramObj = item;
      this.popupSet.param = param;
    },
    paginationClick(pageNum) {
      console.log('페이지네이션 클릭수신 :', pageNum);
      this.listStatus.currentPage = pageNum;
      this.fetchResult(pageNum);
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkInformationPopup']),
    isProceedingLife() {
      return (text) => {
        console.log('isProceedingLife before :', text);
        return text > 0;
      };
    },
    transTxt() {
      return (text) => {
        if (text === 0) {
          return '시작하기';
        }
        if (text < 100) {
          return '탐색중';
        }
        return '완료';
      };
    },
  },
  beforeDestroy() {
    if (this.checkInformationPopup) {
      this.SET_INFORMATION_POPUP(false);
    }
    if (this.checkPopup) {
      this.SET_POPUP(false);
    }
  },
  components: {
    InformationPopup,
    SideMenu,
    DefaultPopup,
    ResultList,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    h5.page-title {
      margin: 0 0 37px 0;
    }
  }
}
</style>
