<template>
  <div class="app-context flex deep share">
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
        v-if="view.isCardPrepare"
      ></notification>
      <card-life
        :cardType="cardStatus.cardType"
        :cardInfo="cardStatus.list"
        v-if="view.isCardPrepare"
      ></card-life>
        <!-- :isFinalFlag="this.$route.query.isFlag" -->
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import CardLife from '@/components/CardLife.vue';
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import Notification from '@/components/Notification.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      cardStatus: {
        cardType: 1,
        list: {},
      },
      ogTag: {
        title: null,
        description: '삶의 의미 탐색',
        image: null,
        url: null,
      },
      notificationInfo: {
        title: '',
        btnSet: [],
      },
      view: {
        isCardPrepare: false,
        idList: [],
      },
      popupSet: {},
      shareLifeImgArr: [],
    };
  },
  head: {
    meta() {
      return [
        {
          property: 'og:title',
          content: this.transMeta(decodeURI(this.$route.query.title)),
        },
        { property: 'og:description', content: this.ogTag.description },
        {
          property: 'og:image',
          content: '',
        },
        {
          property: 'og:url',
          content: '',
        },
        { name: 'description', content: this.ogTag.description },
      ];
    },
  },
  created() {
    console.log('준비물 점검 ID :', this.$route.query.id);
    console.log('준비물 점검 day :', this.$route.query.day);
    console.log('준비물 점검 title :', decodeURI(this.$route.query.title));
    if (this.$route.query.day === '10') {
      console.log('10일 클릭했음.');
      this.complex();
    } else {
      this.fetchShareLife(this.$route.query.id);
    }
    this.fetchShareLife(this.$route.query.id);
  },
  mounted() {
    document.querySelector('#app').style.backgroundColor = '#ebf2f1';
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('life', ['FETCH_LIFE', 'FETCH_LIFE_LIST_SHARE']),
    async complex() {
      console.log('complex 들어왔음');
      await this.fetchShareSample(this.$route.query.userId);
      await this.fetchShareLifeComplex(this.view.idList);
    },
    async fetchShareSample(userId) {
      console.log('fetchShareSample 실행함');
      try {
        const idList = [];
        await this.FETCH_LIFE_LIST_SHARE(userId).then((data) => {
          const targetArr = data.response.list.slice(6, 9);
          for (let i = 0; i < targetArr.length; i++) {
            idList.push(targetArr[i].id);
          }
          this.view.idList = idList;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async fetchShareLifeComplex(idList) {
      console.log('fetchShareComplex 실행함');
      const imgWrap = [];
      for (let i = 0; i < idList.length; i++) {
        try {
          await this.FETCH_LIFE(idList[i]).then((data) => {
            console.log('idList 들어온다', idList[i]);
            imgWrap.push(data.response.images);
            console.log(data.response.images, 'list@@@@@@@@@@');
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error :', error);
        }
        console.log('imgWrap :', imgWrap);
        this.cardStatus.list.images = imgWrap;
      }
    },
    async fetchShareLife(id) {
      try {
        await this.FETCH_LIFE(id).then((data) => {
          const dayCount = data.response.day;
          if (dayCount >= 7 && dayCount <= 9) {
            this.cardStatus.cardType = 2;
          } else if (dayCount === 10) {
            this.cardStatus.cardType = 3;
          } else if (dayCount >= 11 && dayCount <= 20 || dayCount === 36) {
            this.cardStatus.cardType = 4;
          } else if (dayCount === 21 || dayCount === 25 || dayCount === 29 || dayCount === 35 || dayCount === 28) {
            this.cardStatus.cardType = 5;
          } else if (dayCount === 22 || dayCount === 26) {
            this.cardStatus.cardType = 6;
          } else if (dayCount === 23) {
            this.cardStatus.cardType = 7;
          } else if (dayCount === 24 || dayCount === 27 || dayCount === 30) {
            this.cardStatus.cardType = 8;
          } else if (dayCount === 31 || dayCount === 33) {
            this.cardStatus.cardType = 9;
          } else if (dayCount === 32 || dayCount === 34 || dayCount === 38) {
            this.cardStatus.cardType = 10;
          } else if (dayCount === 37) {
            this.cardStatus.cardType = 11;
          }
          this.cardStatus.list = data.response;
          this.view.isCardPrepare = true;
          this.notificationInfo.title = `[${data.response.day}일차] ${data.response.title}`;
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    transMeta() {
      return (title) => {
        console.log('title :', title);
        return title;
      };
    },
  },
  components: {
    Notification,
    DefaultPopup,
    CardLife,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  #app .app-context.share.flex {
    min-height: 1024px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px) {
  #app .app-context.share.flex {
  }
}
@media screen and (max-width: 1023px) {
  #app .app-context.share.flex {
    .wrap-context {
      margin: 25px 55px;
      width: calc(100% - 110px);
      padding: 0 0 135px 0;
    }
  }
}
@media screen and (max-width: 768px) {
  #app .app-context.share.flex {
    .wrap-context {
      margin: 25px;
      width: calc(100% - 50px);
      padding: 0 0 135px 0;
    }
  }
}

@media screen and (max-width: 480px) {
  #app .app-context.share {
    .wrap-context {
      margin: 20px 30px;
      width: calc(100% - 60px);
    }
  }
}

.app-context {
  &.share {
    height: auto;
    .wrap-context {
      margin: 40px auto;
    }
  }
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 20px 0;
      font-size: 26px;
      font-weight: normal;
      font-family: "AppleSDGothicNeoB00";
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
