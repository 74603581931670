<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <div class="camera-context">
        <div class="item-box">
          <div
            class="animator-wrap"
            :class="{ active: animate.value !== null, pause: plyrValue.isViewMilestone }"
          >
            <div class="animator" v-if="plyrValue.isViewMilestone">
              <div class="btn-wrap">
                <div class="item">
                  <button type="button" @click="btnClick('prepare')">
                    연습 영상 다시보기
                  </button>
                </div>
                <div
                  class="item"
                  v-if="
                    plyrValue.currentSetCount !== plyrValue.setCount ||
                    plyrValue.currentRepeatCount !== plyrValue.repeatCount
                  "
                >
                  <button
                    type="button"
                    @click="btnClick('training')"
                    v-text="
                      view.pastCurrentRepeat !== null
                        ? '훈련 시작하기'
                        : '다음 세트 시작하기'
                    "
                  />
                </div>
              </div>
            </div>
            <div class="animator" v-else>
              <div
                class="training-notification"
                :class="{
                  active: animate.value !== null,
                  prepare: animate.value === '준비',
                  start: animate.value === '시작',
                }"
              >
                <span v-text="animate.value" />
              </div>
              <!--
              <div class="half-circle-timer">
                <span v-text="animate.value" />
              </div>
              <div class="info-wrap">
                <div class="holder">
                  <h4 class="title" v-text="plyrValue.currentRepeatCount >= 2 ? '침' : '물'"></h4>
                  <div class="content">
                    <div class="img-wrap">
                      <img
                        src="@assets/images/training/cup.png"
                        v-if="plyrValue.currentRepeatCount >= 2"
                      />
                      <img src="@assets/images/training/mouse.png" v-else />
                    </div>
                    <p v-if="plyrValue.currentRepeatCount >= 2">5ml</p>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
          <div class="video-player" v-if="view.videoPlayerOn">
            <vue-plyr :options="plyrOption" ref="plyr">
              <!-- <video controls crossorigin playsinline > -->
              <video controls crossorigin playsinline>
                <source
                  :src="require(`@/assets/video/${plyrValue.src}`)"
                  type="video/mp4"
                  size="720"
                />
              </video>
            </vue-plyr>
          </div>
        </div>
        <div class="item-box activated">
          <div class="info">
            <div class="bg-img">
              <img src="@assets/images/util/information.svg" />
            </div>
            <div class="item-wrap">
              <div class="item">
                <h3 class="title">훈련절차</h3>
                <div class="orderList">
                  <p>거울에 어깨선을 맞추고 <br />입을 원 중앙에 놓아주세요.</p>
                  <p>혀를 왼쪽 볼에 힘껏 붙여주세요.</p>
                  <p>선을 따라 혀를 힘껏 움직이세요.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="camera-attach">
            <!--
            <div class="select-wrap">
              <v-select
                :options="speedOpt.option"
                :searchable="false"
                @input="speedChange()"
                v-model="speedOpt.value"
              ></v-select>
            </div>
            -->
          </div>
          <div class="training-status" v-if="view.loadDone">
            <div
              class="item"
              v-for="(set, setIndex) in plyrValue.setCount"
              :key="setIndex + 'a'"
            >
              <h3 class="i-header" v-text="setIndex + 1 + '세트'"></h3>
              <div class="i-content">
                <span
                  class="num"
                  v-for="(repeat, repeatIndex) in plyrValue.repeatCount"
                  :key="repeatIndex + 'a'"
                  v-text="repeatIndex + 1"
                  :class="{
                    active:
                      (plyrValue.currentRepeatCount !== null &&
                        repeatIndex + 1 <= plyrValue.currentRepeatCount &&
                        plyrValue.currentSetCount >= setIndex + 1) ||
                      setIndex + 1 < plyrValue.currentSetCount,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @start-protocol="initProtocol"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      speedOpt: {
        option: ['느리게', '보통', '빠르게'],
        value: '' || '보통',
      },
      plyrOption: {
        autoplay: false,
        controls: ['play', 'mute', 'volume'],
        // "progress",
        fullscreen: {
          enabled: false,
          fallback: false,
          iosNative: false,
          container: 'video-player',
        },
      },
      plyrValue: {
        duration: '',
        player: '',
        src: 'tongue_practice_light.mp4',
        isPrepare: true,
        currentRepeatCount: null,
        repeatCount: 6,
        setCount: 2,
        currentSetCount: 1,
        isViewMilestone: false,
        setDone: false,
        allSetClear: false,
      },
      sideMenuDeep: true,
      notificationInfo: {
        title: '힘껏 혀 돌리기',
        btnSet: [
          {
            img: 'icon_information.svg',
            name: '훈련안내',
            routerUrl: 'trainingInfo',
            isActionBtn: false,
          },
          {
            img: 'icon_record.svg',
            name: '훈련기록',
            routerUrl: 'trainingRecord',
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: false,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      animate: {
        intervalObj: null,
        initValue: null,
        value: null,
        isPause: false,
        isDone: false,
      },
      apiStatus: {
        detailId: null,
      },
      view: {
        isFirst: false,
        comment: '',
        isFirstTraining: false,
        isLastTraining: false,
        loadVideo: {
          practice: false,
          training: false,
        },
        videoPlayerOn: false,
        loadDone: false,
        isTonguePaused: false,
        infoStartLock: false,
        infoEndLock: false,
        pastCurrentRepeat: null,
        isPageStart: false,
        currentType: 'practice',
        doubleSpeed: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    console.log('type - tongue :', this.$route.query.type);
    console.log('time - tongue :', this.$route.query.time);
    console.log('day - tongue :', this.$route.query.day);
    console.log('number - tongue :', this.$route.query.number);
    console.log('set - tongue :', this.$route.query.set);
    console.log('isLastCount -tongue :', this.$route.query.isLastCount);
    this.checkTraining(
      this.$route.query.type,
      this.$route.query.time,
      this.$route.query.day,
    );
  },
  mounted() {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('training', [
      'CHECK_TRAINING',
      'START_TRAINING',
      'FETCH_TRAINING',
      'SEND_TRAINING_DATA',
    ]),
    ...mapActions('util', ['FETCH_COMMENT_TRAINING']),
    initProtocol() {
      if (this.view.isFirstTraining) {
        console.log('init [프로토콜] gif 배속설정 on');
        this.view.doubleSpeed = true;
        const url = 'samkim://gifChangeable?flag=true';
        document.location.href = url;
        this.$refs.plyr.player.play();
      }
    },
    async loadPop(type, time, day, trainingTime) {
      console.log('시작안내문구 로직 시작');
      try {
        const info = {
          type,
          time,
          day,
          trainingTime,
        };
        await this.FETCH_COMMENT_TRAINING(info).then((data) => {
          console.log('fetchComment : ', data);
          console.log('fetchComment - inner : ', data.response.comment);
          if (data.response.comment !== null) {
            this.view.comment = data.response.comment;
          } else {
            this.view.comment = '멘트 점검중';
          }
          if (Number(this.$route.query.time) === 0 && time === 'start') {
            console.log('팝업이 없음');
            this.initProtocol();
          } else {
            const url = 'samkim://cameraTonguePreviewEnd';
            document.location.href = url;
            setTimeout(() => {
              this.SET_POPUP(true);
              this.popupSet.popType = 'endTraining';
              this.popupSet.title = `삼킴 훈련 ${this.getCountToOrder(
                this.$route.query.time,
              )}회`;
              this.popupSet.popImg = 'warning.svg';
              this.popupSet.param = this.getCountToOrder(this.$route.query.time);
              this.popupSet.trainingName = 'tongue';
              this.popupSet.content = this.view.comment;
              if (time === 'start') {
                this.popupSet.isProtocol = true;
              } else {
                this.popupSet.isProtocol = false;
              }
              this.popupSet.popDay = this.$route.query.day;
              this.popupSet.popView = time;
              if (time !== 'end') {
                this.popupSet.cancelBtnText = '확인';
              } else {
                if (this.$route.query.isLastCount && this.view.isLastTraining) {
                  console.log('라스트 횟팝');
                  this.popupSet.isLastTrainingCount = true;
                }
                this.popupSet.cancelBtnText = null;
                this.popupSet.nextLink = '/trainingDate';
                this.popupSet.confirmBtnText = '확인';
                this.popupSet.destination = true;
              }
            }, 150);
          }
          /**
          if (this.$route.query.isLastCount === "true") {
            this.popupSet.isLastTrainingCount = true;
            this.popupSet.content =
              "혀돌리기 마지막 시간입니다.\n수고하셨습니다.\n다른 훈련도 함께 진행 해 주세요.";
          } else if (this.getCountToOrder(this.$route.query.time) === "1") {
            console.log("첫 훈련종료");
            this.popupSet.content =
              "혀돌리기 훈련 첫 번째 시간을 마쳤습니다. 다른 훈련도 진행 해 주세요.";
          } else if (this.getCountToOrder(this.$route.query.time) === "2") {
            console.log("두번째 훈련종료");
            this.popupSet.content =
              "혀돌리기 훈련 두 번째 시간을 마쳤습니다. 다른 훈련도 진행 해 주세요.";
          }
           */
          /** 메인페이지 진입시 삶의의미 팝업을 띄울 수 있게
          if (this.$route.query.isLastCount) {
            if (time === "end") {
              localStorage.removeItem("passedDailyComment");
            }
          }
           */
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', JSON.stringify(error));
      }
    },
    async sendTrainingData(detailId, num, set) {
      console.log('detailId :', detailId);
      console.log('num :', num);
      console.log('set :', set);
      try {
        const payload = {
          detailId,
          list: [
            {
              number: num,
              set,
            },
          ],
        };
        await this.SEND_TRAINING_DATA(payload).then((data) => {
          console.log(
            '서버저장완료',
            'num :',
            num,
            'set :',
            set,
            'isCompleted :',
            data.response.isCompleted,
          );
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    setTrainingStatus(number, set) {
      console.log('training-status -number', number);
      console.log('training-status -set', set);
      this.plyrValue.repeatCount = Number(number);
      this.plyrValue.setCount = Number(set);
    },
    async fetchTraining(id) {
      try {
        await this.FETCH_TRAINING(id).then((data) => {
          /**  시간세팅 */
          this.view.loadDone = true;
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          console.log('시간세팅-data :', data);
          console.log('시간 포문', data.response.list.length);
          for (let i = data.response.list.length - 1; i >= 0; i--) {
            console.log('회차 :', i);
            if (data.response.list[i].number > 0) {
              console.log('i 진입점 :', i, ' 현재 데이터', data.response.list[i]);
              this.plyrValue.currentSetCount = data.response.list[i].set;
              this.plyrValue.currentRepeatCount = data.response.list[i].number;
              break;
            }
          }
          if (this.plyrValue.currentRepeatCount === null) {
            console.log('연습파일을 넣어줬어요');
            this.view.loadVideo.practice = true;
          } else if (this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount) {
            console.log('연습, 훈련 선택자 진입');
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
            /**
            this.view.currentType = null;
             */
          } else {
            this.view.isFirst = true;
            this.plyrValue.isViewMilestone = true;
            console.log('혀돌리기 파일을 넣어줬어요');
            this.view.loadVideo.training = true;
            this.view.pastCurrentRepeat = this.plyrValue.currentRepeatCount;
          }

          if (
            this.plyrValue.isViewMilestone
            && this.plyrValue.currentSetCount === this.plyrValue.setCount
            && this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount
          ) {
            this.plyrValue.allSetClear = true;
          }
          if (this.view.isFirstTraining) {
            this.loadPop(
              this.getCommentTypeToOrder(this.$route.query.type),
              'start',
              this.$route.query.day,
              this.getTimeToOrder(this.$route.query.time),
            );
          }
          this.initVideo();
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
        this.popupSet.isProtocol = false;
      } else {
        this.$router.push('/trainingDate');
      }
    },
    speedChange() {},
    async trainingStart(type, time, day) {
      const factor = {
        type: this.getTypeToOrder(type),
        time: this.getTimeToOrder(time),
        day,
      };
      try {
        await this.START_TRAINING(factor).then((data) => {
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          this.apiStatus.detailId = data.response.detailId;
          this.fetchTraining(data.response.detailId);
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async checkTraining(type, time, day) {
      /**
      console.log("type", type);
      console.log("time", time);
      console.log("day", day);
       */
      const factor = {
        time: this.getTimeToOrder(time),
        day,
      };
      console.log('factor :', factor);
      try {
        await this.CHECK_TRAINING(factor).then((data) => {
          console.log('data :', data);
          this.apiStatus.detailId = data.response.turn.detailId;

          if (data.response.raising.isCompleted && data.response.swallow.isCompleted) {
            this.view.isLastTraining = true;
          }
          if (!data.response.turn.isExist) {
            this.trainingStart(
              this.$route.query.type,
              this.$route.query.time,
              this.$route.query.day,
            );
            if (!data.response.raising.isExist && !data.response.swallow.isExist) {
              this.view.isFirstTraining = true;
            }
          } else {
            this.fetchTraining(data.response.turn.detailId);
          }
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.isProtocol = false;
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    btnClick(comment) {
      console.log('comment :', comment);
      if (comment === 'prepare') {
        if (this.view.currentType !== 'practice') {
          this.initSource('tongue_practice_light.mp4');
          this.view.currentType = 'practice';
        }
        if (
          this.plyrValue.currentSetCount !== this.plyrValue.setCount
          || this.plyrValue.currentRepeatCount !== this.plyrValue.repeatCount
        ) {
          if (this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentRepeatCount = null;
          }
          this.view.infoStartLock = false;
          this.view.infoEndLock = false;
        } else {
          this.plyrValue.allSetClear = true;
        }
        const { currentSetCount } = this.plyrValue;
        if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
          this.plyrValue.currentSetCount = currentSetCount + 1;
        }
        this.plyrValue.isPrepare = true;
        this.view.doubleSpeed = true;
        console.log('[프로토콜] gif 배속설정 on in mileStone');
        const url = 'samkim://gifChangeable?flag=true';
        document.location.href = url;
        this.$refs.plyr.player.play();
      } else if (comment === 'training') {
        if (this.view.pastCurrentRepeat !== null) {
          const returnValue = this.view.pastCurrentRepeat;
          this.plyrValue.currentRepeatCount = returnValue;
          this.view.pastCurrentRepeat = null;
        } else {
          this.plyrValue.currentRepeatCount = 0;
          const { currentSetCount } = this.plyrValue;
          if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentSetCount = currentSetCount + 1;
          }
        }
        if (this.view.currentType !== 'training') {
          this.initSource('tongue_training_light.mp4');
          this.view.currentType = 'training';
        }
        this.callCountAnimation();
      }

      this.plyrValue.isViewMilestone = false;
      this.plyrValue.setDone = false;
    },
    initSource(file) {
      console.log('initSource :', file);
      this.$refs.plyr.player.source = {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: require(`@/assets/video/${file}`),
            type: 'video/mp4',
            size: 720,
          },
        ],
      };
      this.plyrValue.isPrepare = false;
    },
    loadVideoItem() {
      /** 시간업데이트 */
      this.plyrValue.player = this.$refs.plyr.player;
      this.$refs.plyr.player.on('timeupdate', (data) => {
        const value = data.detail.plyr;
        if (this.plyrValue.currentRepeatCount === null) {
          let url;
          if (value.currentTime > 38 && !this.view.infoEndLock) {
            this.view.infoEndLock = true;
            console.log('안내영상 gif 끄기');
            url = 'samkim://gifEnd?isInfo=true';
            document.location.href = url;
          } else if (value.currentTime > 20 && !this.view.infoStartLock) {
            this.view.infoStartLock = true;
            console.log('안내영상 gif 켜기');
            url = 'samkim://gifStart?isInfo=true';
            document.location.href = url;
          }
        }
      });
      /** 비디오 초기시작 */
      this.$refs.plyr.player.on('playing', (data) => {});

      /** 비디오 재개 */
      this.$refs.plyr.player.on('play', (data) => {
        console.log('비디오 시작', this.plyrValue.currentRepeatCount);
        if (!this.view.isFirst) {
          if (this.view.isTonguePaused) {
            this.view.isTonguePaused = false;
            const url = 'samkim://gifPlay';
            document.location.href = url;
          } else {
            console.log('스타트전 띠롱 :', this.plyrValue.currentRepeatCount);
            if (this.plyrValue.currentRepeatCount !== null) {
              const url = `samkim://gifStart?day=${this.$route.query.day}&currentSet=${this.plyrValue.currentSetCount}&currentNum=${this.plyrValue.currentRepeatCount}`;
              document.location.href = url;
            }
            if (this.animate.value !== null) {
              this.animate.value = null;
              clearInterval(this.animate.intervalObj);
            }
          }
        } else {
          /**
        this.$refs.plyr.player.pause();
        this.callCountAnimation();
         */
          if (!this.plyrValue.isPrepare) {
            this.$refs.plyr.player.pause();
            this.callCountAnimation();
          }
        }
      });
      /** 비디오 중지 */
      this.$refs.plyr.player.on('pause', (data) => {
        console.log('중지요청');
        if (!this.view.isFirst) {
          if (data.detail.plyr.currentTime <= 16) {
            this.view.isTonguePaused = true;
            const url = 'samkim://gifPause';
            document.location.href = url;
          }
          clearInterval(this.animate.intervalObj);
        }
      });

      /** 비디오 완료 */
      this.$refs.plyr.player.on('ended', (data) => {
        console.log('비디오 종료');
        const { currentRepeatCount } = this.plyrValue;
        const { currentSetCount } = this.plyrValue;
        console.log('카운트 체크 :', currentRepeatCount);
        if (currentRepeatCount !== null && this.view.pastCurrentRepeat === null) {
          /** 동영상 준비  */
          if (currentRepeatCount < this.plyrValue.repeatCount) {
            this.plyrValue.currentRepeatCount = currentRepeatCount + 1;
          }
          if (this.plyrValue.currentRepeatCount < this.plyrValue.repeatCount) {
            if (this.view.currentType !== 'training') {
              this.initSource('tongue_training_light.mp4');
              this.view.currentType = 'training';
            }
            this.callCountAnimation();
          } else if (currentSetCount < this.plyrValue.setCount) {
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
            this.view.isFirstTraining = false;
            /** 다음세트 시작일 때 버튼 선택자를 부릅니다. */
          } else {
            this.plyrValue.setDone = true;
            if (this.plyrValue.allSetClear) {
              this.plyrValue.isViewMilestone = true;
            }
            if (this.view.isLastTraining) {
              if (this.$route.query.isLastCount) {
                this.loadPop(
                  'training',
                  'end',
                  this.$route.query.day,
                  /**
                this.getTimeToOrder(this.$route.query.time)
                 */
                  'evening',
                );
              } else {
                this.loadPop(
                  'training',
                  'end',
                  this.$route.query.day,
                  this.getTimeToOrder(this.$route.query.time),
                );
              }
            } else if (!this.plyrValue.allSetClear) {
              const url = 'samkim://cameraTonguePreviewEnd';
              document.location.href = url;
              setTimeout(() => {
                this.SET_POPUP(true);
                this.popupSet.popType = 'warn';
                this.popupSet.popImg = 'success.svg';
                this.popupSet.content = '훈련이 완료되었습니다.';
                this.popupSet.cancelBtnText = null;
                this.popupSet.isProtocol = false;
                this.popupSet.nextLink = '/trainingDate';
                this.popupSet.confirmBtnText = '확인';
                this.popupSet.destination = true;
              }, 150);
            }
          }
          if (!this.plyrValue.allSetClear) {
            console.log('진입진입스');
            this.sendTrainingData(
              this.apiStatus.detailId,
              this.plyrValue.currentRepeatCount,
              this.plyrValue.currentSetCount,
            );
          }

          console.log('number: ', this.plyrValue.currentRepeatCount);
          console.log('set: ', this.plyrValue.currentSetCount);
          const url = `samkim://gifEnd?day=${this.$route.query.day}&currentSet=${this.plyrValue.currentSetCount}&currentNum=${this.plyrValue.currentRepeatCount}`;
          document.location.href = url;
          /** !== null if문 끝 */
        } else {
          /**
           *  if (
            this.view.pastCurrentRepeat !== null &&
            this.view.currentType !== "training" &&
            this.plyrValue.isPrepare
          ) {
            console.log("연습하기 루프에 진입");
            this.view.isFirst = true;
            this.plyrValue.isViewMilestone = true;
            } else {
              밑애 애들들어감
            }
          }
           */
          if (this.view.pastCurrentRepeat !== null) {
            this.view.pastCurrentRepeat = null;
          } else {
            console.log('연습하기에만 빠집니다.');
            this.plyrValue.currentRepeatCount = 0;
            if (this.view.doubleSpeed) {
              console.log('[프로토콜] gif 배속설정 off');
              this.view.doubleSpeed = false;
              const url = 'samkim://gifChangeable?flag=false';
              document.location.href = url;
            }
          }
          if (this.view.currentType !== 'training' && this.plyrValue.isPrepare) {
            console.log('이곳에 빠짐');
            this.initSource('tongue_training_light.mp4');
            this.view.currentType = 'training';
          }
          this.callCountAnimation();
        }
        /**
      if (this.plyrValue.isPrepare) {
        this.initSource("tongue_training_light.mp4");
      }
       */
      });
      /** 비디오 전체화면 시작 */
      this.$refs.plyr.player.on('enterfullscreen', (data) => {});

      /** 비디오 전체화면 중지 */
      this.$refs.plyr.player.on('exitfullscreen', (data) => {});
      if (this.view.loadVideo.practice && !this.view.isFirstTraining) {
        this.$refs.plyr.player.play();
        console.log('[프로토콜] gif 배속설정 on');
        this.view.doubleSpeed = true;
        const url = 'samkim://gifChangeable?flag=true';
        document.location.href = url;
      }
      if (
        this.view.loadVideo.practice
        && this.view.isFirstTraining
        && this.getTimeToOrder(this.$route.query.time) === 'morning'
      ) {
        this.$refs.plyr.player.play();
      }
    },
    initVideo() {
      console.log('비디오 준비');
      if (this.view.loadVideo.practice) {
        this.view.currentType = 'practice';
        this.plyrValue.src = 'tongue_practice_light.mp4';
      }
      if (this.view.loadVideo.training) {
        this.view.currentType = 'training';
        this.plyrValue.src = 'tongue_training_light.mp4';
      }
      this.initAttach();
      this.$nextTick((_) => {
        this.loadVideoItem();
      });
    },
    initAttach() {
      if (!this.view.isPageStart) {
        this.view.videoPlayerOn = true;
        const url = `samkim://cameraTonguePreviewStart?detailId=${this.apiStatus.detailId}`;
        document.location.href = url;
        this.view.isPageStart = true;
      }
    },
    callCountAnimation() {
      console.log('애니메이션 콜');
      let currentValue;
      this.animate.intervalObj = setInterval(() => {
        currentValue = this.animate.value;
        if (currentValue === null) {
          this.animate.value = '준비';
        } else if (currentValue === '준비') {
          this.animate.value = '시작';
        } else if (currentValue === '시작') {
          clearInterval(this.animate.intervalObj);
          if (this.view.isFirst) {
            this.view.isFirst = false;
          }
          console.log('플레이 요청을 하였음');
          this.$refs.plyr.player.play();
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    getCommentTypeToOrder() {
      return (order) => {
        console.log('getCommentTypeToOrder -order :', order);
        console.log('getCommentTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'total';
        }
        if (Number(order) === 1) {
          return 'life';
        }
        if (Number(order) === 2) {
          return 'training';
        }
      };
    },
    getTypeToOrder() {
      return (order) => {
        console.log('getTypeToOrder -order :', order);
        console.log('getTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'swallow';
        }
        if (Number(order) === 1) {
          return 'raising';
        }
        if (Number(order) === 2) {
          return 'turn';
        }
      };
    },
    getTimeToOrder() {
      return (order) => {
        console.log('getTimeToOrder -order :', order);
        console.log('getTimeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'morning';
        }
        if (Number(order) === 1) {
          return 'lunch';
        }
        if (Number(order) === 2) {
          return 'evening';
        }
      };
    },
    getCountToOrder() {
      return (order) => {
        console.log('getCountToOrder -order :', order);
        console.log('getCountToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return '1';
        }
        if (Number(order) === 1) {
          return '2';
        }
        if (Number(order) === 2) {
          return '3';
        }
      };
    },
  },
  beforeDestroy() {
    const url = 'samkim://cameraTonguePreviewEnd';
    document.location.href = url;
  },
  components: {
    vSelect,
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .training-notification {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 125px;
      height: 53px;
      border-radius: 19px;
      border: 1px solid #fd8b11;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      opacity: 0;
      z-index: 5;
      top: 50%;
      &.active {
        opacity: 1;
      }
      &.prepare {
        background: #fff;
        span {
          color: #fd8b11;
        }
      }
      &.start {
        background: #fd8b11;
        span {
          color: #fff;
        }
      }
      span {
        display: block;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 24px,
          $font-family: "AppleSDGothicNeoB00",
          $letter-spacing: -0.52px,
          $text-align: left
        );
      }
    }
    .low-margin {
      margin: 0 0 56px 0;
    }

    .directive.low {
      margin: 0 0 51px 0;
    }

    .camera-context {
      display: flex;
      position: relative;
      &.active {
        opacity: 1;
      }
      .item-box {
        width: calc(50% - 5px);
        position: relative;
        min-height: 524px;
        /**
        &.holder {
          &:after {
            content: "";
            width: 30px;
            height: 1px;
            background: #315754;
            position: absolute;
            left: -40px;
            top: 0;
          }
        }
         */
        .info {
          position: relative;
          width: 100%;
          border-radius: 2px;
          padding: 18px 30px 18px 45px;
          border: solid 1px #bbbcc1;
          background-color: #ffffff;
          margin: 0 0 10px 0;
          height: 195px;
          .bg-img {
            position: absolute;
            left: 0;
            top: 0;
            img {
            }
          }
          .item-wrap {
            .item {
              margin: 25px 0 0 0;
              &:first-of-type {
                margin: 0;
              }
              &.flex {
                margin: 0;
              }
              h3.title {
                margin: 0 0 10px 0;
                @include initfontKor(
                  $font-size: 30px,
                  $line-height: 36px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.6px,
                  $text-align: left
                );
              }
              .orderList {
                p {
                  padding: 0 0 0 25px;
                  position: relative;
                  @include initfontKor(
                    $font-size: 22px,
                    $line-height: 28px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #363739,
                    $letter-spacing: -0.44px,
                    $text-align: left
                  );
                  &:nth-of-type(1):after {
                    content: "1)";
                  }
                  &:nth-of-type(2):after {
                    content: "2)";
                  }
                  &:nth-of-type(3):after {
                    content: "3)";
                  }
                  &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                  }
                }
              }
            }
          }
        }
        .animator-wrap {
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          transition: 0.5s;
          display: none;
          &.active {
            display: block;
          }
          &.pause {
            display: block;
            background: rgba(54, 61, 65, 0.5);
          }
          .animator {
            .btn-wrap {
              display: flex;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              flex-wrap: wrap;
              .item {
                width: 100%;
                &:first-of-type {
                  margin: 0;
                  button {
                    background: #21978c;
                    &:after {
                      background-image: url("~@assets/images/util/icon_replay.svg");
                    }
                  }
                }
                margin: 20px 0 0 0;
                button {
                  width: 290px;
                  border: 0;
                  padding: 0 40px 0 17.5px;
                  @include initfontKor(
                    $font-size: 30px,
                    $line-height: 52px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: left
                  );
                  position: relative;
                  background: #fd8b11;
                  cursor: pointer;
                  &:after {
                    content: "";
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 11px;
                    background-image: url("~@assets/images/util/icon_nextplay.svg");
                  }
                }
              }
            }
            .half-circle-timer {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgb(54, 61, 65);
              span {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            .info-wrap {
              width: 95px;
              height: 95px;
              background: rgb(54, 61, 65);
              border-radius: 50%;
              left: 0;
              position: absolute;
              top: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              .holder {
              }
              h4.title {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
                margin: 0 0 8px 0;
                width: 100%;
              }
              .content {
                display: flex;
                align-items: center;
                justify-content: center;
                .img-wrap {
                  img {
                    max-width: 34px;
                  }
                }
                p {
                  @include initfontKor(
                    $font-size: 19px,
                    $line-height: 24px,
                    $font-family: "AppleSDGothicNeoL00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                  margin: 0 0 0 5px;
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          margin: 0 0 0 10px;
        }
        &.activated {
          .camera-attach {
            height: calc(100% - 205px);
            background: #676767;
            position: relative;
            .select-wrap {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 1;
            }
          }
          .training-status {
            padding: 15px;
            background: rgb(54, 61, 65);
            width: 100%;
            bottom: 0;
            position: absolute;
            min-height: 126px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .item {
              width: 100%;
              margin: 10px 0 0 0;
              display: flex;
              border-radius: 4px;
              overflow: hidden;
              background: #e9edec;
              &:first-of-type {
                margin: 0;
              }
              h3.i-header {
                width: 60px;
                @include initfontKor(
                  $font-size: 20px,
                  $line-height: 38px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.4px,
                  $text-align: center
                );
                background: #e9edec;
              }
              .i-content {
                width: calc(100% - 60px);
                display: flex;
                span.num {
                  display: block;
                  background: #e9edec;
                  border: 0px solid #fff;
                  border-width: 0 1px;
                  min-width: 37px;
                  @include initfontKor(
                    $font-size: 20px,
                    $line-height: 36px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #315754,
                    $letter-spacing: -0.4px,
                    $text-align: center
                  );
                  &.active {
                    background: #315754;
                    color: #fff;
                    font-family: "AppleSDGothicNeoB00";
                  }
                }
              }
            }
            &:first-of-type {
            }
          }
        }
      }
    }
  }
}
.video-player {
  height: 100%;
}
.app-context {
  .wrap-context {
    .camera-attach .select-wrap {
      width: 105px !important;
      margin: 10px;
      background: #fff;
      .v-select {
        .vs__selected {
        }
      }
    }
  }
}
</style>
