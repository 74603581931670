<template>
  <div class="tab-box" :class="{ diet: tabType === 1, evalRecord: tabType === 2 }">
    <ul class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(index)"
        :class="{ tab__selected: index == selectedIndex }"
      >
        <p class="title">{{ tab.tabData.title }}</p>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tabType: {
      type: Number,
      default: 0
    },
    stopAuto: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedIndex: 0,
      currentProgressNum: 1,
      tabs: []
    };
  },
  created() {
    this.tabs = this.$children;
    console.log("정보", this.tabs);
  },
  mounted() {
    if (!this.stopAuto) {
      this.selectTab(0);
    }
  },
  methods: {
    refSelectTab(i) {
      this.selectedIndex = i;
      this.currentProgressNum = i + 1;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
    selectTab(i) {
      this.selectedIndex = i;
      this.currentProgressNum = i + 1;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
      /** 선택사항 이벤트 수신시 리로드 */
      this.$emit("tab-selected", i);
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.tab-box {
  &.evalRecord {
    ul.tabs__header {
      li {
        min-width: 146px;
        height: 46px;
        width: auto;
        padding: 0 20px;
      }
    }
  }
  &.diet {
    ul.tabs__header {
      width: 290px;
      height: 50px;
      padding: 5px;
      background: #fff;
      margin: 0 auto 16px auto;
      li {
        width: 140px;
        height: 40px;
        background: transparent;
        margin: 0;
        &:first-of-type {
        }
        &.tab__selected {
          border-radius: 2px;
          box-shadow: 0 3px 6px 0 rgba(108, 109, 112, 0.3);
          background-color: rgb(253, 139, 17);
          p.title {
            color: #fff;
          }
        }
        p.title {
          @include initfontKor(
            $font-size: 22px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fd8b11,
            $letter-spacing: -0.44px,
            $text-align: center
          );
        }
      }
    }
    .tab-wrapper {
      padding: 0;
      background: transparent;
      box-shadow: none;
      border: 0;
    }
  }
}
ul.tabs__header {
  padding: 0;
  li {
    width: 160px;
    height: 46px;
    background: rgba(49, 87, 84, 0.1);
    margin: 0 0 0 10px;
    border-top-right-radius: 4.5px;
    border-top-left-radius: 4.5px;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &.tab__selected {
      background: #fff;
      p {
        @include initfontKor(
          $font-size: 26px,
          $line-height: 46px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #363739,
          $letter-spacing: -0.92px,
          $text-align: center
        );
        color: #315754;
      }
      &:after {
        content: "";
        position: absolute;
        background: #fff;
        width: calc(100% + 10px);
        height: 20px;
        bottom: -20px;
        left: 0;
      }
      &:first-of-type {
        box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
      }
    }
    &:first-of-type {
      margin: 0;
    }
    p {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.92px,
        $text-align: center
      );
    }
  }
}
.tab-wrapper {
  padding: 52px 32px;
  background: #fff;
  box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
  /**
  border: solid 1px rgb(234, 235, 241);
   */
}
</style>
