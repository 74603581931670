<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
      :isCollapsed="true"
    ></side-menu>
    <div class="wrap-context">
      <h3 class="welcome"><b>건강한 삼킴</b>을 통해</h3>
      <h3 class="welcome"><b>삶의 의미</b>를 높여보아요!</h3>
      <block-menu :menuList="menuList"></block-menu>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @history-called="historyCall" />
    <information-popup
      v-if="checkInformationPopup"
      :informationPopupSet="informationPopupSet"
      @go-training="goTraining"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import SideMenu from '@/components/SideMenu.vue';
import BlockMenu from '@component/BlockMenu.vue';
import DefaultPopup from '@/components/modal/DefaultPopup';
import InformationPopup from '@/components/modal/InformationPopup';
import {
  mapActions, mapMutations, mapState, mapGetters,
} from 'vuex';

export default {
  data() {
    return {
      informationPopupSet: {},
      popupSet: {},
      menuList: [
        {
          img: 'item01.png',
          name: '삼킴 평가',
          isActive: false,
          routerUrl: 'evaluate',
        },
        {
          img: 'item02.png',
          name: '삼킴 훈련',
          isActive: true,
          routerUrl: 'trainingDate',
        },
        {
          img: 'item03.png',
          name: '삶의 의미\n탐색',
          isActive: false,
          routerUrl: 'choiceMeaning',
        },
        {
          img: 'item04.png',
          name: '자세법과\n식이법',
          isActive: true,
          routerUrl: 'choiceType',
        },
        {
          img: 'item05.png',
          name: '원격상담',
          isActive: false,
          routerUrl: 'reservationType',
        },
      ],
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: true,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: false,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      view: {},
    };
  },
  created() {
    if (localStorage.getItem('token') !== null) {
      this.checkEntry();
    }
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    /**
     *   this.$router.push({
          name: "InformationDate",
          query: { type: "total", time: "start" }
        });
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_INFORMATION_POPUP']),
    ...mapActions('util', ['FETCH_ENTRY', 'FETCH_ENTRY_FCM']),
    goTraining() {
      console.log('이벤트 수신함');
      this.$router.push('/trainingDate');
    },
    historyCall() {
      const url = 'samkim://appClose';
      document.location.href = url;
      localStorage.removeItem('passedDailyComment');
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else if (this.checkInformationPopup) {
        this.SET_INFORMATION_POPUP(false);
      } else {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '앱을 종료하시겠습니까?';
        this.popupSet.cancelBtnText = '아니오';
        this.popupSet.confirmBtnText = '예';
        this.popupSet.nextLink = null;
        this.popupSet.historyCall = true;
      }
    },
    async checkEntry() {
      if (this.GET_FCMTOKEN !== '') {
        try {
          await this.FETCH_ENTRY_FCM(this.GET_FCMTOKEN).then((data) => {
            console.log('정보 :', data);
            if (data.response.realDay === data.response.day) {
              if (!data.response.trainingCompleted) {
                if (
                  localStorage.getItem('passedDailyComment')
                  !== this.$moment(new Date()).format('YYYY-MM-DD')
                ) {
                  console.log('일차 팝업호출 진입');
                  this.SET_INFORMATION_POPUP(true);
                  this.informationPopupSet.popType = 0;
                  this.informationPopupSet.commentType = 'total';
                  this.informationPopupSet.commentTime = 'start';
                  this.informationPopupSet.commentDay = data.response.day;
                  this.informationPopupSet.commentTrainingTime = null;
                } else {
                  console.log('삼킴훈련 팝업호출 진입');
                  this.SET_POPUP(true);
                  this.popupSet.popType = 'needTraining';
                }
              } else if (!data.response.lifeCompleted) {
                console.log('삶의의미 팝업호출 진입');
                this.SET_POPUP(true);
                this.popupSet.popType = 'needLifeSearching';
              }
            } else if (data.response.trainingDay === data.response.lifeDay) {
              if (
                localStorage.getItem('passedDailyComment')
                  !== this.$moment(new Date()).format('YYYY-MM-DD')
              ) {
                console.log('일차 팝업호출 진입');
                this.SET_INFORMATION_POPUP(true);
                this.informationPopupSet.popType = 0;
                this.informationPopupSet.commentType = 'total';
                this.informationPopupSet.commentTime = 'start';
                this.informationPopupSet.commentDay = data.response.day;
                this.informationPopupSet.commentTrainingTime = null;
              } else {
                console.log('삼킴훈련 팝업호출 진입');
                this.SET_POPUP(true);
                this.popupSet.popType = 'needTraining';
              }
            } else if (data.response.trainingDay > data.response.lifeDay) {
              console.log('삶의의미 팝업호출 진입');
              this.SET_POPUP(true);
              this.popupSet.popType = 'needLifeSearching';
            }
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error: ', error);
        }
      } else {
        try {
          await this.FETCH_ENTRY().then((data) => {
            console.log('정보 :', data);
            if (data.response.realDay === data.response.day) {
              if (!data.response.trainingCompleted) {
                if (
                  localStorage.getItem('passedDailyComment')
                  !== this.$moment(new Date()).format('YYYY-MM-DD')
                ) {
                  console.log('일차 팝업호출 진입');
                  this.SET_INFORMATION_POPUP(true);
                  this.informationPopupSet.popType = 0;
                  this.informationPopupSet.commentType = 'total';
                  this.informationPopupSet.commentTime = 'start';
                  this.informationPopupSet.commentDay = data.response.day;
                  this.informationPopupSet.commentTrainingTime = null;
                } else {
                  console.log('삼킴훈련 팝업호출 진입');
                  this.SET_POPUP(true);
                  this.popupSet.popType = 'needTraining';
                }
              } else if (!data.response.lifeCompleted) {
                console.log('삶의의미 팝업호출 진입');
                this.SET_POPUP(true);
                this.popupSet.popType = 'needLifeSearching';
              }
            } else if (data.response.trainingDay === data.response.lifeDay) {
              if (
                localStorage.getItem('passedDailyComment')
                  !== this.$moment(new Date()).format('YYYY-MM-DD')
              ) {
                console.log('일차 팝업호출 진입');
                this.SET_INFORMATION_POPUP(true);
                this.informationPopupSet.popType = 0;
                this.informationPopupSet.commentType = 'total';
                this.informationPopupSet.commentTime = 'start';
                this.informationPopupSet.commentDay = data.response.day;
                this.informationPopupSet.commentTrainingTime = null;
              } else {
                console.log('삼킴훈련 팝업호출 진입');
                this.SET_POPUP(true);
                this.popupSet.popType = 'needTraining';
              }
            } else if (data.response.trainingDay > data.response.lifeDay) {
              console.log('삶의의미 팝업호출 진입');
              this.SET_POPUP(true);
              this.popupSet.popType = 'needLifeSearching';
            }
          });
        } catch (error) {
          console.log('error: ', error);
        }
      }
    },
    async testVersion() {
      try {
        await this.FETCH_APP_VERSION().then((data) => {
          console.log('data :', data);
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
      }
    },
    isUnAuthorized() {
      console.log('401 에러가 날때 나오는 메인페이지 팝');
      if (this.checkInformationPopup) {
        this.SET_INFORMATION_POPUP(false);
      }
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async checkToken() {
      try {
        await this.ACCOUNT_INFO().then(() => {});
      } catch (error) {
        console.log('error :', error);
      }
    },
    goIntroPage() {
      this.$router.push('/informationDate');
    },
  },
  mounted() {
    console.log('총 넓이', window.innerWidth);
    console.log('총 높이', window.innerHeight);
    this.SET_POPUP(true);
    this.popupSet.popType = 'needTraining';
    /**
    this.SET_INFORMATION_POPUP(true);
    this.informationPopupSet.popType = 0;
    this.informationPopupSet.commentType = "total";
    this.informationPopupSet.commentTime = "start";
    this.informationPopupSet.commentDay = "2";
    this.informationPopupSet.commentTrainingTime = null;
     */
    /**
    if (sessionStorage.getItem("infoPage") !== null) {
      this.goIntroPage();
    }
     */
    /**
     * 푸시 대비용
    this.$router.push({
      name: "TrainingDate",
      query: { order: 2 }
    });
     */
  },
  beforeDestroy() {
    this.SET_POPUP(false);
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkInformationPopup']),
    ...mapGetters('auth', ['GET_APPVERSION', 'GET_FCMTOKEN']),
  },
  components: {
    SideMenu,
    BlockMenu,
    DefaultPopup,
    InformationPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 1055px;
    margin: 90px 90px 0 auto;
    h3.welcome {
      position: relative;
      margin: 0 0 40px 0;
      @include initfontKor(
        $font-size: 46px,
        $line-height: 60px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.92px,
        $text-align: left
      );
      &:first-of-type {
        margin: 0;
        padding: 20px 0 0 0;
        &:after {
          background-image: url("~@assets/images/main/mark.svg");
          position: absolute;
          top: 0;
          left: -30px;
          width: 29px;
          height: 29px;
          content: "";
        }
      }
      b {
        font-weight: normal;
        font-family: "AppleSDGothicNeoB00";
        color: #fd8b11;
        position: relative;
        font-size: 46px;
        /**
        &:after {
          width: 100%;
          height: 3px;
          background: #fd8b11;
          position: absolute;
          bottom: -4px;
          content: "";
          left: 0;
        }
        &:before {
          width: 100%;
          height: 1px;
          background: #fd8b11;
          position: absolute;
          bottom: -8px;
          content: "";
          left: 0;
        }
         */
      }
    }
  }
}
</style>
