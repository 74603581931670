<template>
  <div class="swiper-wrap">
    <swiper
      class="swiper"
      :options="swiperOption"
      ref="mySwiper"
      @slide-change-transition-start="slideStart"
      @slide-change-transition-end="slideEnd"
    >
      <swiper-slide v-for="(card, index) in swiperList" :key="index + 'A'">
        <div class="item">
          <div class="in-box">
            <h5 class="date">{{ card.day }}일차</h5>
            <h3 class="title" v-html="computedLine(card.title, index)"></h3>
            <div class="btn-wrap">
              <button
                type="button"
                @click="goLifeSearch(index)"
                v-text="transTxt(card.progress)"
              />
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div
      class="swiper-btn prev"
      @click="prev()"
      :class="{ isHide: this.currentIndex === 0 }"
    >
      <img src="@assets/images/search/prev.svg" />
    </div>
    <div
      class="swiper-btn next"
      @click="next()"
      :class="{ isHide: this.currentIndex === this.swiperList.length - 1 }"
    >
      <img src="@assets/images/search/next.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    swiperList: {
      type: Array,
    },
    swiperOrder: {
      type: Number,
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        allowTouchMove: false,
        centeredSlides: true,
        lazy: true,
        loopFillGroupWithBlank: true,
        spaceBetween: 41,
        navigation: {
          nextEl: ".swiper-btn.next",
          prevEl: ".swiper-btn.prev",
        },
      },
      currentIndex: 0,
    };
  },
  created() {
    /**
    if (this.swiperList[5].title.indexOf("소중한 나의 역사, 그 모든 순간") !== -1) {
      this.swiperList[5].title = "소중한 나의 역사, \n그 모든 순간";
    }
    if (this.swiperList[20].title.indexOf("사람은 누구나 괴로움을 겪는다") !== -1) {
      this.swiperList[20].title = "사람은 누구나\n 괴로움을 겪는다";
    }
    if (
      this.swiperList[31].title.indexOf("스마일 어르신들의 활기찬 노년 이야기") !== -1
    ) {
      this.swiperList[31].title = "스마일 어르신들의\n 활기찬 노년 이야기";
    }
    if (this.swiperList[34].title.indexOf("내가 주변에 나누어 주었던 것들") !== -1) {
      this.swiperList[34].title = "내가 주변에\n 나누어 주었던 것들";
    }
     */
  },
  mounted() {
    this.checkRealIndex();
  },
  methods: {
    goLifeSearch(swiperIndex) {
      console.log("스와이퍼 인덱스", swiperIndex);
      const progress = this.swiperList[swiperIndex].progress >= 100;
      this.$emit("into-list", this.swiperList[swiperIndex].day, progress);
    },
    checkRealIndex() {
      this.currentIndex = this.swiperOrder;
      this.$refs.mySwiper.$swiper.slideTo(this.swiperOrder);
    },
    slideStart(info) {
      console.log("리얼인덱스", info.realIndex);
      console.log("스와이프리스트 렝스", this.swiperList.length);
    },
    slideEnd() {},
    prev() {
      const index = this.currentIndex;
      this.currentIndex = index - 1;
      this.$refs.mySwiper.$swiper.slidePrev();
      console.log("prev Click");
    },
    next() {
      const index = this.currentIndex;
      this.currentIndex = index + 1;
      this.$refs.mySwiper.$swiper.slideNext();
      console.log("next Click");
    },
  },
  computed: {
    computedLine() {
      return (text, index) => {
        if (index === 5) {
          return "소중한 나의 역사, <br>그 모든 순간";
        } else if (index === 20) {
          return "사람은 누구나<br> 괴로움을 겪는다";
        } else if (index === 31) {
          return "스마일 어르신들의<br> 활기찬 노년 이야기";
        } else if (index === 34) {
          return "내가 주변에<br> 나누어 주었던 것들";
        } else {
          return text;
        }
      };
    },
    transTxt() {
      return (text) => {
        if (text === 0) {
          return "시작하기";
        }
        if (text < 100) {
          return "탐색중";
        }
        return "완료";
      };
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.swiper-wrap {
  height: auto;
  .swiper-btn {
    width: 73px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    cursor: pointer;
    z-index: 1;
    background-color: rgba(253, 139, 17, 0.1);
    &.isHide {
      display: none;
    }
    &.prev {
      left: calc(50% - 178px);
    }
    &.next {
      left: calc(50% + 178px);
    }
    img {
      max-width: 41px;
    }
  }
  .swiper {
    max-width: 934px;
    margin: 69px auto 0 auto;

    .swiper-slide {
      &.swiper-slide-next {
      }
      .item {
        margin: 0;
        width: 100%;
        height: 254px;

        h5.date {
          @include initfontKor(
            $font-size: 19px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          margin: 0 0 19px 0;
        }

        h3.title {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #fe8c12,
            $letter-spacing: -0.52px,
            $text-align: center
          );
          margin: 0 0 25px 0;
          min-height: 68px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn-wrap {
          button {
            width: 191px;
            height: 43px;
            padding: 0;
            border: 0;
            cursor: pointer;
            @include initfontKor(
              $font-size: 22px,
              $line-height: 43px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.44px,
              $text-align: center
            );
          }
        }
      }
    }
    .swiper-slide.swiper-slide-active {
      .item {
        width: 100%;
      }
    }
    .item {
      border-radius: 5px;
      box-shadow: 0 3px 50px 0 rgba(165, 165, 165, 0.3);
      background-color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      .in-box {
        .btn-wrap {
          display: flex;
          justify-content: center;
          button {
            border: 0;
            padding: 0;
            background: #315754;
          }
        }
      }
    }
  }
}
</style>
