var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-context flex absolute"},[_c('side-menu',{attrs:{"sideMenuOption":_vm.sideMenuOption}}),(_vm.popType === 0 && _vm.view.isVisible)?_c('div',{staticClass:"wrap-context infomation-page",class:{
        first: _vm.commentDay < 11 === true,
        second: 10 < _vm.commentDay && _vm.commentDay < 21 === true,
        third: 20 < _vm.commentDay && _vm.commentDay < 31 === true,
        fourth: 30 < _vm.commentDay && _vm.commentDay < 41 === true,
      }},[_c('div',{staticClass:"audio-player",class:{ activated: _vm.plyrValue.isPrepared }},[_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.plyrOption}},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":require(("@/assets/audio/" + (_vm.plyrValue.src))),"type":"audio/aac"}})])])],1),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.closeInfo()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/closeX.svg")}})]),_c('div',{staticClass:"content-wrap"},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.commentDay)+"일차")]),(_vm.view.isPlaying)?_c('div',{staticClass:"audio-dummy",class:{ narrow: this.commentDay === 1 },on:{"click":function($event){return _vm.audioToggle()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/icon_sound.svg")}})]):_c('div',{staticClass:"audio-dummy",class:{ narrow: this.commentDay === 1 },on:{"click":function($event){return _vm.audioToggle()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/icon_sound_mute.svg")}})]),_c('p',{staticClass:"paragraph",class:{ small: this.commentDay === 1 },domProps:{"innerHTML":_vm._s(_vm.computedLine(_vm.comment))}}),(_vm.commentTime === 'start')?_c('div',{staticClass:"btn-wrap"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goTraining()}}},[_vm._v("시작하기")])]):_vm._e()])]):_vm._e(),(_vm.popType === 1 && _vm.view.isVisible)?_c('div',{staticClass:"wrap-context infomation-page",class:{
        isStart: _vm.commentTime === 'start',
        isEnd: _vm.commentTime === 'end',
      }},[_c('div',{staticClass:"audio-player",class:{ activated: _vm.plyrValue.isPrepared }},[_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.plyrOption}},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":require(("@/assets/audio/" + (_vm.plyrValue.src))),"type":"audio/aac"}})])])],1),_c('div',{staticClass:"close",on:{"click":function($event){return _vm.closeInfo()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/closeX.svg")}})]),_c('div',{staticClass:"content-wrap"},[_c('h3',{staticClass:"specific"},[_vm._v("삶의 의미 탐색")]),_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.commentDay)+"일차")]),(_vm.view.isPlaying)?_c('div',{staticClass:"audio-dummy",on:{"click":function($event){return _vm.audioToggle()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/icon_sound.svg")}})]):_c('div',{staticClass:"audio-dummy",on:{"click":function($event){return _vm.audioToggle()}}},[_c('img',{attrs:{"src":require("@/assets/images/util/icon_sound_mute.svg")}})]),_c('p',{staticClass:"paragraph",domProps:{"innerHTML":_vm._s(_vm.computedLine(_vm.comment))}}),(_vm.commentTime === 'start')?_c('div',{staticClass:"btn-wrap"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goTraining()}}},[_vm._v("시작하기")])]):_c('div',{staticClass:"btn-wrap"},[(_vm.commentType === 'life' && _vm.commentDay !== 1 && _vm.commentDay !== 40)?_c('button',{staticClass:"share-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.share(_vm.view.shareItem)}}},[_c('img',{staticClass:"pop-talk-img",attrs:{"src":require("@/assets/images/popup/talk.svg")}}),_vm._v(" 카카오톡으로 공유 ")]):_vm._e(),_c('button',{staticClass:"share-normal",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeInfo()}}},[_vm._v("닫기")])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }