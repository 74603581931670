<template>
  <div
    class="result-list"
    :class="{
      flex: resultType === 0,
      grid: (resultType === 1) | (resultType === 3),
      dietList: resultType === 2,
    }"
  >
    <div class="r-header">
      <div
        class="item"
        v-for="(headerItem, index) in resultList.header"
        :key="index + 'A'"
      >
        <h4>{{ headerItem }}</h4>
      </div>
    </div>
    <div
      class="r-content"
      v-if="resultType === 0"
      :class="{ progress: resultType === 0 }"
    >
      <div
        class="list"
        v-for="(listItem, index) in resultList.list"
        :key="index + 'B'"
        @click="goDetail(index)"
      >
        <div class="item">
          <h4 class="day">{{ listItem.day }}일차</h4>
        </div>
        <div class="item">
          <h4 v-html="computedLine(listItem.title)"></h4>
        </div>
        <div class="item">
          <div class="progress-wrap">
            <div class="progress-box">
              <div class="progress"></div>
            </div>
            <p class="percent" v-text="transPercent(listItem.progress, index)"></p>
          </div>
        </div>
        <div class="item">
          <div
            class="btn-wrap"
            :class="{
              active: listItem.progress > 0,
              ing: listItem.progress > 0 && listItem.progress < 100,
            }"
          >
            <button
              type="button"
              @click="listDetail(listItem)"
              v-if="listItem.progress < 100"
              v-text="transTxt(listItem.progress)"
            />
            <!-- :class="{ ing : listItem.progress > 0 && listItem.progress < 100}" -->
            <p class="done" v-else @click="meaningDone()">탐색완료</p>
          </div>
        </div>
        <div class="item">
          <div class="btn-wrap trans" :class="{ active: listItem.progress >= 100 }">
            <button
              type="button"
              @click="shareResult(listItem, listItem.progress >= 100)"
              v-if="listItem.day != 1 && listItem.day != 40"
            >
              공유하기
            </button>
            <div v-else>-</div>
          </div>
        </div>
        <div class="item">
          <h4
            v-text="listItem.updateAt !== null ? transDate(listItem.updateAt) : '-'"
          ></h4>
        </div>
      </div>
    </div>
    <div class="r-content" v-if="resultType === 1">
      <div class="list-wrap" v-if="resultList.list.length > 0">
        <div
          class="list"
          :class="{ inCursor: resultType === 1 }"
          v-for="(listItem, index) in resultList.list"
          :key="index + 'C'"
          @click="checkConts(index)"
        >
          <div class="item">
            <h4 class="day">{{ index + 1 }}</h4>
          </div>
          <div class="item" :class="{ isNew: !listItem.isRead }">
            <div class="content-wrap">
              <h4 v-text="listItem.title"></h4>
            </div>
          </div>
          <div class="item">
            <h4 v-text="transDate(listItem.createAt)"></h4>
          </div>
        </div>
      </div>
      <div class="list-wrap" v-else>
        <p class="empty">알람이 없습니다.</p>
      </div>
    </div>
    <div class="r-content" v-if="resultType === 3">
      <div
        class="list"
        :class="{ inCursor: resultType === 3 }"
        v-for="(listItem, index) in resultList.list"
        :key="index + 'C'"
        @click="checkIdx(index)"
      >
        <div class="item">
          <h4 class="day">{{ index + 1 }}</h4>
        </div>
        <div class="item">
          <div class="content-wrap">
            <h4 v-text="listItem.title"></h4>
          </div>
        </div>
        <div class="item">
          <h4 v-text="transDate(listItem.createAt)"></h4>
        </div>
      </div>
    </div>
    <div class="r-content" v-if="resultType === 2">
      <div class="list" v-for="(listItem, index) in resultList.list" :key="index + 'B'">
        <div class="item">
          <h4 class="day">{{ makePageNum(index) }}</h4>
        </div>
        <div class="item">
          <h4 v-text="listItem.title" class="left-direction"></h4>
        </div>
        <div class="item inCursor">
          <div class="btn-wrap" v-if="!listItem.isCompleted">
            <button type="button" @click="goDetail(index)">시작하기</button>
          </div>
          <p class="done" v-else @click="goDetail(index)">활동완료</p>
        </div>
        <div class="item">
          <div class="btn-wrap trans" :class="{ active: listItem.isExist }">
            <button
              type="button"
              @click.stop
              @click="shareResult(listItem, listItem.isExist)"
            >
              공유하기
            </button>
          </div>
        </div>
        <div class="item">
          <h4 v-text="transDate(listItem.updateAt)"></h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resultList: {
      type: Object,
    },
    resultType: {
      type: Number,
      default: 0,
    },
    resultFor: {
      type: String,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabOrder: null,
    };
  },
  created() {},
  methods: {
    meaningDone() {
      this.$emit('isProceed-meaning');
    },
    listDetail(item) {
      this.$emit('lifelist-clicked', item);
    },
    goDetail(index) {
      const indexSample = index + 20;
      if (this.resultFor === 'diet') {
        this.$router.push({
          name: 'InfomationPage',
          query: {
            questionOrder: this.resultList.list[index].questionOrder,
            id: this.resultList.list[index].id,
          },
        });
      } else if (this.resultFor === 'posture') {
        this.$router.push({
          name: 'InfomationPosturePage',
          query: {
            questionOrder: this.resultList.list[index].questionOrder,
            id: this.resultList.list[index].id,
            isExist: this.resultList.list[index].isExist,
          },
        });
      }
    },
    checkIdx(index) {
      this.$emit(
        'call-alerm-pop',
        this.transDate(this.resultList.list[index].createAt),
        this.resultList.list[index].id,
      );
    },
    checkConts(index) {
      console.log('data-check-origin', this.resultList.list[index]);

      /**
      console.log("data-check", this.resultList.list[index].data.body);
      const convertObj = JSON.parse(this.resultList.list[index].data);
      const dataVal = decodeURI(decodeURIComponent(convertObj.body));
       */
      this.$emit(
        'call-alerm-pop',
        this.transDate(this.resultList.list[index].createAt),
        this.resultList.list[index].body,
      );
    },
    initResult(list, order) {
      console.log(order, '에서 실행됨');
      this.resultList.list = list;
      this.tabOrder = order;
    },
    shareResult(itemList, condition) {
      if (condition) {
        console.log('리스트에서의 쉐어에밋 :', itemList);
        this.$emit('share-pop-call', itemList);
      }
    },
    transPercent(number, index) {
      this.$nextTick((_) => {
        if (this.tabOrder === null || this.tabOrder === 0) {
          document
            .querySelector('.r-content')
            .childNodes[index].childNodes[2].querySelector(
              '.progress',
            ).style.width = `${number}%`;
        } else {
          document
            .querySelector('.tab-box')
            .childNodes[this.tabOrder + 1].querySelector('.r-content')
            .childNodes[index].childNodes[2].querySelector(
              '.progress',
            ).style.width = `${number}%`;
        }
      });
      console.log('index', index);
      return `${number}%`;
    },
  },
  computed: {
    makePageNum() {
      return (index) => (this.currentPage - 1) * 5 + (index + 1);
    },
    computedLine() {
      return (text) => {
        if (text.indexOf('소중한 나의 역사, 그 모든 순간') !== -1) {
          return '소중한 나의 역사, <br>그 모든 순간';
        } if (text.indexOf('색으로 표현해보는 나의 삶') !== -1) {
          return '색으로 표현해보는 <br>나의 삶';
        } if (text.indexOf('사람은 누구나 괴로움을 겪는다') !== -1) {
          return '사람은 누구나<br> 괴로움을 겪는다';
        } if (text.indexOf('스마일 어르신들의 활기찬 노년 이야기') !== -1) {
          return '스마일 어르신들의<br> 활기찬 노년 이야기';
        } if (text.indexOf('내가 주변에 나누어 주었던 것들') !== -1) {
          return '내가 주변에<br> 나누어 주었던 것들';
        }
        return text;
      };
    },
    transDate() {
      return (info) => {
        console.log('info :', info);
        if (info === null) {
          return '-';
        }
        return this.$moment(info).format('YY-MM-DD');
      };
    },
    transTxt() {
      return (text) => {
        if (text === 0) {
          return '시작하기';
        }
        if (text < 100) {
          return '탐색중';
        }
      };
    },
    transTimeFormat() {
      return (time) => {
        console.log('t-t-time', time);
        return this.$moment(time).format('YY-MM-DD hh:mm');
      };
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.result-list {
  &.dietList {
    .r-header {
      display: grid;
      align-items: center;
      height: 57px;
      grid-auto-flow: dense;
      grid-gap: 0;
      grid-template-columns: 60px 1fr 120px 120px 120px;
      background: rgba(253, 139, 17, 0.1);
      padding: 0 20px;
      margin: 0 0 6px 0;
      .item {
        display: flex;
        justify-content: center;
        &:nth-of-type(1) {
        }
        &:nth-of-type(2) {
          justify-content: end;
          padding-left: 30px;
          h4 {
            text-align: left;
          }
        }
        h4 {
          @include initfontKor(
            $font-size: 22px,
            $line-height: 30px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
    }
    .r-content {
      background: #fff;
      .list {
        &.inCursor {
          cursor: pointer;
        }
        padding: 20px;
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 0;
        grid-template-columns: 60px 1fr 120px 120px 120px;
        border-top: 1px solid #e1e5ec;
        &:first-of-type {
          border-top: 0;
        }
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          &.inCursor {
            cursor: pointer;
          }
          p.done {
            @include initfontKor(
              $font-size: 19px,
              $line-height: 25px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #315754,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
          &:nth-of-type(1) {
          }
          &:nth-of-type(2) {
            justify-content: end;
            padding-left: 30px;
            h4 {
              text-align: left;
            }
          }
          &:nth-of-type(3) {
            // justify-content: start;
          }
          &.isNew {
            .content-wrap {
              position: relative;
              width: fit-content;
              margin: 0 auto;
              &:after {
                content: "";
                background-image: url("~@assets/images/util/img_new.svg");
                position: absolute;
                width: 23px;
                height: 23px;
                top: 50%;
                right: -30px;
                transform: translateY(-50%);
              }
            }
            h4 {
              width: fit-content;
              margin: 0 auto;
              max-width: 590px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          h4 {
            @include initfontKor(
              $font-size: 19px,
              $line-height: 25px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: center
            );
            &.day {
              color: #fe8c12;
            }
          }
          .btn-wrap {
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: rgba(49, 87, 84, 0.5);
            overflow: hidden;
            width: 110px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;

            &.active {
              background-color: transparent;
              box-shadow: none;
              cursor: pointer;
            }

            &.trans {
              background: transparent;
              box-shadow: none;
              border-radius: 0;
              &.active {
                button {
                  color: $pointcolor__orange;
                  &:after {
                    background: $pointcolor__orange;
                  }
                }
              }
              button {
                position: relative;
                color: #8b8d94;
                &:after {
                  content: "";
                  position: absolute;
                  width: calc(100% - 46px);
                  bottom: 1px;
                  left: 50%;
                  transform: translateX(-50%);
                  height: 1px;
                  background: #8b8d94;
                }
              }
            }
            button {
              width: inherit;
              border: 0;
              padding: 0;
              background: transparent;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 25px,
                $font-family: "AppleSDGothicNeoR00",
                $color: #fff,
                $letter-spacing: -0.48px,
                $text-align: center
              );
            }
          }
        }
      }
    }
  }
  &.grid {
    .r-header {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 0;
      grid-template-columns: 60px 1fr 130px;
      background: rgba(253, 139, 17, 0.1);
      padding: 0 30px;
      margin: 0 0 6px 0;
      .item {
        h4 {
          @include initfontKor(
            $font-size: 24px,
            $line-height: 50px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
    }
    .r-content {
      background: #fff;
      .list-wrap {
        p.empty {
          min-height: 275px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include initfontKor(
            $font-size: 24px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
      .list {
        &.inCursor {
          cursor: pointer;
        }
        padding: 22px 30px;
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 0;
        grid-template-columns: 60px 1fr 130px;
        border-top: 1px solid #e1e5ec;
        &:first-of-type {
          border-top: 0;
        }
        .item {
          &.isNew {
            .content-wrap {
              position: relative;
              width: fit-content;
              margin: 0 auto;
              &:after {
                content: "";
                background-image: url("~@assets/images/util/img_new.svg");
                position: absolute;
                width: 23px;
                height: 23px;
                top: 50%;
                right: -30px;
                transform: translateY(-50%);
              }
            }
            h4 {
              width: fit-content;
              margin: 0 auto;
              max-width: 590px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          h4 {
            @include initfontKor(
              $font-size: 24px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: center
            );
            &.day {
              color: #fe8c12;
            }
          }
        }
      }
    }
  }
  &.flex {
    .r-header {
      display: flex;
      align-items: center;
      background-color: rgba(253, 139, 17, 0.1);
      padding: 0 20px;
      height: 50px;
      .item {
        margin: 0 0 0 20px;
        display: flex;
        justify-content: center;
        &:nth-of-type(1) {
          width: 70px;
          margin: 0;
          justify-content: end;
        }

        &:nth-of-type(2) {
          width: calc(100% - 710px);
          justify-content: end;
          h4 {
            text-align: left;
          }
        }

        &:nth-of-type(3) {
          width: 180px;
        }

        &:nth-of-type(4) {
          width: 120px;
        }

        &:nth-of-type(5) {
          width: 120px;
        }

        &:nth-of-type(6) {
          width: 120px;
        }
        h4 {
          @include initfontKor(
            $font-size: 22px,
            $line-height: 30px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
    }
    .r-content {
      background: #fff;
      &.progress {
        .list .item:nth-of-type(3) {
          justify-content: flex-end;
        }
      }
      .list {
        display: flex;
        padding: 0 20px;
        border-top: 1px solid #e1e5ec;
        &:first-of-type {
          border-top: 0;
        }
        .item {
          margin: 0 0 0 20px;
          padding: 20px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          &:nth-of-type(1) {
            width: 70px;
            margin: 0;
            justify-content: end;
          }

          &:nth-of-type(2) {
            width: calc(100% - 710px);
            justify-content: end;
            h4 {
              text-align: left;
            }
          }

          &:nth-of-type(3) {
            width: 180px;
          }

          &:nth-of-type(4) {
            width: 120px;
          }

          &:nth-of-type(5) {
            width: 120px;
          }

          &:nth-of-type(6) {
            width: 120px;
          }

          .progress-wrap {
            display: flex;
            align-items: center;

            .progress-box {
              width: 143px;
              height: 11px;
              border-radius: 2px;
              background-color: rgba(49, 87, 84, 0.2);
              margin: 0 11px 0 0;

              .progress {
                background-color: rgb(49, 87, 84);
                height: inherit;
              }
            }

            p.percent {
              @include initfontKor(
                $font-size: 19px,
                $line-height: 25px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #315754,
                $letter-spacing: -0.48px,
                $text-align: left
              );
            }
          }

          h4 {
            @include initfontKor(
              $font-size: 19px,
              $line-height: 25px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: center
            );

            &.day {
              color: #fe8c12;
            }
          }
          p.done {
            @include initfontKor(
              $font-size: 19px,
              $line-height: 25px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
          .btn-wrap {
            border-radius: 2px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            background-color: rgba(49, 87, 84, 0.5);
            overflow: hidden;
            width: 110px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            cursor: pointer;

            &.active {
              background-color: #fff;
              box-shadow: none;
              p {
                color: #315754;
              }
              &.ing {
                background-color: #315754;
              }
            }
            &.trans {
              background: transparent;
              box-shadow: none;
              border-radius: 0;
              &.active {
                button {
                  color: $pointcolor__orange;
                  &:after {
                    background: $pointcolor__orange;
                  }
                }
              }
              button {
                position: relative;
                color: #8b8d94;
                &:after {
                  content: "";
                  position: absolute;
                  width: calc(100% - 46px);
                  bottom: 2px;
                  left: 50%;
                  transform: translateX(-50%);
                  height: 1px;
                  background: #8b8d94;
                }
              }
            }
            button {
              width: inherit;
              border: 0;
              padding: 0;
              background: transparent;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 25px,
                $font-family: "AppleSDGothicNeoR00",
                $color: #fff,
                $letter-spacing: -0.48px,
                $text-align: center
              );
            }
          }
        }
      }
    }
  }
}
</style>
