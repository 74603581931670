<template>
  <div class="record-table">
    <div class="record" v-for="(parentItem, parentIndex) in setOpt.set" :key="parentIndex + 'A'">
      <div class="r-title">
        <h3>{{ parentIndex + 1 }}세트</h3>
      </div>
      <div class="r-content" v-if="record.length > 0">
        <div
          class="item"
          :class="{ active: record[parentIndex].number > childIndex }"
          v-for="(childItem, childIndex) in setOpt.number"
          :key="childIndex + 'B'"
        >
          <p class="num">{{ childIndex + 1 }}</p>
        </div>
      </div>
      <div class="r-content" v-else>
        <div class="item" v-for="(childItem, childIndex) in setOpt.number" :key="childIndex + 'B'">
          <p class="num">{{ childIndex + 1 }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    record: {
      type: Array
    },
    setOpt: {
      type: Object
    }
  },
  data() {
    return {};
  },
  created() {
    console.log("record : ", this.record);
    console.log("record -length : ", this.record.length);
  },
  methods: {},
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.record-table {
  display: flex;
  flex-wrap: wrap;
  .record {
    display: flex;
    width: 100%;
    border-top: solid 1px rgb(187, 188, 193);
    &:first-of-type {
      border-top: 0px;
    }
    .r-title {
      background-color: rgba(49, 87, 84, 0.05);
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0px solid #cacbd0;
      border-width: 0 1px;
      h3 {
        @include initfontKor(
          $font-size: 26px,
          $line-height: 34px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #315754,
          $letter-spacing: -0.52px,
          $text-align: center
        );
      }
    }
    .r-content {
      width: calc(100% - 140px);
      padding: 4px 16px;
      display: flex;
      .item {
        margin: 0 0 0 6px;
        background-color: rgb(233, 237, 236);
        width: 50px;
        height: 50px;
        &.active {
          background: #315754;
          p.num {
            color: #fff;
            font-family: "AppleSDGothicNeoB00";
          }
        }
        p.num {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 50px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #315754,
            $letter-spacing: -0.52px,
            $text-align: center
          );
        }
        &:first-of-type {
          margin: 0;
        }
      }
    }
  }
}
</style>
