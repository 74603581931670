<template>
  <div class="app-context login">
    <div class="wrap-context">
      <div class="toast-alert" :class="{ active: view.isToastAlert }">
        <p v-text="view.toastTxt"></p>
      </div>
      <div class="login-context">
        <div class="close" @click="appCloseCall()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <div class="title">
          <div class="img-wrap">
            <img src="@assets/images/login/symbol_login.png" />
          </div>
          <h3>삼킴건강코치</h3>
        </div>
        <div class="content">
          <div class="account">
            <div class="article" :class="{ focus: view.isNameFocused }">
              <input
                type="text"
                v-model="login.name.value"
                placeholder="성함"
                @click="enterName()"
                @blur="exitName()"
                @input="checkName()"
                @keyup.enter="enteredLogin()"
              />
            </div>
            <div class="article" :class="{ focus: view.isPhoneFocused }">
              <input
                type="number"
                v-model="login.phone.value"
                placeholder="연락처"
                @click="enterPhone()"
                @blur="exitPhone()"
                @input="checkPhone()"
                @keyup.enter="enteredLogin()"
              />
            </div>
          </div>
        </div>
        <div
          class="mega-btn-wrap"
          :class="{ active: login.name.flag && login.phone.flag }"
        >
          <button type="button" @click="loginCall()">로그인</button>
        </div>
        <div class="flex-opt-wrap">
          <div class="item">
            <div class="input-wrap">
              <input type="checkbox" id="storeId" v-model="login.storeId" />
              <label for="storeId">
                <div class="holder">
                  <span class="checkImg" />
                </div>
                <h5>아이디 저장</h5>
              </label>
            </div>
          </div>
          <div class="item">
            <div class="input-wrap">
              <input type="checkbox" id="autoLogin" v-model="login.autoLogin" />
              <label for="autoLogin">
                <div class="holder">
                  <span class="checkImg" />
                </div>
                <h5>자동 로그인</h5>
              </label>
            </div>
          </div>
        </div>
        <div class="notification">
          <p>아직 회원이 아니신가요?</p>
          <router-link to="/join/1">회원가입</router-link>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @history-called="appClose" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import errorParser from "@/utils/error-parser";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      login: {
        name: {
          flag: false,
          value: "",
        },
        phone: {
          flag: false,
          value: "",
        },
        autoLogin: false,
        storeId: false,
      },
      view: {
        isNameFocused: false,
        isPhoneFocused: false,
        isToastAlert: false,
        toastTxt: "",
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
    this.getUserName();
    this.initStoreData();
  },
  methods: {
    ...mapActions("join", ["LOGIN"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("join", [
      "SET_PERSONALINFO",
      "SET_QUESTIONLIST1",
      "SET_QUESTIONLIST2",
      "SET_QUESTIONLIST3",
      "SET_QUESTIONLIST4",
    ]),
    initStoreData() {
      const sampleArray = [];
      if (Object.keys(this.GET_PERSONALINFO).length > 0) {
        console.log("사용자 정보있음");
        this.SET_PERSONALINFO(sampleArray);
      }
      if (this.GET_QUESTIONLIST1.length > 0) {
        console.log("1번리스트 정보있음");
        this.SET_QUESTIONLIST1(sampleArray);
      }
      if (this.GET_QUESTIONLIST2.length > 0) {
        console.log("2번리스트 정보있음");
        this.SET_QUESTIONLIST2(sampleArray);
      }
      if (this.GET_QUESTIONLIST3.length > 0) {
        console.log("3번리스트 정보있음");
        this.SET_QUESTIONLIST3(sampleArray);
      }
      if (this.GET_QUESTIONLIST4.length > 0) {
        console.log("4번리스트 정보있음");
        this.SET_QUESTIONLIST4(sampleArray);
      }
    },
    checkToast(order) {
      this.view.isToastAlert = true;
      this.view.toastTxt =
        order === 0
          ? "성함 또는 연락처가 잘못기입되었습니다."
          : "아이디 또는 비밀번호가 일치하지 않습니다.";
      setTimeout(() => {
        this.view.isToastAlert = false;
      }, 2500);
    },
    enteredLogin() {
      if (this.login.name.flag && this.login.phone.flag) {
        this.loginCall();
      }
    },

    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.SET_POPUP(true);
        this.popupSet.popType = "warn";
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = "앱을 종료하시겠습니까?";
        this.popupSet.cancelBtnText = "아니오";
        this.popupSet.confirmBtnText = "예";
        this.popupSet.nextLink = null;
        this.popupSet.historyCall = true;
      }
    },
    getUserName() {
      if (localStorage.getItem("storeId") !== null) {
        this.login.name.flag = true;
        this.login.name.value = localStorage.getItem("storeId");
        this.login.storeId = true;
      }
    },
    appClose() {
      const url = "samkim://appClose";
      document.location.href = url;
      localStorage.removeItem("passedDailyComment");
    },
    appCloseCall() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "앱을 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.confirmBtnText = "예";
      this.popupSet.nextLink = null;
      this.popupSet.historyCall = true;
    },
    async loginCall() {
      if (!this.login.name.flag || !this.login.phone.flag) {
        this.checkToast(0);
      } else {
        try {
          const payload = {
            userName: this.login.name.value,
            tel: this.login.phone.value,
          };
          if (this.GET_FCMTOKEN !== "") {
            payload.fcmToken = this.GET_FCMTOKEN;
          }
          if (this.GET_DEVICEID !== "") {
            payload.deviceId = this.GET_DEVICEID;
          }
          console.log("로그인시 사용한 fcmToken :", payload.fcmToken);
          console.log("로그인시 사용한 deviceId :", payload.deviceId);
          await this.LOGIN(payload).then((data) => {
            console.log("data-result: ", data.result);
            console.log("data-response-id: ", data.response.id);
            console.log("data-response-token: ", data.response.token);
            if (data.result) {
              localStorage.setItem("token", data.response.token);
              localStorage.setItem("id", data.response.id);
              if (localStorage.getItem("userName") !== data.response.userName) {
                localStorage.removeItem("passedDailyComment");
              }
              localStorage.setItem("userName", data.response.userName);
              const url = `samkim://login?sessionToken=${data.response.token}&userName=${data.response.userName}&id=${data.response.id}&autoLogin=${this.login.autoLogin}`;
              document.location.href = url;
              if (this.login.storeId) {
                localStorage.setItem("storeId", this.login.name.value);
              } else if (localStorage.getItem("storeId") !== null) {
                localStorage.removeItem("storeId");
              }
              if (sessionStorage.getItem("destinedURL") !== null) {
                const destinationLink = sessionStorage.getItem("destinedURL");
                sessionStorage.removeItem("destinedURL");
                this.$router.push(destinationLink);
              } else {
                this.$router.push("/");
              }
              /**
              this.SET_POPUP(true);
              this.popupSet.popType = "warn";
              this.popupSet.popImg = "success.svg";
              this.popupSet.content = "로그인이 완료되었습니다.";
              this.popupSet.cancelBtnText = null;
              this.popupSet.nextLink = "/";
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.destination = true;
               */
            } else {
              this.checkToast(1);
              /**
              this.SET_POPUP(true);
              this.popupSet.popType = 'warn';
              this.popupSet.popImg = 'success.svg';
              this.popupSet.content = '아이디 또는 비밀번호가 일치하지 않습니다.';
              this.popupSet.cancelBtnText = '확인';
              this.popupSet.nextLink = null;
              this.popupSet.confirmBtnText = null;
               */
            }
          });
        } catch (error) {
          this.SET_POPUP(true);
          this.popupSet.content = String(errorParser.parse(error));
          this.popupSet.popImg = "warning.svg";
          this.popupSet.cancelBtnText = "확인";
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
        }
      }
    },
    checkName() {
      this.login.name.flag = false;
      const userName = this.login.name.value.replace(/\s/gi, "");
      if (userName === "") {
        this.login.name.flag = false;
        return false;
      }
      const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,21}$/;
      if (!isHan.test(userName)) {
        this.login.name.flag = false;
        return false;
      }
      this.login.name.flag = true;
      return true;
    },
    checkPhone() {
      this.login.phone.flag = false;
      const phone = this.login.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.login.phone.flag = false;
        return false;
      }
      const isPhone = /^[0-9]{8,11}$/;
      if (!isPhone.test(phone)) {
        this.login.phone.flag = false;
        return false;
      }
      this.login.phone.flag = true;
      return true;
    },

    enterName() {
      this.view.isNameFocused = true;
    },
    enterPhone() {
      this.view.isPhoneFocused = true;
    },
    exitName() {
      this.view.isNameFocused = false;
    },
    exitPhone() {
      this.view.isPhoneFocused = false;
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("auth", ["GET_FCMTOKEN", "GET_DEVICEID"]),
    ...mapGetters("join", [
      "GET_PERSONALINFO",
      "GET_QUESTIONLIST1",
      "GET_QUESTIONLIST2",
      "GET_QUESTIONLIST3",
      "GET_QUESTIONLIST4",
    ]),
  },
  components: {
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context.login {
  background-image: url("~@assets/images/login/bg_login.png");
  width: 1280px;
  background-size: cover;
  .wrap-context {
    margin: 0;
    .toast-alert {
      transition: 0.5s;
      position: absolute;
      border-bottom-left-radius: 12.5px;
      border-bottom-right-radius: 12.5px;
      border: 2px solid #fe6967;
      width: 354px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -130px;
      left: 50%;
      transform: translateX(-50%);
      &.active {
        top: -2px;
      }
      p {
        @include initfontKor(
          $font-size: 16px,
          $line-height: 24px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fe6967,
          $letter-spacing: normal,
          $text-align: center
        );
      }
    }
    .login-context {
      padding: 40px 70px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 480px;
      height: 440px;
      border-radius: 10px;
      box-shadow: 0 3px 30px 0 rgba(54, 55, 57, 0.16);
      background-color: #ffffff;
      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
      .title {
        position: relative;
        margin: 0 0 30px 0;
        .img-wrap {
          position: absolute;
          left: 30px;
          img {
          }
        }
        h3 {
          @include initfontKor(
            $font-size: 36px,
            $line-height: 42px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #315754,
            $letter-spacing: -0.72px,
            $text-align: center
          );
        }
      }
      .content {
        margin: 0 0 20px 0;
        .account {
          margin: 0 0 20px 0;
          .article {
            background: #eaebf1;
            border: solid 1px #eaebf1;
            padding: 15px 30px 15px 55px;
            margin: 12px 0 0 0;
            position: relative;
            border-radius: 10px;
            &:first-of-type {
              margin: 0;
            }
            &.focus {
              background: transparent;
              border-radius: 10px;
              border: solid 1px #315754;
              &:nth-of-type(1):after {
                background-image: url("~@assets/images/login/icon_name_active.svg");
              }
              &:nth-of-type(2):after {
                background-image: url("~@assets/images/login/icon_pw_active.svg");
              }
              input {
              }
            }
            &:nth-of-type(1):after {
              background-image: url("~@assets/images/login/icon_name.svg");
            }
            &:nth-of-type(2):after {
              background-image: url("~@assets/images/login/icon_pw.svg");
            }
            &:after {
              content: "";
              position: absolute;
              width: 36px;
              height: 36px;
              left: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
            position: relative;
            input {
              background: transparent;
              @include initfontKor(
                $font-size: 24px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #363739,
                $letter-spacing: -0.48px,
                $text-align: left
              );
              &::placeholder {
                color: #8b8d94;
              }
            }
          }
        }
      }
      .mega-btn-wrap {
        width: 100%;
        border-radius: 10px;
        border: solid 2px #315754;
        margin: 0 0 25px 0;
        &.active {
          background: #315754;
          button {
            color: #fff;
          }
        }
        button {
          width: inherit;
          border: 0;
          padding: 0;
          background: transparent;
          cursor: pointer;
          @include initfontKor(
            $font-size: 24px,
            $line-height: 60px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #315754,
            $letter-spacing: -0.48px,
            $text-align: center
          );
        }
      }
      .flex-opt-wrap {
        display: flex;
        justify-content: space-between;
        .item {
          .input-wrap {
            margin-bottom: 12px;
            input[type="checkbox"] {
              display: none;
              position: absolute;
              + label {
                cursor: pointer;
                .holder {
                  margin: 0 8px 0 0;
                  border-radius: 3px;
                  border: solid 2px #315754;
                  width: 20px;
                  height: 20px;
                  position: relative;
                }
                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  width: 13px;
                  height: 9px;
                  background-image: url("~@assets/images/login/check_un.svg");
                  cursor: pointer;
                  transform: translate(-50%, -50%);
                }
              }
              &:checked + label {
                .holder {
                  background: #315754;
                }
                p {
                  font-weight: bold;
                }
                span {
                  background-image: url("~@assets/images/login/check_on.svg");
                  background-size: cover;
                }
              }
            }
            label {
              display: flex;
              align-items: center;
              width: 100%;

              h5 {
                @include initfontKor(
                  $font-size: 24px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #363739,
                  $letter-spacing: -0.48px,
                  $text-align: left
                );
              }
            }
          }
        }
      }
      .notification {
        width: 100%;
        position: absolute;
        bottom: -50px;
        left: 0;
        display: flex;
        justify-content: space-between;
        p {
          padding: 0 0 0 10px;
          @include initfontKor(
            $font-size: 24px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: left
          );
        }
        a {
          cursor: pointer;
          position: relative;
          &:after {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #fd8b11;
            content: "";
            left: 0;
            bottom: 3px;
          }
          @include initfontKor(
            $font-size: 24px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fd8b11,
            $letter-spacing: -0.48px,
            $text-align: left
          );
          display: block;
        }
      }
    }
  }
}
</style>
