import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@page/MainPage.vue";
import Evaluate from "@page/evaluation/MainPage.vue";
import Clinical from "@page/evaluation/Clinical.vue";
import Structure from "@page/evaluation/Structure.vue";
import Swallow from "@page/evaluation/Swallow.vue";
import Vocalization from "@page/evaluation/Vocalization.vue";
import EvaluateRecord from "@page/evaluation/EvaluateRecord.vue";
import EvaluateExplain from "@page/evaluation/Explain.vue";
import ExplainRecord from "@page/evaluation/ExplainRecord.vue";
import LifeMeaningExplain from "@page/evaluation/LifeMeaningExplain.vue";
import Chew from "@page/evaluation/Chew.vue";
import TrainingDate from "@page/training/TrainingDate.vue";
import TrainingInfo from "@page/training/TrainingInfo.vue";
import TrainingRecord from "@page/training/TrainingRecord.vue";
import TrainingSwallow from "@page/training/TrainingSwallow.vue";
import TrainingSwallowTest from "@page/training/TrainingSwallowTest.vue";
import TrainingRaising from "@page/training/TrainingRaising.vue";
import TongueTurn from "@page/training/TongueTurn.vue";
import InfoObstacle from "@page/training/InfoObstacle.vue";
import InfoSwallow from "@page/training/InfoSwallow.vue";
import InfoRaising from "@page/training/InfoRaising.vue";
import InfoTogue from "@page/training/InfoTogue.vue";
import ChoiceMeaning from "@page/search/ChoiceMeaning.vue";
import SerchInfo from "@page/search/SearchInfo.vue";
import SerchList from "@page/search/SearchList.vue";
import ChoiceType from "@page/diet/ChoiceType.vue";
import InfomationPage from "@page/diet/InfomationPage.vue";
import InfomationPosturePage from "@page/diet/InfomationPosturePage.vue";
import DietList from "@page/diet/DietList.vue";
import PostureList from "@page/diet/PostureList.vue";
import ShareDiet from "@page/diet/ShareDiet.vue";
import SharePosture from "@page/diet/SharePosture.vue";
import ShareLife from "@page/lifeshare/ShareLife.vue";
import ShareLifeList from "@page/lifeshare/ShareLifeList.vue";
import SwiperSample from "@page/evaluation/SwiperSample.vue";
import Timer from "@page/evaluation/Timer.vue";
import Test from "@page/evaluation/Test.vue";
import Calendar from "@component/Calendar.vue";
import TimerTest from "@component/CountTimer.vue";
import InformationDate from "@page/util/InformationDate.vue";
import ReservationType from "@page/reservation/MainPage.vue";
import ApplyReservation from "@page/reservation/ApplyReservation.vue";
import ConsultingList from "@page/reservation/ConsultingList.vue";
import Mypage from "@page/mypage/ChoiceType.vue";
import Join from "@page/join/Join.vue";
import Login from "@page/join/Login.vue";
import AlermList from "@page/util/AlermList.vue";
import NoticeList from "@page/util/NoticeList.vue";
import TimerTest2 from "@page/util/TimerTest2.vue";

import TTEST from "@component/materialQuick.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/join/:code",
    name: "Join",
    component: Join,
    meta: { unauthorized: true }
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: { unauthorized: true }
  },
  {
    path: "/evaluate",
    name: "Evaluate",
    component: Evaluate
  },
  {
    path: "/clinical",
    name: "Clinical",
    component: Clinical
  },

  {
    path: "/trainingDate",
    name: "TrainingDate",
    component: TrainingDate
  },
  {
    path: "/trainingInfo",
    name: "TrainingInfo",
    component: TrainingInfo
  },
  {
    path: "/trainingRecord",
    name: "TrainingRecord",
    component: TrainingRecord
  },
  {
    path: "/trainingSwallow",
    name: "TrainingSwallow",
    component: TrainingSwallow
  },
  {
    path: "/trainingSwallowTest",
    name: "TrainingSwallowTest",
    component: TrainingSwallowTest
  },
  {
    path: "/trainingRaising",
    name: "TrainingRaising",
    component: TrainingRaising
  },
  {
    path: "/infoObstacle",
    name: "InfoObstacle",
    component: InfoObstacle
  },
  {
    path: "/infoSwallow",
    name: "InfoSwallow",
    component: InfoSwallow
  },
  {
    path: "/infoRaising",
    name: "InfoRaising",
    component: InfoRaising
  },
  {
    path: "/infoTogue",
    name: "InfoTogue",
    component: InfoTogue
  },
  {
    path: "/tongueTurn",
    name: "TongueTurn",
    component: TongueTurn
  },
  {
    path: "/choiceMeaning",
    name: "ChoiceMeaning",
    component: ChoiceMeaning
  },
  {
    path: "/searchInfo",
    name: "SerchInfo",
    component: SerchInfo
  },
  {
    path: "/searchList",
    name: "SerchList",
    component: SerchList
  },
  {
    path: "/dietList",
    name: "DietList",
    component: DietList
  },
  {
    path: "/postureList",
    name: "PostureList",
    component: PostureList
  },

  {
    path: "/choiceType",
    name: "ChoiceType",
    component: ChoiceType
  },
  {
    path: "/infomationPage",
    name: "InfomationPage",
    component: InfomationPage
  },
  {
    path: "/infomationPosturePage",
    name: "InfomationPosturePage",
    component: InfomationPosturePage
  },
  {
    path: "/share/posture",
    name: "SharePosture",
    component: SharePosture,
    meta: { unauthorized: true }
  },
  {
    path: "/share/life",
    name: "ShareLife",
    component: ShareLife,
    meta: { unauthorized: true }
  },
  {
    path: "/share/lifelist",
    name: "ShareLifeList",
    component: ShareLifeList,
    meta: { unauthorized: true }
  },
  {
    path: "/share/diet",
    name: "ShareDiet",
    component: ShareDiet,
    meta: { unauthorized: true }
  },
  {
    path: "/share/posture",
    name: "SharePosture",
    component: SharePosture,
    meta: { unauthorized: true }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar
  },
  {
    path: "/swiperSample",
    name: "SwiperSample",
    component: SwiperSample
  },
  {
    path: "/timer",
    name: "Timer",
    component: Timer
  },
  {
    path: "/structure",
    name: "Structure",
    component: Structure
  },
  {
    path: "/swallow",
    name: "Swallow",
    component: Swallow
  },
  {
    path: "/chew",
    name: "Chew",
    component: Chew
  },
  {
    path: "/vocalization",
    name: "Vocalization",
    component: Vocalization
  },
  {
    path: "/test",
    name: "Test",
    component: Test
  },
  {
    path: "/timerTest",
    name: "TimerTest",
    component: TimerTest
  },
  {
    path: "/evaluateRecord",
    name: "EvaluateRecord",
    component: EvaluateRecord
  },
  {
    path: "/explainRecord",
    name: "ExplainRecord",
    component: ExplainRecord
  },
  {
    path: "/evaluateExplain",
    name: "EvaluateExplain",
    component: EvaluateExplain
  },
  {
    path: "/lifeMeaningExplain",
    name: "lifeMeaningExplain",
    component: LifeMeaningExplain
  },
  {
    path: "/informationDate",
    name: "InformationDate",
    component: InformationDate
  },
  {
    path: "/reservationType",
    name: "ReservationType",
    component: ReservationType
  },
  {
    path: "/applyReservation",
    name: "applyReservation",
    component: ApplyReservation
  },
  {
    path: "/consultingList",
    name: "consultingList",
    component: ConsultingList
  },
  {
    path: "/mypage",
    name: "mypage",
    component: Mypage
  },
  {
    path: "/alermList",
    name: "alermList",
    component: AlermList
  },
  {
    path: "/noticeList",
    name: "noticeList",
    component: NoticeList,
    meta: { unauthorized: true }
  },
  {
    path: "/timerTest2",
    name: "timerTest2",
    component: TimerTest2
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  /** 내가 지정한 URL */
  /** https://router.vuejs.org/guide/advanced/meta.html  */
  if (to.matched.some(record => record.meta.unauthorized)) {
    /**
    console.log("권한이 필요없어 이동함", to.path);
     */
    return next();
  }
  if (localStorage.getItem("token") !== null) {
    /**
    console.log("권한이 부여되어 이동함", to.path);
    */
    return next();
  }
  console.log("이동하기 위해선 권한이 필요함", to.path);
  sessionStorage.setItem("destinedURL", to.path);
  return next("/login");
});

export default router;
