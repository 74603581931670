<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <directive :directiveTxt="directiveTxt" class="directive low"></directive>
      <block-menu
        class="diet"
        :menuList="menuList"
        :menuType="menuType"
        @check-link-flag="goRouter"
        ref="blockMenuItem"
        v-if="view.isLoadBlockMenu"
      ></block-menu>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import Directive from '@/components/Directive.vue';
import BlockMenu from '@component/BlockMenu.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      menuType: 6,
      menuList: [
        {
          img: 'reservation.png',
          name: '예약하기',
          isActive: false,
          routerUrl: 'applyReservation',
        },
        {
          img: 'remote.png',
          name: '시작하기',
          isActive: false,
          routerUrl: null,
        },
      ],
      directiveTxt: {
        point: '원격상담',
        normal: '을 통해 함께 관리하세요.',
        small: null,
      },
      notificationInfo: {
        title: '원격 상담',
        btnSet: [
          {
            img: 'icon_history.svg',
            name: '상담이력',
            routerUrl: 'consultingList',
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item04.png',
          img_active: 'sidebar_menu4_active.png',
          img_active_deep: 'sidebar_menu4_active_deep.png',
          title: '삶의 의미 탐색',
          isActive: false,
          link: 'choiceMeaning',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: true,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      view: {
        isLoadBlockMenu: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.getConsultationInfo();
    this.checkTime();
  },
  mounted() {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('consultation', ['CHECK_CONSULTATION']),
    async goRouter(order) {
      if (order === 0) {
        this.$router.push('/applyReservation');
      } else if (order === 1) {
        /** https://dever.tistory.com/280 */
        /** 한번 더 */
        try {
          await this.CHECK_CONSULTATION().then((data) => {
            console.log('data :', data);
            /** 프론트 시간 검증
            const mutateDate =
              data.response.consultationDate + "T" + data.response.consultationTime;
            const sample1 = this.$moment(new Date(mutateDate));
            const sample2 = this.$moment(new Date());
            if (this.$moment.duration(sample2.diff(sample1)).asMilliseconds() > 0) {
              alert("원격 출입가능");
            }
            console.log(
              "현재시간 검증",
              this.$moment.duration(sample2.diff(sample1)).asMilliseconds() > 0
            );
            */
            if (data.response.timeCheck && data.response.groupId !== null) {
              window.open(
                `https://remote.swallow.pro/?groupId=${data.response.groupId}&threadId=${data.response.threadId}`,
                '_blank',
              );
            } else {
              this.SET_POPUP(true);
              this.popupSet.content = '상담가능한 시간이 아닙니다.';
              this.popupSet.popImg = 'warning.svg';
              this.popupSet.cancelBtnText = '확인';
              this.popupSet.nextLink = null;
              this.popupSet.confirmBtnText = null;
            }
          });
        } catch (error) {
          console.log('error: ', error);
          console.log('errorParser: ', errorParser.parse(error));
        }
      }
    },
    checkTime() {
      console.log('시간체크', this.$moment(new Date()).format('LTS'));
    },
    async getConsultationInfo() {
      try {
        await this.CHECK_CONSULTATION().then((data) => {
          console.log('data!:', data);
          const mutateDate = `${data.response.consultationDate}T${data.response.consultationTime}`;
          const sample1 = this.$moment(new Date(mutateDate));
          const sample2 = this.$moment(new Date());
          console.log(
            '리버팅 :',
            this.$moment.duration(sample1.diff(sample2)).asHours() > 0,
          );
          if (data.response.timeCheck) {
            this.menuList[1].isActive = true;
          }
          this.view.isLoadBlockMenu = true;
          if (this.$moment.duration(sample2.diff(sample1)).asHours() < 1) {
            this.$nextTick((_) => {
              this.getResvTime(
                `${data.response.consultationDate}T${data.response.consultationTime}`,
              );
            });
          }
        });
      } catch (error) {
        this.view.isLoadBlockMenu = true;
        console.log('error: ', error);
        console.log('errorParser: ', errorParser.parse(error));
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/");
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    getResvTime(resvTime) {
      /** 예약 시간 API 조회 */
      const splitVal = resvTime.split('T');
      const timeVal = splitVal[1].replace(':00', '');
      const transContext = {
        date: splitVal[0],
        time: timeVal,
      };
      this.$refs.blockMenuItem.drawSpeech(1, transContext);
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Directive,
    BlockMenu,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 56px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
