<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <h5 class="page-title complex">설문조회</h5>
      <record-navigation
        :dateList="dateList"
        @open-calendar="calendarPop"
        @call-list-date="callListInfo"
        @call-list-fail="callListFail"
        ref="recordNav"
        v-if="view.isRecordNavigationVisible"
      >
      </record-navigation>
      <!-- 탭메뉴 시작 -->
      <tabs ref="tabs" :tabType="tabType" @tab-selected="tabSelected">
        <tab :tabData="tab[0]">
          <div class="list-wrap structure" v-if="evaluateList.structure.length > 0">
            <div
              class="item"
              v-for="(listItem, index) in evaluateList.structure"
              :key="index + 'A'"
            >
              <h4 class="header" v-text="listItem.questionText" />
              <h4 class="content" v-text="getAnswer(listItem)" />
            </div>
          </div>
          <div class="list-wrap structure alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[1]">
          <div class="list-wrap water" v-if="evaluateList.water.list.length > 0">
            <div class="list-box xeno" v-if="evaluateList.water.list.length >= 3">
              <h4 class="title">3ml</h4>
              <div class="list">
                <div class="article grid">
                  <h4 class="a-header">아 ~</h4>
                  <div class="a-content">
                    <div class="item">
                      <h4 class="header">1</h4>
                      <h4
                        class="content"
                        v-text="eraseZero(evaluateList.water.audioList[0].beforeAudioLength)"
                      />
                    </div>
                    <div class="item">
                      <h4 class="header">2</h4>
                      <h4
                        class="content"
                        v-text="eraseZero(evaluateList.water.audioList[0].afterAudioLength)"
                      />
                    </div>
                  </div>
                </div>
                <div class="article">
                  <div class="item">
                    <h4 class="header" v-text="evaluateList.water.list[0].questionText" />
                    <h4 class="content" v-text="evaluateList.water.list[0].answerText" />
                  </div>
                </div>
                <div class="article grid">
                  <h4 class="a-header">설골-후두상승</h4>
                  <div class="a-content">
                    <div class="item">
                      <h4 class="header">속도</h4>
                      <h4 class="content" v-text="evaluateList.water.list[1].answerText" />
                    </div>
                    <div class="item">
                      <h4 class="header">범위</h4>
                      <h4 class="content" v-text="evaluateList.water.list[2].answerText" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-box xeno" v-if="evaluateList.water.list.length >= 6">
              <h4 class="title">9ml</h4>
              <div class="list">
                <div class="article grid">
                  <h4 class="a-header">아 ~</h4>
                  <div class="a-content">
                    <div class="item">
                      <h4 class="header">1</h4>
                      <h4
                        class="content"
                        v-text="eraseZero(evaluateList.water.audioList[1].beforeAudioLength)"
                      />
                    </div>
                    <div class="item">
                      <h4 class="header">2</h4>
                      <h4
                        class="content"
                        v-text="eraseZero(evaluateList.water.audioList[1].beforeAudioLength)"
                      />
                    </div>
                  </div>
                </div>
                <div class="article">
                  <div class="item">
                    <h4 class="header" v-text="evaluateList.water.list[3].questionText" />
                    <h4 class="content" v-text="evaluateList.water.list[3].answerText" />
                  </div>
                </div>
                <div class="article grid">
                  <h4 class="a-header">설골-후두상승</h4>
                  <div class="a-content">
                    <div class="item">
                      <h4 class="header">속도</h4>
                      <h4 class="content" v-text="evaluateList.water.list[4].answerText" />
                    </div>
                    <div class="item">
                      <h4 class="header">범위</h4>
                      <h4 class="content" v-text="evaluateList.water.list[5].answerText" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-box" v-if="evaluateList.water.list.length >= 9">
              <h4 class="title">90ml</h4>
              <div class="list">
                <div class="article">
                  <div class="item">
                    <h4 class="header" v-text="evaluateList.water.list[6].questionText" />
                    <h4 class="content" v-text="evaluateList.water.list[6].answerText" />
                  </div>
                </div>
                <div class="article grid">
                  <h4 class="a-header">설골-후두상승</h4>
                  <div class="a-content">
                    <div class="item">
                      <h4 class="header">속도</h4>
                      <h4 class="content" v-text="evaluateList.water.list[7].answerText" />
                    </div>
                    <div class="item">
                      <h4 class="header">범위</h4>
                      <h4 class="content" v-text="evaluateList.water.list[8].answerText" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list-wrap water alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[2]">
          <div class="list-wrap solid" v-if="evaluateList.solid.length > 0">
            <div class="list-box">
              <h4 class="title">보통</h4>
              <div class="list">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].normalTime" />
                </div>
                <div class="item">
                  <h4 class="header">총 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].normalNumber" />
                </div>
                <div class="item">
                  <h4 class="header">초당 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].normalNumberPerSecond" />
                </div>
              </div>
            </div>
            <div class="list-box">
              <h4 class="title">최대</h4>
              <div class="list">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].maxTime" />
                </div>
                <div class="item">
                  <h4 class="header">총 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].maxNumber" />
                </div>
                <div class="item">
                  <h4 class="header">초당 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].maxNumberPerSecond" />
                </div>
              </div>
            </div>
            <div class="list-box">
              <h4 class="title">턱 움직임</h4>
              <div class="list alone">
                <div class="item">
                  <h4 class="header">범위 및 규칙성</h4>
                  <h4 class="content" v-text="this.evaluateList.solid[0].range" />
                </div>
              </div>
            </div>
          </div>
          <div class="list-wrap solid alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[3]">
          <div class="list-wrap solid" v-if="evaluateList.vocalization.length > 0">
            <div class="list-box">
              <h4 class="title">아~</h4>
              <div class="list alone">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].aTime" />
                </div>
              </div>
            </div>
            <div class="list-box">
              <h4 class="title">퍼</h4>
              <div class="list">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="makeZero(this.evaluateList.vocalization[0].pTime)" />
                </div>
                <div class="item">
                  <h4 class="header">총 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].pNumber" />
                </div>
                <div class="item">
                  <h4 class="header">초당 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].pNumberPerSecond" />
                </div>
              </div>
            </div>
            <div class="list-box">
              <h4 class="title">터</h4>
              <div class="list">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="makeZero(this.evaluateList.vocalization[0].tTime)" />
                </div>
                <div class="item">
                  <h4 class="header">총 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].tNumber" />
                </div>
                <div class="item">
                  <h4 class="header">초당 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].tNumberPerSecond" />
                </div>
              </div>
            </div>
            <div class="list-box">
              <h4 class="title">커</h4>
              <div class="list">
                <div class="item">
                  <h4 class="header">시간</h4>
                  <h4 class="content" v-text="makeZero(this.evaluateList.vocalization[0].kTime)" />
                </div>
                <div class="item">
                  <h4 class="header">총 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].kNumber" />
                </div>
                <div class="item">
                  <h4 class="header">초당 횟수</h4>
                  <h4 class="content" v-text="this.evaluateList.vocalization[0].kNumberPerSecond" />
                </div>
              </div>
            </div>
          </div>
          <div class="list-wrap solid alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
      </tabs>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <calendar-popup
      @range-setted="changeDateRange"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import CalendarPopup from "@/components/modal/CalendarPopup";
import SideMenu from "@/components/SideMenu.vue";
import RecordNavigation from "@component/RecordNavigation.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      dateList: [],
      evaluateList: {
        structure: [],
        water: {
          list: [],
          audioList: []
        },
        solid: [],
        vocalization: []
      },
      tabType: 2,
      selectOrder: 0,
      apiStatus: {
        currentYear: "",
        currentMonth: "",
        currentType: "",
        currentDate: ""
      },
      fetchOpt: {
        id: null,
        date: null,
        firstDay: null,
        lastDay: null
      },
      tab: [
        {
          title: "삼킴구조와 기능"
        },
        {
          title: "물 삼키기"
        },
        {
          title: "고체 씹기"
        },
        {
          title: "아~/퍼/터/커"
        }
      ],
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: ""
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: true,
          link: "evaluate"
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate"
        },
        {
          img: "sidebar_item04.png",
          img_active: "sidebar_menu4_active.png",
          img_active_deep: "sidebar_menu4_active_deep.png",
          title: "삶의 의미 탐색",
          isActive: false,
          link: "choiceMeaning"
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType"
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType"
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage"
        }
      ],
      popupSet: {},
      popupSetCalendar: {},
      view: {
        isRecordNavigationVisible: false,
        passedFirstTab: false
      }
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }

    /** 날짜조회후 gte - lte 데이터 불러옴 
    this.getDateRange();
    */
    this.getExplainList();
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CALENDAR_POPUP"]),
    ...mapActions("evaluate", ["FETCH_EVALUATE_LIST", "FETCH_EXPLAINLIST"]),
    async getExplainList() {
      try {
        const payload = {
          userId: localStorage.getItem("id"),
          get_type: "day"
        };
        await this.FETCH_EXPLAINLIST(payload).then(data => {
          this.dateList = data.response.list;
          this.view.isRecordNavigationVisible = true;
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    callListFail() {
      console.log("진행한 평가가 없음", this.transToType(this.selectOrder));
      this.$nextTick(_ => {
        this.view.passedFirstTab = true;
      });
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/clinical");
      }
    },
    initEvaluateList() {
      const arrayInit = [];
      const commonObj = {
        list: [],
        audioList: []
      };
      this.evaluateList.structure = arrayInit;
      this.evaluateList.water = commonObj;
      this.evaluateList.solid = arrayInit;
      this.evaluateList.vocalization = arrayInit;
    },
    callListInfo(info) {
      console.log("info 정보받음 :", info);
      this.view.isRecordNavigationVisible = true;
      this.fetchOpt.date = info.date;
      this.fetchOpt.id = info.id;
      this.getEvaluateList(this.selectOrder);
    },
    async changeDateRange(gte, lte, goalDate) {
      console.log("찾기", goalDate);
      console.log("범위 바꾸는 곳에 들어감");
      this.fetchOpt.firstDay = gte;
      this.fetchOpt.lastDay = lte;
      try {
        const payload = {
          type: this.transToType(Number(this.selectOrder)),
          gte,
          lte
        };
        await this.FETCH_MONTHLIST(payload).then(data => {
          if (goalDate !== null) {
            this.$refs.recordNav.initList(data.response.list, goalDate);
          } else {
            this.$refs.recordNav.initList(data.response.list);
          }
        });
      } catch (error) {
        console.log("error :", String(errorParser.parse(error)));
      }
    },
    async getDateRange() {
      /** select-order를 타입으로 */
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      let monthVal;
      let yearVal;
      if (currentMonth < 3) {
        monthVal = currentMonth + 12;
        yearVal = currentYear - 1;
      } else if (currentMonth < 10) {
        monthVal = currentMonth - 12;
        yearVal = currentYear + 1;
      } else {
        monthVal = currentMonth;
        yearVal = currentYear;
      }
      const firstDay = this.$moment(new Date(yearVal, monthVal - 4, 1)).format("YYYY-MM-DD");
      const lastDay = this.$moment(new Date(yearVal, monthVal + 3, 0)).format("YYYY-MM-DD");
      this.fetchOpt.firstDay = firstDay;
      this.fetchOpt.lastDay = lastDay;
      try {
        const payload = {
          type: this.transToType(Number(this.selectOrder)),
          gte: firstDay,
          lte: lastDay
        };
        await this.FETCH_MONTHLIST(payload).then(data => {
          this.dateList = data.response.list;
          this.view.isRecordNavigationVisible = true;
        });
      } catch (error) {
        console.log("error :", String(errorParser.parse(error)));
      }
      console.log("firstDay :", firstDay);
      console.log("lastDay :", lastDay);
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    async tabSelected(order) {
      this.selectOrder = order;
      console.log("tab-order :", order);
      if (this.view.passedFirstTab) {
        this.changeDateRange(this.fetchOpt.firstDay, this.fetchOpt.lastDay, this.fetchOpt.date);
      }
    },
    async getEvaluateList(order) {
      /**
       * API수신 후 list 정보를 구한다.
       */
      this.initEvaluateList();
      let payload;
      if (order === 0) {
        if (this.fetchOpt.id !== null) {
          try {
            payload = {
              type: "structure",
              id: this.fetchOpt.id
            };
            await this.FETCH_EVALUATE_LIST(payload).then(data => {
              this.evaluateList.structure = data.response.list;
            });
          } catch (error) {
            console.log("error: ", errorParser.parse(error));
          }
        }
      } else if (order === 1) {
        if (this.fetchOpt.id !== null) {
          try {
            payload = {
              type: "water",
              id: this.fetchOpt.id
            };
            await this.FETCH_EVALUATE_LIST(payload).then(data => {
              console.log("water-app-data", data);
              this.evaluateList.water = data.response;
            });
          } catch (error) {
            console.log("error: ", errorParser.parse(error));
          }
        }
      } else if (order === 2) {
        if (this.fetchOpt.id !== null) {
          try {
            payload = {
              type: "solid",
              id: this.fetchOpt.id
            };
            await this.FETCH_EVALUATE_LIST(payload).then(data => {
              this.evaluateList.solid = data.response.list;
            });
          } catch (error) {
            errorParser.parse(error);
          }
        }
      } else if (order === 3) {
        if (this.fetchOpt.id !== null) {
          try {
            payload = {
              type: "vocalization",
              id: this.fetchOpt.id
            };
            await this.FETCH_EVALUATE_LIST(payload).then(data => {
              this.evaluateList.vocalization = data.response.list;
            });
          } catch (error) {
            errorParser.parse(error);
          }
        }
      }
      this.view.passedFirstTab = true;
    },
    calendarPop() {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.calendarType = 0;
      this.popupSetCalendar.selectOrder = this.selectOrder;
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkCalendarPopup"]),
    getAnswer() {
      return item => {
        if (item.etcText === null) {
          return item.answerText;
        } else {
          return item.etcText;
        }
      };
    },
    eraseZero() {
      return txt => {
        console.log("txt :", txt);
        const splitTxt = txt.split(":");
        return splitTxt[1] + ":" + splitTxt[2];
      };
    },
    makeZero() {
      return timeValue => {
        return "0" + timeValue;
      };
    },
    transToType() {
      return order => {
        if (order === 0) {
          return "structure";
        }
        if (order === 1) {
          return "water";
        }
        if (order === 2) {
          return "solid";
        }
        if (order === 3) {
          return "vocalization";
        }
      };
    }
  },
  components: {
    SideMenu,
    CalendarPopup,
    RecordNavigation,
    Tabs,
    Tab
  }
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    position: relative;
    .tab-wrapper {
      max-height: 440px;
      overflow-y: scroll;
    }
    .list-wrap {
      &.structure {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 0px;
        grid-template-columns: 1fr 1fr 1fr;
        border-bottom: 1px solid #fd8b11;
        &.alone {
          display: block;
          border-bottom: 0;
          p {
            @include initfontKor(
              $font-size: 26px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: left
            );
          }
        }
        .item {
          border-right: 1px solid #d0d0d1;
          border-top: 1px solid #d0d0d1;
          &:nth-of-type(3n) {
            border-right: 0;
          }
          &:nth-of-type(n + 13) {
            border-bottom: 1px solid #d0d0d1;
          }
          &:nth-of-type(16) {
            border-top: 0;
            border-bottom: 0;
          }
          h4 {
            &.header {
              background: #fffbf8;
              @include initfontKor(
                $font-size: 26px,
                $line-height: 66px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -1.04px,
                $text-align: center
              );
            }
            &.content {
              @include initfontKor(
                $font-size: 26px,
                $line-height: 66px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #fd8b11,
                $letter-spacing: -1.04px,
                $text-align: center
              );
            }
          }
        }
      }
      &.water {
        &.alone {
          display: block;
          border-bottom: 0;
          p {
            @include initfontKor(
              $font-size: 26px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: left
            );
          }
        }
        .list-box {
          margin: 55px 0 0 0;
          &:first-of-type {
            margin: 0;
          }
          h4.title {
            margin: 0 0 8.5px 0;
            @include initfontKor(
              $font-size: 26px,
              $line-height: 34px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #fd8b11,
              $letter-spacing: -1.04px,
              $text-align: center
            );
          }
          &.xeno .list {
            grid-template-columns: 1fr 175px 1fr;
            .article {
              .a-content .item:nth-of-type(1) {
                &:after {
                  content: "";
                  box-shadow: 0px 0px 0px 0px #d0d0d1, 0px 0px 0px 1px #d0d0d1;
                  position: absolute;
                  width: 152px;
                  height: 133px;
                  left: 0;
                }
              }
              &:nth-of-type(1) {
                .a-header {
                  box-shadow: 0px 0px 0px 0px #d0d0d1, 0px 1px 0px 0px #d0d0d1;
                  margin-bottom: 1px;
                }
                .item {
                  outline: none;
                  box-shadow: 0px 0px 0px 0px #d0d0d1, 1px 0px 0px 0px #d0d0d1;
                  .header {
                    border-bottom: 0;
                  }
                }
              }
              &:nth-of-type(2) {
                .item {
                  .content {
                    line-height: 132px;
                    position: relative;
                    &:after {
                      content: "";
                      box-shadow: 0px 0px 0px 0px #d0d0d1, 0px 0px 0px 1px #d0d0d1;
                      position: absolute;
                      width: 175px;
                      height: 132px;
                      left: -1px;
                      top: 1px;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                .a-content {
                  .item:first-of-type .header {
                    position: relative;
                    &:after {
                      content: "";
                      width: 152px;
                      height: 66px;
                      position: absolute;
                      box-shadow: 0px 0px 0px 0px #d0d0d1, 0px 0px 0px 1px #d0d0d1;
                      left: 0;
                    }
                  }
                }
              }
              .a-content {
                .item {
                  .header {
                    line-height: 66px;
                  }
                  .content {
                  }
                }
              }
            }
          }
        }
        .list {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 0px;
          grid-template-columns: 235px 1fr;
          border-top: 1px solid #fd8b11;
          .article {
            &:first-of-type {
              border-right: 1px solid #d0d0d1;
              .item {
                .header {
                  position: relative;
                  line-height: 132px;
                  &:after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background: #d0d0d1;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }
                }
                .content {
                  position: relative;
                  &:after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background: #d0d0d1;
                    position: absolute;
                    bottom: -1px;
                    left: 0;
                  }
                }
              }
            }
            &.grid {
              h4.a-header {
                background: #fffbf8;
                @include initfontKor(
                  $font-size: 26px,
                  $line-height: 66px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -1.04px,
                  $text-align: center
                );
              }
              .a-content {
                display: grid;
                grid-auto-flow: dense;
                grid-gap: 0px;
                grid-template-columns: 1fr 1fr;
                .item {
                  outline: 1px solid #d0d0d1;
                  position: relative;
                  &:first-of-type {
                    margin: 0 1px 0 0;
                    &:after {
                      display: none;
                    }
                  }
                  &:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 100%;
                    right: -1px;
                    background: #fff;
                    bottom: 0;
                    z-index: 2;
                  }
                  .header {
                    border-bottom: 1px solid #d0d0d1;
                  }
                }
              }
            }
            .item {
              h4 {
                &.header {
                  background: #fffbf8;
                  @include initfontKor(
                    $font-size: 26px,
                    $line-height: 66px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #292a2b,
                    $letter-spacing: -1.04px,
                    $text-align: center
                  );
                }
                &.content {
                  @include initfontKor(
                    $font-size: 26px,
                    $line-height: 66px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fd8b11,
                    $letter-spacing: -1.04px,
                    $text-align: center
                  );
                }
              }
            }
          }
        }
      }
      &.solid {
        &.alone {
          display: block;
          border-bottom: 0;
          p {
            @include initfontKor(
              $font-size: 26px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: left
            );
          }
        }
        .list-box {
          margin: 55px 0 0 0;
          &:first-of-type {
            margin: 0;
          }
          h4.title {
            margin: 0 0 8.5px 0;
            @include initfontKor(
              $font-size: 26px,
              $line-height: 34px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #fd8b11,
              $letter-spacing: -1.04px,
              $text-align: center
            );
          }
        }
        .list {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 0px;
          grid-template-columns: 1fr 1fr 1fr;
          border-top: 1px solid #fd8b11;
          &.alone {
            grid-template-columns: 1fr;
          }
          .item {
            border-bottom: 1px solid #d0d0d1;
            border-left: 1px solid #d0d0d1;
            &:first-of-type {
              border-left: 0;
            }
            h4 {
              &.header {
                background: #fffbf8;
                position: relative;
                @include initfontKor(
                  $font-size: 26px,
                  $line-height: 66px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -1.04px,
                  $text-align: center
                );
                &:after {
                  content: "";
                  position: absolute;
                  width: 100%;
                  left: 0;
                  bottom: 0;
                  height: 1px;
                  background: #d0d0d1;
                }
              }
              &.content {
                @include initfontKor(
                  $font-size: 26px,
                  $line-height: 66px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #fd8b11,
                  $letter-spacing: -1.04px,
                  $text-align: center
                );
              }
            }
          }
        }
      }
    }
  }
}
</style>
