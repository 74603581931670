<template>
  <div class="directive" :class="{ deep: directiveType === 1 }">
    <h3 class="title"><b v-text="directiveTxt.point"></b>{{ directiveTxt.normal }}</h3>
    <p class="small" v-text="directiveTxt.small"></p>
  </div>
</template>

<script>
export default {
  props: {
    directiveTxt: {
      type: Object
    },
    directiveType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.directiveType1 {
  .directive {
    p {
      &.small {
        @include initfontKor(
          $font-size: 24px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fe8c12,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }
}
.directive {
  display: flex;
  position: relative;
  padding: 20px 0 0 30px;
  &.deep {
    h3.title {
      b {
        color: #315754;
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    width: 26px;
    height: 26px;
    background-image: url("~@assets/images/util/mark.png");
    left: 0;
    top: 0;
  }
  h3.title {
    @include initfontKor(
      $font-size: 30px,
      $line-height: 40px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #363739,
      $letter-spacing: -0.6px,
      $text-align: left
    );
    b {
      @include initfontKor(
        $font-size: 30px,
        $font-family: "AppleSDGothicNeoB00",
      );
      color: #fe8c12;
    }
  }
  p {
    &.small {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: left
      );
      margin: 0 0 0 8px;
    }
  }
}
</style>
