<template>
  <div class="swiper-wrap idon" v-once>
    <div class="swiper-All-wrapper">
      <swiper
        class="swiper"
        :options="swiperOption"
        ref="mySwiper"
        @slide-change-transition-start="slideStart"
        @slide-change-transition-end="slideEnd"
      >
        <swiper-slide v-for="(card, index) in swiperList" :key="index + 'A'">
          <div class="swiper-item">
            <h3 class="s-title" v-text="card.text" />
            <div
              class="tv-box"
              :class="{
                crayon1: card.color === '#b0cc43',
                crayon2: card.color === '#e6e618',
                crayon3: card.color === '#e68629',
                crayon4: card.color === '#e5602e',
                crayon5: card.color === '#cd2045',
                crayon6: card.color === '#b41f73',
                crayon7: card.color === '#962d91',
                crayon8: card.color === '#592d8c',
                crayon9: card.color === '#303089',
                crayon10: card.color === '#233f93',
                crayon11: card.color === '#0a57a4',
                crayon12: card.color === '#1474aa',
                crayon13: card.color === '#1691b2',
                crayon14: card.color === '#15a85c',
                crayon15: card.color === '#7eb442'
              }"
            >
              <div class="img-wrap">
                <img class="swiper-lazy" :src="'https://dev.swallow.pro' + card.images" />
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-btn prev" @click="prev()" :class="{ isHide: this.currentIndex === 0 }">
        <img src="@assets/images/util/icon_swiper_mini_before.svg" />
      </div>
      <div
        class="swiper-btn next"
        @click="next()"
        :class="{ isHide: this.currentIndex === this.swiperList.length - 1 }"
      >
        <img src="@assets/images/util/icon_swiper_mini_next.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    swiperList: {
      type: Array,
    },
    swiperOrder: {
      type: Number,
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        setWrapperSize: true,
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button.next',
          prevEl: '.swiper-button.prev',
        },
        lazy: true,
      },
      currentIndex: 0,
    };
  },
  created() {},
  mounted() {},
  methods: {
    slideStart(info) {
      console.log('리얼인덱스', info.realIndex);
      this.currentIndex = info.realIndex;
      console.log('스와이프리스트 렝스', this.swiperList.length);
    },
    slideEnd() {},
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
      console.log('prev Click');
    },
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
      console.log('next Click');
    },
  },
  computed: {
    computedLine() {
      return (text) => {
        if (text.indexOf('\n') !== -1) {
          return text.split('\n').join('<br />');
        }
        return text;
      };
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 370px) {
  .app-context.share .info-area {
    padding: 4px 12px;
    .swiper-wrap {
      max-width: 300px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 17px 0;
        }
        .tv-box {
          width: 205px;
          height: 135px;
          .img-wrap {
            max-width: 160px;
            max-height: 90px;
            left: calc(50% - 20px);
            top: calc(50% - 5px);
            img {
              height: 90px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 370px) and (max-width: 390px) {
  .app-context.share .info-area {
    padding: 15px 20px;
    .swiper-wrap {
      max-width: 320px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 20px;
          line-height: 26px;
          margin: 0 0 17px 0;
        }
        .tv-box {
          width: 255px;
          height: 160px;
          .img-wrap {
            max-width: 170px;
            max-height: 115px;
            left: calc(50% - 30px);
            top: calc(50% - 5px);
            img {
              height: 115px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 391px) and (max-width: 430px) {
  .app-context.share .info-area {
    .swiper-wrap {
      max-width: 340px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 22px;
          line-height: 27px;
          margin: 0 0 20px 0;
        }
        .tv-box {
          width: 255px;
          height: 160px;
          .img-wrap {
            max-width: 170px;
            max-height: 115px;
            left: calc(50% - 30px);
            top: calc(50% - 5px);
            img {
              height: 115px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 431px) and (max-width: 480px) {
  .app-context.share .info-area {
    .swiper-wrap {
      max-width: 390px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 24px;
          line-height: 29px;
          margin: 0 0 20px 0;
        }
        .tv-box {
          width: 305px;
          height: 195px;
          .img-wrap {
            max-width: 200px;
            max-height: 135px;
            left: calc(50% - 30px);
            top: calc(50% - 5px);
            img {
              height: 135px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .app-context.share .info-area {
    .swiper-wrap {
      max-width: 640px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 10px auto 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 24px;
          line-height: 29px;
          margin: 0 0 20px 0;
        }
        .tv-box {
          width: 440px;
          height: 295px;
          .img-wrap {
            max-width: 280px;
            max-height: 205px;
            left: calc(50% - 35px);
            top: calc(50% - 12.5px);
            img {
              height: 205px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 625px) and (max-width: 767px) {
  .app-context.share .info-area {
    .swiper-wrap {
      max-width: 530px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 10px auto 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 24px;
          line-height: 29px;
          margin: 0 0 20px 0;
        }
        .tv-box {
          width: 390px;
          height: 245px;
          .img-wrap {
            max-width: 260px;
            max-height: 175px;
            left: calc(50% - 30px);
            top: calc(50% - 5px);
            img {
              height: 175px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 625px) {
  .app-context.share .info-area {
    .swiper-wrap {
      max-width: 390px;
      .swiper-btn {
        top: calc(50% + 25px);
      }
      .swiper {
        margin: 10px auto 0 auto;
      }
      .swiper-item {
        .s-title {
          font-size: 24px;
          line-height: 29px;
          margin: 0 0 20px 0;
        }
        .tv-box {
          width: 305px;
          height: 195px;
          .img-wrap {
            max-width: 200px;
            max-height: 135px;
            left: calc(50% - 30px);
            top: calc(50% - 5px);
            img {
              height: 135px;
            }
          }
        }
      }
    }
  }
}
.swiper-wrap {
  height: auto;
  padding: 0;
  max-width: 870px;
  .swiper-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    h3.s-title {
      width: 100%;
      margin: 0 0 10px 0;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 34px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
    .tv-box {
      .img-wrap {
        img {
        }
      }
    }
  }
  .tv-box {
    width: 660px;
    height: 440px;
    background-size: cover;
    position: relative;
    &.crayon1 {
      background-image: url("~@assets/images/util/crayon1.png");
    }
    &.crayon2 {
      background-image: url("~@assets/images/util/crayon2.png");
    }
    &.crayon3 {
      background-image: url("~@assets/images/util/crayon3.png");
    }
    &.crayon4 {
      background-image: url("~@assets/images/util/crayon4.png");
    }
    &.crayon5 {
      background-image: url("~@assets/images/util/crayon5.png");
    }
    &.crayon6 {
      background-image: url("~@assets/images/util/crayon6.png");
    }
    &.crayon7 {
      background-image: url("~@assets/images/util/crayon7.png");
    }
    &.crayon8 {
      background-image: url("~@assets/images/util/crayon8.png");
    }
    &.crayon9 {
      background-image: url("~@assets/images/util/crayon9.png");
    }
    &.crayon10 {
      background-image: url("~@assets/images/util/crayon10.png");
    }
    &.crayon11 {
      background-image: url("~@assets/images/util/crayon11.png");
    }
    &.crayon12 {
      background-image: url("~@assets/images/util/crayon12.png");
    }
    &.crayon13 {
      background-image: url("~@assets/images/util/crayon13.png");
    }
    &.crayon14 {
      background-image: url("~@assets/images/util/crayon14.png");
    }
    &.crayon15 {
      background-image: url("~@assets/images/util/crayon15.png");
    }
    .img-wrap {
      position: absolute;
      max-width: 340px;
      max-height: 230px;
      left: calc(50% - 60px);
      top: calc(50% - 30px);
      transform: translate(-50%, -50%);
      img {
        max-width: 100%;
      }
    }
  }
  .swiper-btn {
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
    &.isHide {
    }
    &.prev {
      left: 0;
    }
    &.next {
      right: 0;
    }
    img {
      max-width: 41px;
    }
  }
  .swiper {
    margin: 69px auto 0 auto;

    .swiper-slide {
      &.swiper-slide-next {
      }
      img {
        max-width: 340px;
        height: 230px;
      }
    }
    .swiper-slide.swiper-slide-active {
      .item {
        width: 100%;
      }
    }
  }
}
</style>
